import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './publish-period.reducer';

export const PublishPeriodDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const publishPeriodEntity = useAppSelector(state => state.publishPeriod.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="publishPeriodDetailsHeading">
          <Translate contentKey="kpbackApp.publishPeriod.detail.title">PublishPeriod</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.publishPeriod.id">Id</Translate>
            </span>
          </dt>
          <dd>{publishPeriodEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.publishPeriod.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{publishPeriodEntity.legacyId}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="kpbackApp.publishPeriod.name">Name</Translate>
            </span>
          </dt>
          <dd>{publishPeriodEntity.name}</dd>
          <dt>
            <span id="isOther">
              <Translate contentKey="kpbackApp.publishPeriod.isOther">Is Other</Translate>
            </span>
          </dt>
          <dd>{publishPeriodEntity.isOther ? 'true' : 'false'}</dd>
          <dt>
            <span id="isIrregular">
              <Translate contentKey="kpbackApp.publishPeriod.isIrregular">Is Irregular</Translate>
            </span>
          </dt>
          <dd>{publishPeriodEntity.isIrregular ? 'true' : 'false'}</dd>
          <dt>
            <span id="isDaily">
              <Translate contentKey="kpbackApp.publishPeriod.isDaily">Is Daily</Translate>
            </span>
          </dt>
          <dd>{publishPeriodEntity.isDaily ? 'true' : 'false'}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="kpbackApp.publishPeriod.code">Code</Translate>
            </span>
          </dt>
          <dd>{publishPeriodEntity.code}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.publishPeriod.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{publishPeriodEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.publishPeriod.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {publishPeriodEntity.createdAt ? (
              <TextFormat value={publishPeriodEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.publishPeriod.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{publishPeriodEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.publishPeriod.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {publishPeriodEntity.modifiedAt ? (
              <TextFormat value={publishPeriodEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.publishPeriod.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{publishPeriodEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.publishPeriod.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {publishPeriodEntity.deletedAt ? (
              <TextFormat value={publishPeriodEntity.deletedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/publish-period" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/publish-period/${publishPeriodEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PublishPeriodDetail;
