import dayjs from 'dayjs';

export interface IVersion {
  id?: string;
  legacyId?: number | null;
  title?: string;
  orderNo?: number;
  isDeleted?: boolean;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<IVersion> = {
  isDeleted: false,
};
