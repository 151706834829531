import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PublicationReleasePersonRole from './publication-release-person-role';
import PublicationReleasePersonRoleDetail from './publication-release-person-role-detail';
import PublicationReleasePersonRoleUpdate from './publication-release-person-role-update';
import PublicationReleasePersonRoleDeleteDialog from './publication-release-person-role-delete-dialog';

const PublicationReleasePersonRoleRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PublicationReleasePersonRole />} />
    <Route path="new" element={<PublicationReleasePersonRoleUpdate />} />
    <Route path=":id">
      <Route index element={<PublicationReleasePersonRoleDetail />} />
      <Route path="edit" element={<PublicationReleasePersonRoleUpdate />} />
      <Route path="delete" element={<PublicationReleasePersonRoleDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PublicationReleasePersonRoleRoutes;
