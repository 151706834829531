import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './medium.reducer';

export const MediumDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const mediumEntity = useAppSelector(state => state.medium.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="mediumDetailsHeading">
          <Translate contentKey="kpbackApp.medium.detail.title">Medium</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.medium.id">Id</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.medium.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.legacyId}</dd>
          <dt>
            <span id="nameEt">
              <Translate contentKey="kpbackApp.medium.nameEt">Name Et</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.nameEt}</dd>
          <dt>
            <span id="orderNo">
              <Translate contentKey="kpbackApp.medium.orderNo">Order No</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.orderNo}</dd>
          <dt>
            <span id="marcBindingName">
              <Translate contentKey="kpbackApp.medium.marcBindingName">Marc Binding Name</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.marcBindingName}</dd>
          <dt>
            <span id="hasBindings">
              <Translate contentKey="kpbackApp.medium.hasBindings">Has Bindings</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.hasBindings ? 'true' : 'false'}</dd>
          <dt>
            <span id="hasPrintRun">
              <Translate contentKey="kpbackApp.medium.hasPrintRun">Has Print Run</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.hasPrintRun ? 'true' : 'false'}</dd>
          <dt>
            <span id="hasHeight">
              <Translate contentKey="kpbackApp.medium.hasHeight">Has Height</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.hasHeight ? 'true' : 'false'}</dd>
          <dt>
            <span id="hasDrm">
              <Translate contentKey="kpbackApp.medium.hasDrm">Has Drm</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.hasDrm ? 'true' : 'false'}</dd>
          <dt>
            <span id="hasWebpage">
              <Translate contentKey="kpbackApp.medium.hasWebpage">Has Webpage</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.hasWebpage ? 'true' : 'false'}</dd>
          <dt>
            <span id="hasContentType">
              <Translate contentKey="kpbackApp.medium.hasContentType">Has Content Type</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.hasContentType ? 'true' : 'false'}</dd>
          <dt>
            <span id="isOther">
              <Translate contentKey="kpbackApp.medium.isOther">Is Other</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.isOther ? 'true' : 'false'}</dd>
          <dt>
            <span id="asAppendix">
              <Translate contentKey="kpbackApp.medium.asAppendix">As Appendix</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.asAppendix ? 'true' : 'false'}</dd>
          <dt>
            <span id="asRelease">
              <Translate contentKey="kpbackApp.medium.asRelease">As Release</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.asRelease ? 'true' : 'false'}</dd>
          <dt>
            <span id="isPhysical">
              <Translate contentKey="kpbackApp.medium.isPhysical">Is Physical</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.isPhysical ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.medium.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.medium.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.createdAt ? <TextFormat value={mediumEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.medium.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.medium.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.modifiedAt ? <TextFormat value={mediumEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.medium.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.medium.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>{mediumEntity.deletedAt ? <TextFormat value={mediumEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="kpbackApp.medium.parent">Parent</Translate>
          </dt>
          <dd>{mediumEntity.parent ? mediumEntity.parent.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/medium" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/medium/${mediumEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default MediumDetail;
