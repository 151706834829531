import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import LegalOwner from './legal-owner';
import LegalOwnerDetail from './legal-owner-detail';
import LegalOwnerUpdate from './legal-owner-update';
import LegalOwnerDeleteDialog from './legal-owner-delete-dialog';

const LegalOwnerRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<LegalOwner />} />
    <Route path="new" element={<LegalOwnerUpdate />} />
    <Route path=":id">
      <Route index element={<LegalOwnerDetail />} />
      <Route path="edit" element={<LegalOwnerUpdate />} />
      <Route path="delete" element={<LegalOwnerDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default LegalOwnerRoutes;
