import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './language.reducer';

export const LanguageDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const languageEntity = useAppSelector(state => state.language.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="languageDetailsHeading">
          <Translate contentKey="kpbackApp.language.detail.title">Language</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.language.id">Id</Translate>
            </span>
          </dt>
          <dd>{languageEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.language.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{languageEntity.legacyId}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="kpbackApp.language.code">Code</Translate>
            </span>
          </dt>
          <dd>{languageEntity.code}</dd>
          <dt>
            <span id="nameEt">
              <Translate contentKey="kpbackApp.language.nameEt">Name Et</Translate>
            </span>
          </dt>
          <dd>{languageEntity.nameEt}</dd>
          <dt>
            <span id="nameMarc">
              <Translate contentKey="kpbackApp.language.nameMarc">Name Marc</Translate>
            </span>
          </dt>
          <dd>{languageEntity.nameMarc}</dd>
          <dt>
            <span id="isOther">
              <Translate contentKey="kpbackApp.language.isOther">Is Other</Translate>
            </span>
          </dt>
          <dd>{languageEntity.isOther ? 'true' : 'false'}</dd>
          <dt>
            <span id="isMusic">
              <Translate contentKey="kpbackApp.language.isMusic">Is Music</Translate>
            </span>
          </dt>
          <dd>{languageEntity.isMusic ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.language.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{languageEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.language.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{languageEntity.createdAt ? <TextFormat value={languageEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.language.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{languageEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.language.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {languageEntity.modifiedAt ? <TextFormat value={languageEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.language.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{languageEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.language.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>{languageEntity.deletedAt ? <TextFormat value={languageEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
        </dl>
        <Button tag={Link} to="/language" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/language/${languageEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default LanguageDetail;
