import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SubjectActionType from './subject-action-type';
import SubjectActionTypeDetail from './subject-action-type-detail';
import SubjectActionTypeUpdate from './subject-action-type-update';
import SubjectActionTypeDeleteDialog from './subject-action-type-delete-dialog';

const SubjectActionTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SubjectActionType />} />
    <Route path="new" element={<SubjectActionTypeUpdate />} />
    <Route path=":id">
      <Route index element={<SubjectActionTypeDetail />} />
      <Route path="edit" element={<SubjectActionTypeUpdate />} />
      <Route path="delete" element={<SubjectActionTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SubjectActionTypeRoutes;
