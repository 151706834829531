import dayjs from 'dayjs';

export interface IConfigurationSetting {
  id?: string;
  legacyId?: number | null;
  code?: string | null;
  value?: string | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<IConfigurationSetting> = {};
