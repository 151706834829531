import dayjs from 'dayjs';
import { IPublicationRelease } from 'app/shared/model/publication-release.model';
import { IClassifier } from 'app/shared/model/classifier.model';

export interface IFileset {
  id?: string;
  legacyId?: number | null;
  title?: string;
  confirmedAt?: dayjs.Dayjs;
  publishedAt?: dayjs.Dayjs;
  fedoraId?: string | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  release?: IPublicationRelease | null;
  filesStatus?: IClassifier | null;
}

export const defaultValue: Readonly<IFileset> = {};
