import dayjs from 'dayjs';
import { IClassifier } from 'app/shared/model/classifier.model';
import { IPublicationRelease } from 'app/shared/model/publication-release.model';
import { IPublicationReleasePersonRole } from 'app/shared/model/publication-release-person-role.model';

export interface IPublicationReleasePerson {
  id?: number;
  roleName?: string;
  orderNo?: number;
  firstName?: string;
  lastName?: string;
  orgName?: string;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  type?: IClassifier | null;
  release?: IPublicationRelease | null;
  role?: IPublicationReleasePersonRole | null;
}

export const defaultValue: Readonly<IPublicationReleasePerson> = {};
