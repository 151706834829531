import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './distributor.reducer';

export const DistributorDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const distributorEntity = useAppSelector(state => state.distributor.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="distributorDetailsHeading">
          <Translate contentKey="kpbackApp.distributor.detail.title">Distributor</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.distributor.id">Id</Translate>
            </span>
          </dt>
          <dd>{distributorEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.distributor.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{distributorEntity.legacyId}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="kpbackApp.distributor.name">Name</Translate>
            </span>
          </dt>
          <dd>{distributorEntity.name}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.distributor.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{distributorEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.distributor.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {distributorEntity.createdAt ? <TextFormat value={distributorEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.distributor.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{distributorEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.distributor.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {distributorEntity.modifiedAt ? <TextFormat value={distributorEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.distributor.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{distributorEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.distributor.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {distributorEntity.deletedAt ? <TextFormat value={distributorEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
        </dl>
        <Button tag={Link} to="/distributor" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/distributor/${distributorEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DistributorDetail;
