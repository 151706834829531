import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Classifier from './classifier';
import ClassifierValue from './classifier-value';
import Person from './person';
import KpAccount from './kp-account';
import Publisher from './publisher';
import PublisherRelationship from './publisher-relationship';
import Address from './address';
import PublisherAccount from './publisher-account';
import ISNBlock from './isn-block';
import ISNBlockOwner from './isn-block-owner';
import ISNBlockRange from './isn-block-range';
import ISN from './isn';
import Publication from './publication';
import PublicationRelease from './publication-release';
import PublicationReleasePerson from './publication-release-person';
import PublicationReleasePersonRole from './publication-release-person-role';
import Manufacturer from './manufacturer';
import NetworkAccess from './network-access';
import Version from './version';
import License from './license';
import Fileset from './fileset';
import FilesetItem from './fileset-item';
import Rejection from './rejection';
import RejectionReason from './rejection-reason';
import ContentType from './content-type';
import Format from './format';
import Medium from './medium';
import Language from './language';
import IsnClass from './isn-class';
import PublishPeriod from './publish-period';
import ActionLogType from './action-log-type';
import ConfigurationSetting from './configuration-setting';
import ActionLog from './action-log';
import PublicNotification from './public-notification';
import LegalOwner from './legal-owner';
import Network from './network';
import Distributor from './distributor';
import SubjectActionType from './subject-action-type';
import Role from './role';
import AccessRight from './access-right';
import Keyword from './keyword';
import DigitalPublication from './digital-publication';
import DigitalPublicationRow from './digital-publication-row';
import Translation from './translation';
import Note from './note';
import PendingFiles from './pending-files';
import PublisherEmail from './publisher-email';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="classifier/*" element={<Classifier />} />
        <Route path="classifier-value/*" element={<ClassifierValue />} />
        <Route path="person/*" element={<Person />} />
        <Route path="kp-account/*" element={<KpAccount />} />
        <Route path="publisher/*" element={<Publisher />} />
        <Route path="publisher-relationship/*" element={<PublisherRelationship />} />
        <Route path="address/*" element={<Address />} />
        <Route path="publisher-account/*" element={<PublisherAccount />} />
        <Route path="isn-block/*" element={<ISNBlock />} />
        <Route path="isn-block-owner/*" element={<ISNBlockOwner />} />
        <Route path="isn-block-range/*" element={<ISNBlockRange />} />
        <Route path="isn/*" element={<ISN />} />
        <Route path="publication/*" element={<Publication />} />
        <Route path="publication-release/*" element={<PublicationRelease />} />
        <Route path="publication-release-person/*" element={<PublicationReleasePerson />} />
        <Route path="publication-release-person-role/*" element={<PublicationReleasePersonRole />} />
        <Route path="manufacturer/*" element={<Manufacturer />} />
        <Route path="network-access/*" element={<NetworkAccess />} />
        <Route path="version/*" element={<Version />} />
        <Route path="license/*" element={<License />} />
        <Route path="fileset/*" element={<Fileset />} />
        <Route path="fileset-item/*" element={<FilesetItem />} />
        <Route path="rejection/*" element={<Rejection />} />
        <Route path="rejection-reason/*" element={<RejectionReason />} />
        <Route path="content-type/*" element={<ContentType />} />
        <Route path="format/*" element={<Format />} />
        <Route path="medium/*" element={<Medium />} />
        <Route path="language/*" element={<Language />} />
        <Route path="isn-class/*" element={<IsnClass />} />
        <Route path="publish-period/*" element={<PublishPeriod />} />
        <Route path="action-log-type/*" element={<ActionLogType />} />
        <Route path="configuration-setting/*" element={<ConfigurationSetting />} />
        <Route path="action-log/*" element={<ActionLog />} />
        <Route path="public-notification/*" element={<PublicNotification />} />
        <Route path="legal-owner/*" element={<LegalOwner />} />
        <Route path="network/*" element={<Network />} />
        <Route path="distributor/*" element={<Distributor />} />
        <Route path="subject-action-type/*" element={<SubjectActionType />} />
        <Route path="role/*" element={<Role />} />
        <Route path="access-right/*" element={<AccessRight />} />
        <Route path="keyword/*" element={<Keyword />} />
        <Route path="digital-publication/*" element={<DigitalPublication />} />
        <Route path="digital-publication-row/*" element={<DigitalPublicationRow />} />
        <Route path="translation/*" element={<Translation />} />
        <Route path="note/*" element={<Note />} />
        <Route path="pending-files/*" element={<PendingFiles />} />
        <Route path="publisher-email/*" element={<PublisherEmail />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
