import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DigitalPublicationRow from './digital-publication-row';
import DigitalPublicationRowDetail from './digital-publication-row-detail';
import DigitalPublicationRowUpdate from './digital-publication-row-update';
import DigitalPublicationRowDeleteDialog from './digital-publication-row-delete-dialog';

const DigitalPublicationRowRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DigitalPublicationRow />} />
    <Route path="new" element={<DigitalPublicationRowUpdate />} />
    <Route path=":id">
      <Route index element={<DigitalPublicationRowDetail />} />
      <Route path="edit" element={<DigitalPublicationRowUpdate />} />
      <Route path="delete" element={<DigitalPublicationRowDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DigitalPublicationRowRoutes;
