import dayjs from 'dayjs';
import { IDigitalPublication } from 'app/shared/model/digital-publication.model';
import { ILicense } from 'app/shared/model/license.model';
import { IClassifier } from 'app/shared/model/classifier.model';

export interface IDigitalPublicationRow {
  id?: string;
  legacyId?: number | null;
  sierraRecordNumber?: string | null;
  fileFormat?: string | null;
  webpage?: string | null;
  licenseOther?: string | null;
  licenseComment?: string | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  digitalPublication?: IDigitalPublication | null;
  license?: ILicense | null;
  status?: IClassifier | null;
}

export const defaultValue: Readonly<IDigitalPublicationRow> = {};
