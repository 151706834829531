import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './version.reducer';

export const VersionDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const versionEntity = useAppSelector(state => state.version.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="versionDetailsHeading">
          <Translate contentKey="kpbackApp.version.detail.title">Version</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.version.id">Id</Translate>
            </span>
          </dt>
          <dd>{versionEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.version.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{versionEntity.legacyId}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="kpbackApp.version.title">Title</Translate>
            </span>
          </dt>
          <dd>{versionEntity.title}</dd>
          <dt>
            <span id="orderNo">
              <Translate contentKey="kpbackApp.version.orderNo">Order No</Translate>
            </span>
          </dt>
          <dd>{versionEntity.orderNo}</dd>
          <dt>
            <span id="isDeleted">
              <Translate contentKey="kpbackApp.version.isDeleted">Is Deleted</Translate>
            </span>
          </dt>
          <dd>{versionEntity.isDeleted ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.version.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{versionEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.version.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{versionEntity.createdAt ? <TextFormat value={versionEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.version.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{versionEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.version.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>{versionEntity.modifiedAt ? <TextFormat value={versionEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.version.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{versionEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.version.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>{versionEntity.deletedAt ? <TextFormat value={versionEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
        </dl>
        <Button tag={Link} to="/version" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/version/${versionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default VersionDetail;
