import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './publication-release-person-role.reducer';

export const PublicationReleasePersonRoleDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const publicationReleasePersonRoleEntity = useAppSelector(state => state.publicationReleasePersonRole.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="publicationReleasePersonRoleDetailsHeading">
          <Translate contentKey="kpbackApp.publicationReleasePersonRole.detail.title">PublicationReleasePersonRole</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonRoleEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="kpbackApp.publicationReleasePersonRole.name">Name</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonRoleEntity.name}</dd>
          <dt>
            <span id="isOther">
              <Translate contentKey="kpbackApp.publicationReleasePersonRole.isOther">Is Other</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonRoleEntity.isOther ? 'true' : 'false'}</dd>
          <dt>
            <span id="isComposer">
              <Translate contentKey="kpbackApp.publicationReleasePersonRole.isComposer">Is Composer</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonRoleEntity.isComposer ? 'true' : 'false'}</dd>
          <dt>
            <span id="isAuthor">
              <Translate contentKey="kpbackApp.publicationReleasePersonRole.isAuthor">Is Author</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonRoleEntity.isAuthor ? 'true' : 'false'}</dd>
          <dt>
            <span id="targetCode">
              <Translate contentKey="kpbackApp.publicationReleasePersonRole.targetCode">Target Code</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonRoleEntity.targetCode}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.publicationReleasePersonRole.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonRoleEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.publicationReleasePersonRole.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {publicationReleasePersonRoleEntity.createdAt ? (
              <TextFormat value={publicationReleasePersonRoleEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.publicationReleasePersonRole.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonRoleEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.publicationReleasePersonRole.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {publicationReleasePersonRoleEntity.modifiedAt ? (
              <TextFormat value={publicationReleasePersonRoleEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.publicationReleasePersonRole.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonRoleEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.publicationReleasePersonRole.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {publicationReleasePersonRoleEntity.deletedAt ? (
              <TextFormat value={publicationReleasePersonRoleEntity.deletedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/publication-release-person-role" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/publication-release-person-role/${publicationReleasePersonRoleEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PublicationReleasePersonRoleDetail;
