import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ActionLog from './action-log';
import ActionLogDetail from './action-log-detail';
import ActionLogUpdate from './action-log-update';
import ActionLogDeleteDialog from './action-log-delete-dialog';

const ActionLogRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ActionLog />} />
    <Route path="new" element={<ActionLogUpdate />} />
    <Route path=":id">
      <Route index element={<ActionLogDetail />} />
      <Route path="edit" element={<ActionLogUpdate />} />
      <Route path="delete" element={<ActionLogDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ActionLogRoutes;
