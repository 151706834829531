import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './rejection.reducer';

export const RejectionDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const rejectionEntity = useAppSelector(state => state.rejection.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="rejectionDetailsHeading">
          <Translate contentKey="kpbackApp.rejection.detail.title">Rejection</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.rejection.id">Id</Translate>
            </span>
          </dt>
          <dd>{rejectionEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.rejection.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{rejectionEntity.legacyId}</dd>
          <dt>
            <span id="comment">
              <Translate contentKey="kpbackApp.rejection.comment">Comment</Translate>
            </span>
          </dt>
          <dd>{rejectionEntity.comment}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.rejection.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{rejectionEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.rejection.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {rejectionEntity.createdAt ? <TextFormat value={rejectionEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.rejection.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{rejectionEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.rejection.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {rejectionEntity.modifiedAt ? <TextFormat value={rejectionEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.rejection.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{rejectionEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.rejection.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {rejectionEntity.deletedAt ? <TextFormat value={rejectionEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="kpbackApp.rejection.languageCode">Language Code</Translate>
          </dt>
          <dd>{rejectionEntity.languageCode ? rejectionEntity.languageCode.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.rejection.publication">Publication</Translate>
          </dt>
          <dd>{rejectionEntity.publication ? rejectionEntity.publication.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.rejection.rejectionReason">Rejection Reason</Translate>
          </dt>
          <dd>{rejectionEntity.rejectionReason ? rejectionEntity.rejectionReason.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.rejection.digitalPublicationRow">Digital Publication Row</Translate>
          </dt>
          <dd>{rejectionEntity.digitalPublicationRow ? rejectionEntity.digitalPublicationRow.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/rejection" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/rejection/${rejectionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default RejectionDetail;
