import dayjs from 'dayjs';
import { IAddress } from 'app/shared/model/address.model';
import { IPublisherEmail } from 'app/shared/model/publisher-email.model';
import { PublisherType } from 'app/shared/model/enumerations/publisher-type.model';
import { PublisherStatus } from 'app/shared/model/enumerations/publisher-status.model';

export interface IPublisher {
  id?: string;
  legacyId?: number | null;
  publisherType?: keyof typeof PublisherType | null;
  status?: keyof typeof PublisherStatus | null;
  personnelId?: string;
  businessName?: string;
  registryCode?: string | null;
  name?: string;
  allowGrp?: boolean | null;
  phone?: string | null;
  contact?: string | null;
  webpage?: string | null;
  isLibrary?: boolean | null;
  lastSignon?: dayjs.Dayjs | null;
  ftpRoot?: string | null;
  hasPublished?: boolean | null;
  archivedAt?: dayjs.Dayjs | null;
  allowMpiid?: boolean | null;
  createdAt?: dayjs.Dayjs;
  latestIsbn?: string | null;
  maxPublicationCount?: number | null;
  inheritedFromRegister?: boolean | null;
  legalOwner?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  address?: IAddress | null;
  emails?: IPublisherEmail[] | null;
}

export const defaultValue: Readonly<IPublisher> = {
  allowGrp: false,
  isLibrary: false,
  hasPublished: false,
  allowMpiid: false,
  inheritedFromRegister: false,
};
