import dayjs from 'dayjs';

export interface ITranslation {
  id?: string;
  key?: string;
  text?: string | null;
  language?: string | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<ITranslation> = {};
