import dayjs from 'dayjs';
import { IPublication } from 'app/shared/model/publication.model';
import { IPublisher } from 'app/shared/model/publisher.model';

export interface INote {
  id?: string;
  legacyId?: number | null;
  note?: string;
  isPublic?: boolean;
  isDeleted?: boolean;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  publication?: IPublication | null;
  publisher?: IPublisher | null;
}

export const defaultValue: Readonly<INote> = {
  isPublic: false,
  isDeleted: false,
};
