import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PublicNotification from './public-notification';
import PublicNotificationDetail from './public-notification-detail';
import PublicNotificationUpdate from './public-notification-update';
import PublicNotificationDeleteDialog from './public-notification-delete-dialog';

const PublicNotificationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PublicNotification />} />
    <Route path="new" element={<PublicNotificationUpdate />} />
    <Route path=":id">
      <Route index element={<PublicNotificationDetail />} />
      <Route path="edit" element={<PublicNotificationUpdate />} />
      <Route path="delete" element={<PublicNotificationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PublicNotificationRoutes;
