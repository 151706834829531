import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ActionLogType from './action-log-type';
import ActionLogTypeDetail from './action-log-type-detail';
import ActionLogTypeUpdate from './action-log-type-update';
import ActionLogTypeDeleteDialog from './action-log-type-delete-dialog';

const ActionLogTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ActionLogType />} />
    <Route path="new" element={<ActionLogTypeUpdate />} />
    <Route path=":id">
      <Route index element={<ActionLogTypeDetail />} />
      <Route path="edit" element={<ActionLogTypeUpdate />} />
      <Route path="delete" element={<ActionLogTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ActionLogTypeRoutes;
