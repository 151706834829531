import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getPaginationState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './publication-release.reducer';

export const PublicationRelease = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const publicationReleaseList = useAppSelector(state => state.publicationRelease.entities);
  const loading = useAppSelector(state => state.publicationRelease.loading);
  const totalItems = useAppSelector(state => state.publicationRelease.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="publication-release-heading" data-cy="PublicationReleaseHeading">
        <Translate contentKey="kpbackApp.publicationRelease.home.title">Publication Releases</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="kpbackApp.publicationRelease.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link
            to="/publication-release/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="kpbackApp.publicationRelease.home.createLabel">Create new Publication Release</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {publicationReleaseList && publicationReleaseList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="kpbackApp.publicationRelease.id">Id</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('legacyId')}>
                  <Translate contentKey="kpbackApp.publicationRelease.legacyId">Legacy Id</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('legacyId')} />
                </th>
                <th className="hand" onClick={sort('licenseOther')}>
                  <Translate contentKey="kpbackApp.publicationRelease.licenseOther">License Other</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('licenseOther')} />
                </th>
                <th className="hand" onClick={sort('licenseComment')}>
                  <Translate contentKey="kpbackApp.publicationRelease.licenseComment">License Comment</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('licenseComment')} />
                </th>
                <th className="hand" onClick={sort('manufacToBeDeterm')}>
                  <Translate contentKey="kpbackApp.publicationRelease.manufacToBeDeterm">Manufac To Be Determ</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('manufacToBeDeterm')} />
                </th>
                <th className="hand" onClick={sort('distributorToBeDeterm')}>
                  <Translate contentKey="kpbackApp.publicationRelease.distributorToBeDeterm">Distributor To Be Determ</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('distributorToBeDeterm')} />
                </th>
                <th className="hand" onClick={sort('mediumOther')}>
                  <Translate contentKey="kpbackApp.publicationRelease.mediumOther">Medium Other</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('mediumOther')} />
                </th>
                <th className="hand" onClick={sort('otherContent')}>
                  <Translate contentKey="kpbackApp.publicationRelease.otherContent">Other Content</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('otherContent')} />
                </th>
                <th className="hand" onClick={sort('height')}>
                  <Translate contentKey="kpbackApp.publicationRelease.height">Height</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('height')} />
                </th>
                <th className="hand" onClick={sort('width')}>
                  <Translate contentKey="kpbackApp.publicationRelease.width">Width</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('width')} />
                </th>
                <th className="hand" onClick={sort('orderNo')}>
                  <Translate contentKey="kpbackApp.publicationRelease.orderNo">Order No</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('orderNo')} />
                </th>
                <th className="hand" onClick={sort('isEdition')}>
                  <Translate contentKey="kpbackApp.publicationRelease.isEdition">Is Edition</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('isEdition')} />
                </th>
                <th className="hand" onClick={sort('editionNo')}>
                  <Translate contentKey="kpbackApp.publicationRelease.editionNo">Edition No</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('editionNo')} />
                </th>
                <th className="hand" onClick={sort('editionImproved')}>
                  <Translate contentKey="kpbackApp.publicationRelease.editionImproved">Edition Improved</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('editionImproved')} />
                </th>
                <th className="hand" onClick={sort('editionEdited')}>
                  <Translate contentKey="kpbackApp.publicationRelease.editionEdited">Edition Edited</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('editionEdited')} />
                </th>
                <th className="hand" onClick={sort('editionRePrint')}>
                  <Translate contentKey="kpbackApp.publicationRelease.editionRePrint">Edition Re Print</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('editionRePrint')} />
                </th>
                <th className="hand" onClick={sort('editionOther')}>
                  <Translate contentKey="kpbackApp.publicationRelease.editionOther">Edition Other</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('editionOther')} />
                </th>
                <th className="hand" onClick={sort('printRun')}>
                  <Translate contentKey="kpbackApp.publicationRelease.printRun">Print Run</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('printRun')} />
                </th>
                <th className="hand" onClick={sort('pages')}>
                  <Translate contentKey="kpbackApp.publicationRelease.pages">Pages</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('pages')} />
                </th>
                <th className="hand" onClick={sort('hasAppendices')}>
                  <Translate contentKey="kpbackApp.publicationRelease.hasAppendices">Has Appendices</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('hasAppendices')} />
                </th>
                <th className="hand" onClick={sort('otherAppendix')}>
                  <Translate contentKey="kpbackApp.publicationRelease.otherAppendix">Other Appendix</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('otherAppendix')} />
                </th>
                <th className="hand" onClick={sort('webpage')}>
                  <Translate contentKey="kpbackApp.publicationRelease.webpage">Webpage</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('webpage')} />
                </th>
                <th className="hand" onClick={sort('isAppendix')}>
                  <Translate contentKey="kpbackApp.publicationRelease.isAppendix">Is Appendix</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('isAppendix')} />
                </th>
                <th className="hand" onClick={sort('indepDistributed')}>
                  <Translate contentKey="kpbackApp.publicationRelease.indepDistributed">Indep Distributed</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('indepDistributed')} />
                </th>
                <th className="hand" onClick={sort('title')}>
                  <Translate contentKey="kpbackApp.publicationRelease.title">Title</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('title')} />
                </th>
                <th className="hand" onClick={sort('hasDrm')}>
                  <Translate contentKey="kpbackApp.publicationRelease.hasDrm">Has Drm</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('hasDrm')} />
                </th>
                <th className="hand" onClick={sort('hasWatermark')}>
                  <Translate contentKey="kpbackApp.publicationRelease.hasWatermark">Has Watermark</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('hasWatermark')} />
                </th>
                <th className="hand" onClick={sort('hasManufacturer')}>
                  <Translate contentKey="kpbackApp.publicationRelease.hasManufacturer">Has Manufacturer</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('hasManufacturer')} />
                </th>
                <th className="hand" onClick={sort('isReference')}>
                  <Translate contentKey="kpbackApp.publicationRelease.isReference">Is Reference</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('isReference')} />
                </th>
                <th className="hand" onClick={sort('refTitle')}>
                  <Translate contentKey="kpbackApp.publicationRelease.refTitle">Ref Title</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('refTitle')} />
                </th>
                <th className="hand" onClick={sort('refIsn')}>
                  <Translate contentKey="kpbackApp.publicationRelease.refIsn">Ref Isn</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('refIsn')} />
                </th>
                <th className="hand" onClick={sort('refPublisher')}>
                  <Translate contentKey="kpbackApp.publicationRelease.refPublisher">Ref Publisher</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('refPublisher')} />
                </th>
                <th className="hand" onClick={sort('refYear')}>
                  <Translate contentKey="kpbackApp.publicationRelease.refYear">Ref Year</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('refYear')} />
                </th>
                <th className="hand" onClick={sort('refNo')}>
                  <Translate contentKey="kpbackApp.publicationRelease.refNo">Ref No</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('refNo')} />
                </th>
                <th className="hand" onClick={sort('refLocation')}>
                  <Translate contentKey="kpbackApp.publicationRelease.refLocation">Ref Location</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('refLocation')} />
                </th>
                <th className="hand" onClick={sort('scale')}>
                  <Translate contentKey="kpbackApp.publicationRelease.scale">Scale</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('scale')} />
                </th>
                <th className="hand" onClick={sort('languageOther')}>
                  <Translate contentKey="kpbackApp.publicationRelease.languageOther">Language Other</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('languageOther')} />
                </th>
                <th className="hand" onClick={sort('hasIsbnRef')}>
                  <Translate contentKey="kpbackApp.publicationRelease.hasIsbnRef">Has Isbn Ref</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('hasIsbnRef')} />
                </th>
                <th className="hand" onClick={sort('isbnRefIsbn')}>
                  <Translate contentKey="kpbackApp.publicationRelease.isbnRefIsbn">Isbn Ref Isbn</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('isbnRefIsbn')} />
                </th>
                <th className="hand" onClick={sort('isbnRefAuthor')}>
                  <Translate contentKey="kpbackApp.publicationRelease.isbnRefAuthor">Isbn Ref Author</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('isbnRefAuthor')} />
                </th>
                <th className="hand" onClick={sort('isbnRefTitle')}>
                  <Translate contentKey="kpbackApp.publicationRelease.isbnRefTitle">Isbn Ref Title</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('isbnRefTitle')} />
                </th>
                <th className="hand" onClick={sort('isbnRefPublisher')}>
                  <Translate contentKey="kpbackApp.publicationRelease.isbnRefPublisher">Isbn Ref Publisher</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('isbnRefPublisher')} />
                </th>
                <th className="hand" onClick={sort('isbnRefYear')}>
                  <Translate contentKey="kpbackApp.publicationRelease.isbnRefYear">Isbn Ref Year</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('isbnRefYear')} />
                </th>
                <th className="hand" onClick={sort('isbnRefLocation')}>
                  <Translate contentKey="kpbackApp.publicationRelease.isbnRefLocation">Isbn Ref Location</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('isbnRefLocation')} />
                </th>
                <th className="hand" onClick={sort('esterId')}>
                  <Translate contentKey="kpbackApp.publicationRelease.esterId">Ester Id</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('esterId')} />
                </th>
                <th className="hand" onClick={sort('isDeleted')}>
                  <Translate contentKey="kpbackApp.publicationRelease.isDeleted">Is Deleted</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('isDeleted')} />
                </th>
                <th className="hand" onClick={sort('createdBy')}>
                  <Translate contentKey="kpbackApp.publicationRelease.createdBy">Created By</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('createdBy')} />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="kpbackApp.publicationRelease.createdAt">Created At</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('createdAt')} />
                </th>
                <th className="hand" onClick={sort('modifiedBy')}>
                  <Translate contentKey="kpbackApp.publicationRelease.modifiedBy">Modified By</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('modifiedBy')} />
                </th>
                <th className="hand" onClick={sort('modifiedAt')}>
                  <Translate contentKey="kpbackApp.publicationRelease.modifiedAt">Modified At</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('modifiedAt')} />
                </th>
                <th className="hand" onClick={sort('deletedBy')}>
                  <Translate contentKey="kpbackApp.publicationRelease.deletedBy">Deleted By</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('deletedBy')} />
                </th>
                <th className="hand" onClick={sort('deletedAt')}>
                  <Translate contentKey="kpbackApp.publicationRelease.deletedAt">Deleted At</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('deletedAt')} />
                </th>
                <th>
                  <Translate contentKey="kpbackApp.publicationRelease.manufacturer">Manufacturer</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="kpbackApp.publicationRelease.distributor">Distributor</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="kpbackApp.publicationRelease.isn">Isn</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="kpbackApp.publicationRelease.publication">Publication</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="kpbackApp.publicationRelease.version">Version</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="kpbackApp.publicationRelease.medium">Medium</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="kpbackApp.publicationRelease.contentType">Content Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="kpbackApp.publicationRelease.format">Format</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="kpbackApp.publicationRelease.languageCode">Language Code</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="kpbackApp.publicationRelease.license">License</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="kpbackApp.publicationRelease.publicationRelease">Publication Release</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {publicationReleaseList.map((publicationRelease, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/publication-release/${publicationRelease.id}`} color="link" size="sm">
                      {publicationRelease.id}
                    </Button>
                  </td>
                  <td>{publicationRelease.legacyId}</td>
                  <td>{publicationRelease.licenseOther}</td>
                  <td>{publicationRelease.licenseComment}</td>
                  <td>{publicationRelease.manufacToBeDeterm ? 'true' : 'false'}</td>
                  <td>{publicationRelease.distributorToBeDeterm ? 'true' : 'false'}</td>
                  <td>{publicationRelease.mediumOther}</td>
                  <td>{publicationRelease.otherContent}</td>
                  <td>{publicationRelease.height}</td>
                  <td>{publicationRelease.width}</td>
                  <td>{publicationRelease.orderNo}</td>
                  <td>{publicationRelease.isEdition ? 'true' : 'false'}</td>
                  <td>{publicationRelease.editionNo}</td>
                  <td>{publicationRelease.editionImproved ? 'true' : 'false'}</td>
                  <td>{publicationRelease.editionEdited ? 'true' : 'false'}</td>
                  <td>{publicationRelease.editionRePrint ? 'true' : 'false'}</td>
                  <td>{publicationRelease.editionOther}</td>
                  <td>{publicationRelease.printRun}</td>
                  <td>{publicationRelease.pages}</td>
                  <td>{publicationRelease.hasAppendices ? 'true' : 'false'}</td>
                  <td>{publicationRelease.otherAppendix}</td>
                  <td>{publicationRelease.webpage}</td>
                  <td>{publicationRelease.isAppendix ? 'true' : 'false'}</td>
                  <td>{publicationRelease.indepDistributed ? 'true' : 'false'}</td>
                  <td>{publicationRelease.title}</td>
                  <td>{publicationRelease.hasDrm ? 'true' : 'false'}</td>
                  <td>{publicationRelease.hasWatermark ? 'true' : 'false'}</td>
                  <td>{publicationRelease.hasManufacturer ? 'true' : 'false'}</td>
                  <td>{publicationRelease.isReference ? 'true' : 'false'}</td>
                  <td>{publicationRelease.refTitle}</td>
                  <td>{publicationRelease.refIsn}</td>
                  <td>{publicationRelease.refPublisher}</td>
                  <td>{publicationRelease.refYear}</td>
                  <td>{publicationRelease.refNo}</td>
                  <td>{publicationRelease.refLocation}</td>
                  <td>{publicationRelease.scale}</td>
                  <td>{publicationRelease.languageOther}</td>
                  <td>{publicationRelease.hasIsbnRef ? 'true' : 'false'}</td>
                  <td>{publicationRelease.isbnRefIsbn}</td>
                  <td>{publicationRelease.isbnRefAuthor}</td>
                  <td>{publicationRelease.isbnRefTitle}</td>
                  <td>{publicationRelease.isbnRefPublisher}</td>
                  <td>{publicationRelease.isbnRefYear}</td>
                  <td>{publicationRelease.isbnRefLocation}</td>
                  <td>{publicationRelease.esterId}</td>
                  <td>{publicationRelease.isDeleted ? 'true' : 'false'}</td>
                  <td>{publicationRelease.createdBy}</td>
                  <td>
                    {publicationRelease.createdAt ? (
                      <TextFormat type="date" value={publicationRelease.createdAt} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{publicationRelease.modifiedBy}</td>
                  <td>
                    {publicationRelease.modifiedAt ? (
                      <TextFormat type="date" value={publicationRelease.modifiedAt} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{publicationRelease.deletedBy}</td>
                  <td>
                    {publicationRelease.deletedAt ? (
                      <TextFormat type="date" value={publicationRelease.deletedAt} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {publicationRelease.manufacturer ? (
                      <Link to={`/manufacturer/${publicationRelease.manufacturer.id}`}>{publicationRelease.manufacturer.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {publicationRelease.distributor ? (
                      <Link to={`/manufacturer/${publicationRelease.distributor.id}`}>{publicationRelease.distributor.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{publicationRelease.isn ? <Link to={`/isn/${publicationRelease.isn.id}`}>{publicationRelease.isn.id}</Link> : ''}</td>
                  <td>
                    {publicationRelease.publication ? (
                      <Link to={`/publication/${publicationRelease.publication.id}`}>{publicationRelease.publication.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {publicationRelease.version ? (
                      <Link to={`/version/${publicationRelease.version.id}`}>{publicationRelease.version.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {publicationRelease.medium ? (
                      <Link to={`/medium/${publicationRelease.medium.id}`}>{publicationRelease.medium.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {publicationRelease.contentType ? (
                      <Link to={`/content-type/${publicationRelease.contentType.id}`}>{publicationRelease.contentType.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {publicationRelease.format ? (
                      <Link to={`/format/${publicationRelease.format.id}`}>{publicationRelease.format.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {publicationRelease.languageCode ? (
                      <Link to={`/language/${publicationRelease.languageCode.id}`}>{publicationRelease.languageCode.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {publicationRelease.license ? (
                      <Link to={`/license/${publicationRelease.license.id}`}>{publicationRelease.license.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {publicationRelease.publicationRelease ? (
                      <Link to={`/publication-release/${publicationRelease.publicationRelease.id}`}>
                        {publicationRelease.publicationRelease.id}
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/publication-release/${publicationRelease.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/publication-release/${publicationRelease.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/publication-release/${publicationRelease.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="kpbackApp.publicationRelease.home.notFound">No Publication Releases found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={publicationReleaseList && publicationReleaseList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PublicationRelease;
