import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './kp-account.reducer';

export const KpAccountDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const kpAccountEntity = useAppSelector(state => state.kpAccount.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="kpAccountDetailsHeading">
          <Translate contentKey="kpbackApp.kpAccount.detail.title">KpAccount</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.kpAccount.id">Id</Translate>
            </span>
          </dt>
          <dd>{kpAccountEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.kpAccount.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{kpAccountEntity.legacyId}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="kpbackApp.kpAccount.email">Email</Translate>
            </span>
          </dt>
          <dd>{kpAccountEntity.email}</dd>
          <dt>
            <span id="isStaff">
              <Translate contentKey="kpbackApp.kpAccount.isStaff">Is Staff</Translate>
            </span>
          </dt>
          <dd>{kpAccountEntity.isStaff ? 'true' : 'false'}</dd>
          <dt>
            <span id="validTo">
              <Translate contentKey="kpbackApp.kpAccount.validTo">Valid To</Translate>
            </span>
          </dt>
          <dd>{kpAccountEntity.validTo ? <TextFormat value={kpAccountEntity.validTo} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="activatedAt">
              <Translate contentKey="kpbackApp.kpAccount.activatedAt">Activated At</Translate>
            </span>
          </dt>
          <dd>
            {kpAccountEntity.activatedAt ? <TextFormat value={kpAccountEntity.activatedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="activationHash">
              <Translate contentKey="kpbackApp.kpAccount.activationHash">Activation Hash</Translate>
            </span>
          </dt>
          <dd>{kpAccountEntity.activationHash}</dd>
          <dt>
            <span id="activationHashSentAt">
              <Translate contentKey="kpbackApp.kpAccount.activationHashSentAt">Activation Hash Sent At</Translate>
            </span>
          </dt>
          <dd>
            {kpAccountEntity.activationHashSentAt ? (
              <TextFormat value={kpAccountEntity.activationHashSentAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lastLoginAt">
              <Translate contentKey="kpbackApp.kpAccount.lastLoginAt">Last Login At</Translate>
            </span>
          </dt>
          <dd>
            {kpAccountEntity.lastLoginAt ? <TextFormat value={kpAccountEntity.lastLoginAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="notificationsSeenAt">
              <Translate contentKey="kpbackApp.kpAccount.notificationsSeenAt">Notifications Seen At</Translate>
            </span>
          </dt>
          <dd>
            {kpAccountEntity.notificationsSeenAt ? (
              <TextFormat value={kpAccountEntity.notificationsSeenAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="ftpAccountName">
              <Translate contentKey="kpbackApp.kpAccount.ftpAccountName">Ftp Account Name</Translate>
            </span>
          </dt>
          <dd>{kpAccountEntity.ftpAccountName}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.kpAccount.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{kpAccountEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.kpAccount.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {kpAccountEntity.createdAt ? <TextFormat value={kpAccountEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.kpAccount.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{kpAccountEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.kpAccount.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {kpAccountEntity.modifiedAt ? <TextFormat value={kpAccountEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.kpAccount.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{kpAccountEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.kpAccount.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {kpAccountEntity.deletedAt ? <TextFormat value={kpAccountEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="kpbackApp.kpAccount.person">Person</Translate>
          </dt>
          <dd>{kpAccountEntity.person ? kpAccountEntity.person.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.kpAccount.role">Role</Translate>
          </dt>
          <dd>{kpAccountEntity.role ? kpAccountEntity.role.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.kpAccount.accessRight">Access Right</Translate>
          </dt>
          <dd>
            {kpAccountEntity.accessRights
              ? kpAccountEntity.accessRights.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {kpAccountEntity.accessRights && i === kpAccountEntity.accessRights.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/kp-account" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/kp-account/${kpAccountEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default KpAccountDetail;
