import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './address.reducer';

export const AddressDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const addressEntity = useAppSelector(state => state.address.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="addressDetailsHeading">
          <Translate contentKey="kpbackApp.address.detail.title">Address</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.address.id">Id</Translate>
            </span>
          </dt>
          <dd>{addressEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.address.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{addressEntity.legacyId}</dd>
          <dt>
            <span id="adsOid">
              <Translate contentKey="kpbackApp.address.adsOid">Ads Oid</Translate>
            </span>
          </dt>
          <dd>{addressEntity.adsOid}</dd>
          <dt>
            <span id="street">
              <Translate contentKey="kpbackApp.address.street">Street</Translate>
            </span>
          </dt>
          <dd>{addressEntity.street}</dd>
          <dt>
            <span id="zipCode">
              <Translate contentKey="kpbackApp.address.zipCode">Zip Code</Translate>
            </span>
          </dt>
          <dd>{addressEntity.zipCode}</dd>
          <dt>
            <span id="city">
              <Translate contentKey="kpbackApp.address.city">City</Translate>
            </span>
          </dt>
          <dd>{addressEntity.city}</dd>
          <dt>
            <span id="county">
              <Translate contentKey="kpbackApp.address.county">County</Translate>
            </span>
          </dt>
          <dd>{addressEntity.county}</dd>
          <dt>
            <span id="country">
              <Translate contentKey="kpbackApp.address.country">Country</Translate>
            </span>
          </dt>
          <dd>{addressEntity.country}</dd>
          <dt>
            <span id="addressName">
              <Translate contentKey="kpbackApp.address.addressName">Address Name</Translate>
            </span>
          </dt>
          <dd>{addressEntity.addressName}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.address.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{addressEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.address.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{addressEntity.createdAt ? <TextFormat value={addressEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.address.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{addressEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.address.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>{addressEntity.modifiedAt ? <TextFormat value={addressEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.address.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{addressEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.address.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>{addressEntity.deletedAt ? <TextFormat value={addressEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
        </dl>
        <Button tag={Link} to="/address" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/address/${addressEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AddressDetail;
