import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ClassifierValue from './classifier-value';
import ClassifierValueDetail from './classifier-value-detail';
import ClassifierValueUpdate from './classifier-value-update';
import ClassifierValueDeleteDialog from './classifier-value-delete-dialog';

const ClassifierValueRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ClassifierValue />} />
    <Route path="new" element={<ClassifierValueUpdate />} />
    <Route path=":id">
      <Route index element={<ClassifierValueDetail />} />
      <Route path="edit" element={<ClassifierValueUpdate />} />
      <Route path="delete" element={<ClassifierValueDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ClassifierValueRoutes;
