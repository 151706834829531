import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Rejection from './rejection';
import RejectionDetail from './rejection-detail';
import RejectionUpdate from './rejection-update';
import RejectionDeleteDialog from './rejection-delete-dialog';

const RejectionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Rejection />} />
    <Route path="new" element={<RejectionUpdate />} />
    <Route path=":id">
      <Route index element={<RejectionDetail />} />
      <Route path="edit" element={<RejectionUpdate />} />
      <Route path="delete" element={<RejectionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RejectionRoutes;
