import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ISNBlockOwner from './isn-block-owner';
import ISNBlockOwnerDetail from './isn-block-owner-detail';
import ISNBlockOwnerUpdate from './isn-block-owner-update';
import ISNBlockOwnerDeleteDialog from './isn-block-owner-delete-dialog';

const ISNBlockOwnerRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ISNBlockOwner />} />
    <Route path="new" element={<ISNBlockOwnerUpdate />} />
    <Route path=":id">
      <Route index element={<ISNBlockOwnerDetail />} />
      <Route path="edit" element={<ISNBlockOwnerUpdate />} />
      <Route path="delete" element={<ISNBlockOwnerDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ISNBlockOwnerRoutes;
