import classifier from 'app/entities/classifier/classifier.reducer';
import classifierValue from 'app/entities/classifier-value/classifier-value.reducer';
import person from 'app/entities/person/person.reducer';
import kpAccount from 'app/entities/kp-account/kp-account.reducer';
import publisher from 'app/entities/publisher/publisher.reducer';
import publisherRelationship from 'app/entities/publisher-relationship/publisher-relationship.reducer';
import address from 'app/entities/address/address.reducer';
import publisherAccount from 'app/entities/publisher-account/publisher-account.reducer';
import iSNBlock from 'app/entities/isn-block/isn-block.reducer';
import iSNBlockOwner from 'app/entities/isn-block-owner/isn-block-owner.reducer';
import iSNBlockRange from 'app/entities/isn-block-range/isn-block-range.reducer';
import iSN from 'app/entities/isn/isn.reducer';
import publication from 'app/entities/publication/publication.reducer';
import publicationRelease from 'app/entities/publication-release/publication-release.reducer';
import publicationReleasePerson from 'app/entities/publication-release-person/publication-release-person.reducer';
import publicationReleasePersonRole from 'app/entities/publication-release-person-role/publication-release-person-role.reducer';
import manufacturer from 'app/entities/manufacturer/manufacturer.reducer';
import networkAccess from 'app/entities/network-access/network-access.reducer';
import version from 'app/entities/version/version.reducer';
import license from 'app/entities/license/license.reducer';
import fileset from 'app/entities/fileset/fileset.reducer';
import filesetItem from 'app/entities/fileset-item/fileset-item.reducer';
import rejection from 'app/entities/rejection/rejection.reducer';
import rejectionReason from 'app/entities/rejection-reason/rejection-reason.reducer';
import contentType from 'app/entities/content-type/content-type.reducer';
import format from 'app/entities/format/format.reducer';
import medium from 'app/entities/medium/medium.reducer';
import language from 'app/entities/language/language.reducer';
import isnClass from 'app/entities/isn-class/isn-class.reducer';
import publishPeriod from 'app/entities/publish-period/publish-period.reducer';
import actionLogType from 'app/entities/action-log-type/action-log-type.reducer';
import configurationSetting from 'app/entities/configuration-setting/configuration-setting.reducer';
import actionLog from 'app/entities/action-log/action-log.reducer';
import publicNotification from 'app/entities/public-notification/public-notification.reducer';
import legalOwner from 'app/entities/legal-owner/legal-owner.reducer';
import network from 'app/entities/network/network.reducer';
import distributor from 'app/entities/distributor/distributor.reducer';
import subjectActionType from 'app/entities/subject-action-type/subject-action-type.reducer';
import role from 'app/entities/role/role.reducer';
import accessRight from 'app/entities/access-right/access-right.reducer';
import keyword from 'app/entities/keyword/keyword.reducer';
import digitalPublication from 'app/entities/digital-publication/digital-publication.reducer';
import digitalPublicationRow from 'app/entities/digital-publication-row/digital-publication-row.reducer';
import translation from 'app/entities/translation/translation.reducer';
import note from 'app/entities/note/note.reducer';
import pendingFiles from 'app/entities/pending-files/pending-files.reducer';
import publisherEmail from 'app/entities/publisher-email/publisher-email.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  classifier,
  classifierValue,
  person,
  kpAccount,
  publisher,
  publisherRelationship,
  address,
  publisherAccount,
  iSNBlock,
  iSNBlockOwner,
  iSNBlockRange,
  iSN,
  publication,
  publicationRelease,
  publicationReleasePerson,
  publicationReleasePersonRole,
  manufacturer,
  networkAccess,
  version,
  license,
  fileset,
  filesetItem,
  rejection,
  rejectionReason,
  contentType,
  format,
  medium,
  language,
  isnClass,
  publishPeriod,
  actionLogType,
  configurationSetting,
  actionLog,
  publicNotification,
  legalOwner,
  network,
  distributor,
  subjectActionType,
  role,
  accessRight,
  keyword,
  digitalPublication,
  digitalPublicationRow,
  translation,
  note,
  pendingFiles,
  publisherEmail,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
