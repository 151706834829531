export enum FileStatus {
  MISSING = 'N',

  UPLOADED = 'U',

  SENT = 'S',

  CONFIRMED = 'C',

  REJECTED = 'R',

  DELETED = 'D',
}
