import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './content-type.reducer';

export const ContentTypeDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const contentTypeEntity = useAppSelector(state => state.contentType.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="contentTypeDetailsHeading">
          <Translate contentKey="kpbackApp.contentType.detail.title">ContentType</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.contentType.id">Id</Translate>
            </span>
          </dt>
          <dd>{contentTypeEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.contentType.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{contentTypeEntity.legacyId}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="kpbackApp.contentType.code">Code</Translate>
            </span>
          </dt>
          <dd>{contentTypeEntity.code}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="kpbackApp.contentType.name">Name</Translate>
            </span>
          </dt>
          <dd>{contentTypeEntity.name}</dd>
          <dt>
            <span id="isOther">
              <Translate contentKey="kpbackApp.contentType.isOther">Is Other</Translate>
            </span>
          </dt>
          <dd>{contentTypeEntity.isOther ? 'true' : 'false'}</dd>
          <dt>
            <span id="forIsmn">
              <Translate contentKey="kpbackApp.contentType.forIsmn">For Ismn</Translate>
            </span>
          </dt>
          <dd>{contentTypeEntity.forIsmn ? 'true' : 'false'}</dd>
          <dt>
            <span id="forIsbn">
              <Translate contentKey="kpbackApp.contentType.forIsbn">For Isbn</Translate>
            </span>
          </dt>
          <dd>{contentTypeEntity.forIsbn ? 'true' : 'false'}</dd>
          <dt>
            <span id="isIsmnName">
              <Translate contentKey="kpbackApp.contentType.isIsmnName">Is Ismn Name</Translate>
            </span>
          </dt>
          <dd>{contentTypeEntity.isIsmnName ? 'true' : 'false'}</dd>
          <dt>
            <span id="forSeparateIsmn">
              <Translate contentKey="kpbackApp.contentType.forSeparateIsmn">For Separate Ismn</Translate>
            </span>
          </dt>
          <dd>{contentTypeEntity.forSeparateIsmn ? 'true' : 'false'}</dd>
          <dt>
            <span id="ismnApxType">
              <Translate contentKey="kpbackApp.contentType.ismnApxType">Ismn Apx Type</Translate>
            </span>
          </dt>
          <dd>{contentTypeEntity.ismnApxType}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.contentType.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{contentTypeEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.contentType.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {contentTypeEntity.createdAt ? <TextFormat value={contentTypeEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.contentType.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{contentTypeEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.contentType.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {contentTypeEntity.modifiedAt ? <TextFormat value={contentTypeEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.contentType.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{contentTypeEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.contentType.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {contentTypeEntity.deletedAt ? <TextFormat value={contentTypeEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
        </dl>
        <Button tag={Link} to="/content-type" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/content-type/${contentTypeEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ContentTypeDetail;
