import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PublisherAccount from './publisher-account';
import PublisherAccountDetail from './publisher-account-detail';
import PublisherAccountUpdate from './publisher-account-update';
import PublisherAccountDeleteDialog from './publisher-account-delete-dialog';

const PublisherAccountRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PublisherAccount />} />
    <Route path="new" element={<PublisherAccountUpdate />} />
    <Route path=":id">
      <Route index element={<PublisherAccountDetail />} />
      <Route path="edit" element={<PublisherAccountUpdate />} />
      <Route path="delete" element={<PublisherAccountDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PublisherAccountRoutes;
