import dayjs from 'dayjs';
import { IKpAccount } from 'app/shared/model/kp-account.model';
import { IPublisher } from 'app/shared/model/publisher.model';

export interface IPublisherAccount {
  id?: string;
  legacyId?: number | null;
  phone?: string | null;
  jobPosition?: string;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  account?: IKpAccount | null;
  publisher?: IPublisher | null;
}

export const defaultValue: Readonly<IPublisherAccount> = {};
