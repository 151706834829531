import dayjs from 'dayjs';
import { IDigitalPublicationRow } from 'app/shared/model/digital-publication-row.model';
import { INetwork } from 'app/shared/model/network.model';
import { IPublicationRelease } from 'app/shared/model/publication-release.model';

export interface INetworkAccess {
  id?: string;
  legacyId?: number | null;
  online?: dayjs.Dayjs;
  download?: dayjs.Dayjs;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  digitalPublicationRow?: IDigitalPublicationRow | null;
  network?: INetwork | null;
  publicationRelease?: IPublicationRelease | null;
}

export const defaultValue: Readonly<INetworkAccess> = {};
