import dayjs from 'dayjs';
import { IPerson } from 'app/shared/model/person.model';
import { IRole } from 'app/shared/model/role.model';
import { IAccessRight } from 'app/shared/model/access-right.model';
import { IPublisherAccount } from 'app/shared/model/publisher-account.model';

export interface IKpAccount {
  id?: string;
  legacyId?: number | null;
  email?: string;
  isStaff?: boolean;
  validTo?: dayjs.Dayjs | null;
  activatedAt?: dayjs.Dayjs | null;
  activationHash?: string | null;
  activationHashSentAt?: dayjs.Dayjs | null;
  lastLoginAt?: dayjs.Dayjs | null;
  notificationsSeenAt?: dayjs.Dayjs | null;
  ftpAccountName?: string | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  person?: IPerson | null;
  role?: IRole | null;
  accessRights?: IAccessRight[] | null;
  publisherAccount?: IPublisherAccount | null;
}

export const defaultValue: Readonly<IKpAccount> = {
  isStaff: false,
};
