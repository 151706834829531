import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DigitalPublication from './digital-publication';
import DigitalPublicationDetail from './digital-publication-detail';
import DigitalPublicationUpdate from './digital-publication-update';
import DigitalPublicationDeleteDialog from './digital-publication-delete-dialog';

const DigitalPublicationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DigitalPublication />} />
    <Route path="new" element={<DigitalPublicationUpdate />} />
    <Route path=":id">
      <Route index element={<DigitalPublicationDetail />} />
      <Route path="edit" element={<DigitalPublicationUpdate />} />
      <Route path="delete" element={<DigitalPublicationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DigitalPublicationRoutes;
