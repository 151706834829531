import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getPaginationState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './publisher.reducer';

export const Publisher = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const publisherList = useAppSelector(state => state.publisher.entities);
  const loading = useAppSelector(state => state.publisher.loading);
  const totalItems = useAppSelector(state => state.publisher.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="publisher-heading" data-cy="PublisherHeading">
        <Translate contentKey="kpbackApp.publisher.home.title">Publishers</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="kpbackApp.publisher.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/publisher/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="kpbackApp.publisher.home.createLabel">Create new Publisher</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {publisherList && publisherList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="kpbackApp.publisher.id">Id</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('legacyId')}>
                  <Translate contentKey="kpbackApp.publisher.legacyId">Legacy Id</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('legacyId')} />
                </th>
                <th className="hand" onClick={sort('publisherType')}>
                  <Translate contentKey="kpbackApp.publisher.publisherType">Publisher Type</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('publisherType')} />
                </th>
                <th className="hand" onClick={sort('status')}>
                  <Translate contentKey="kpbackApp.publisher.status">Status</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('status')} />
                </th>
                <th className="hand" onClick={sort('personnelId')}>
                  <Translate contentKey="kpbackApp.publisher.personnelId">Personnel Id</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('personnelId')} />
                </th>
                <th className="hand" onClick={sort('businessName')}>
                  <Translate contentKey="kpbackApp.publisher.businessName">Business Name</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('businessName')} />
                </th>
                <th className="hand" onClick={sort('registryCode')}>
                  <Translate contentKey="kpbackApp.publisher.registryCode">Registry Code</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('registryCode')} />
                </th>
                <th className="hand" onClick={sort('name')}>
                  <Translate contentKey="kpbackApp.publisher.name">Name</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('name')} />
                </th>
                <th className="hand" onClick={sort('allowGrp')}>
                  <Translate contentKey="kpbackApp.publisher.allowGrp">Allow Grp</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('allowGrp')} />
                </th>
                <th className="hand" onClick={sort('phone')}>
                  <Translate contentKey="kpbackApp.publisher.phone">Phone</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('phone')} />
                </th>
                <th className="hand" onClick={sort('contact')}>
                  <Translate contentKey="kpbackApp.publisher.contact">Contact</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('contact')} />
                </th>
                <th className="hand" onClick={sort('webpage')}>
                  <Translate contentKey="kpbackApp.publisher.webpage">Webpage</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('webpage')} />
                </th>
                <th className="hand" onClick={sort('isLibrary')}>
                  <Translate contentKey="kpbackApp.publisher.isLibrary">Is Library</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('isLibrary')} />
                </th>
                <th className="hand" onClick={sort('lastSignon')}>
                  <Translate contentKey="kpbackApp.publisher.lastSignon">Last Signon</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('lastSignon')} />
                </th>
                <th className="hand" onClick={sort('ftpRoot')}>
                  <Translate contentKey="kpbackApp.publisher.ftpRoot">Ftp Root</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('ftpRoot')} />
                </th>
                <th className="hand" onClick={sort('hasPublished')}>
                  <Translate contentKey="kpbackApp.publisher.hasPublished">Has Published</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('hasPublished')} />
                </th>
                <th className="hand" onClick={sort('archivedAt')}>
                  <Translate contentKey="kpbackApp.publisher.archivedAt">Archived At</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('archivedAt')} />
                </th>
                <th className="hand" onClick={sort('allowMpiid')}>
                  <Translate contentKey="kpbackApp.publisher.allowMpiid">Allow Mpiid</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('allowMpiid')} />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="kpbackApp.publisher.createdAt">Created At</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('createdAt')} />
                </th>
                <th className="hand" onClick={sort('latestIsbn')}>
                  <Translate contentKey="kpbackApp.publisher.latestIsbn">Latest Isbn</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('latestIsbn')} />
                </th>
                <th className="hand" onClick={sort('maxPublicationCount')}>
                  <Translate contentKey="kpbackApp.publisher.maxPublicationCount">Max Publication Count</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('maxPublicationCount')} />
                </th>
                <th className="hand" onClick={sort('inheritedFromRegister')}>
                  <Translate contentKey="kpbackApp.publisher.inheritedFromRegister">Inherited From Register</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('inheritedFromRegister')} />
                </th>
                <th className="hand" onClick={sort('legalOwner')}>
                  <Translate contentKey="kpbackApp.publisher.legalOwner">Legal Owner</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('legalOwner')} />
                </th>
                <th className="hand" onClick={sort('createdBy')}>
                  <Translate contentKey="kpbackApp.publisher.createdBy">Created By</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('createdBy')} />
                </th>
                <th className="hand" onClick={sort('modifiedBy')}>
                  <Translate contentKey="kpbackApp.publisher.modifiedBy">Modified By</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('modifiedBy')} />
                </th>
                <th className="hand" onClick={sort('modifiedAt')}>
                  <Translate contentKey="kpbackApp.publisher.modifiedAt">Modified At</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('modifiedAt')} />
                </th>
                <th className="hand" onClick={sort('deletedBy')}>
                  <Translate contentKey="kpbackApp.publisher.deletedBy">Deleted By</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('deletedBy')} />
                </th>
                <th className="hand" onClick={sort('deletedAt')}>
                  <Translate contentKey="kpbackApp.publisher.deletedAt">Deleted At</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('deletedAt')} />
                </th>
                <th>
                  <Translate contentKey="kpbackApp.publisher.address">Address</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {publisherList.map((publisher, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/publisher/${publisher.id}`} color="link" size="sm">
                      {publisher.id}
                    </Button>
                  </td>
                  <td>{publisher.legacyId}</td>
                  <td>
                    <Translate contentKey={`kpbackApp.PublisherType.${publisher.publisherType}`} />
                  </td>
                  <td>
                    <Translate contentKey={`kpbackApp.PublisherStatus.${publisher.status}`} />
                  </td>
                  <td>{publisher.personnelId}</td>
                  <td>{publisher.businessName}</td>
                  <td>{publisher.registryCode}</td>
                  <td>{publisher.name}</td>
                  <td>{publisher.allowGrp ? 'true' : 'false'}</td>
                  <td>{publisher.phone}</td>
                  <td>{publisher.contact}</td>
                  <td>{publisher.webpage}</td>
                  <td>{publisher.isLibrary ? 'true' : 'false'}</td>
                  <td>{publisher.lastSignon ? <TextFormat type="date" value={publisher.lastSignon} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{publisher.ftpRoot}</td>
                  <td>{publisher.hasPublished ? 'true' : 'false'}</td>
                  <td>{publisher.archivedAt ? <TextFormat type="date" value={publisher.archivedAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{publisher.allowMpiid ? 'true' : 'false'}</td>
                  <td>{publisher.createdAt ? <TextFormat type="date" value={publisher.createdAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{publisher.latestIsbn}</td>
                  <td>{publisher.maxPublicationCount}</td>
                  <td>{publisher.inheritedFromRegister ? 'true' : 'false'}</td>
                  <td>{publisher.legalOwner}</td>
                  <td>{publisher.createdBy}</td>
                  <td>{publisher.modifiedBy}</td>
                  <td>{publisher.modifiedAt ? <TextFormat type="date" value={publisher.modifiedAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{publisher.deletedBy}</td>
                  <td>{publisher.deletedAt ? <TextFormat type="date" value={publisher.deletedAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{publisher.address ? <Link to={`/address/${publisher.address.id}`}>{publisher.address.id}</Link> : ''}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/publisher/${publisher.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/publisher/${publisher.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/publisher/${publisher.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="kpbackApp.publisher.home.notFound">No Publishers found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={publisherList && publisherList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Publisher;
