import dayjs from 'dayjs';

export interface ILicense {
  id?: string;
  legacyId?: number | null;
  name?: string;
  desc?: string | null;
  code?: string | null;
  orderNo?: number;
  isOther?: boolean;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<ILicense> = {
  isOther: false,
};
