import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './manufacturer.reducer';

export const ManufacturerDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const manufacturerEntity = useAppSelector(state => state.manufacturer.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="manufacturerDetailsHeading">
          <Translate contentKey="kpbackApp.manufacturer.detail.title">Manufacturer</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.manufacturer.id">Id</Translate>
            </span>
          </dt>
          <dd>{manufacturerEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.manufacturer.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{manufacturerEntity.legacyId}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="kpbackApp.manufacturer.name">Name</Translate>
            </span>
          </dt>
          <dd>{manufacturerEntity.name}</dd>
          <dt>
            <span id="phone">
              <Translate contentKey="kpbackApp.manufacturer.phone">Phone</Translate>
            </span>
          </dt>
          <dd>{manufacturerEntity.phone}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="kpbackApp.manufacturer.email">Email</Translate>
            </span>
          </dt>
          <dd>{manufacturerEntity.email}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.manufacturer.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{manufacturerEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.manufacturer.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {manufacturerEntity.createdAt ? <TextFormat value={manufacturerEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.manufacturer.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{manufacturerEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.manufacturer.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {manufacturerEntity.modifiedAt ? (
              <TextFormat value={manufacturerEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.manufacturer.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{manufacturerEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.manufacturer.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {manufacturerEntity.deletedAt ? <TextFormat value={manufacturerEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="kpbackApp.manufacturer.address">Address</Translate>
          </dt>
          <dd>{manufacturerEntity.address ? manufacturerEntity.address.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/manufacturer" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/manufacturer/${manufacturerEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ManufacturerDetail;
