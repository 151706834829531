import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './network-access.reducer';

export const NetworkAccessDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const networkAccessEntity = useAppSelector(state => state.networkAccess.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="networkAccessDetailsHeading">
          <Translate contentKey="kpbackApp.networkAccess.detail.title">NetworkAccess</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.networkAccess.id">Id</Translate>
            </span>
          </dt>
          <dd>{networkAccessEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.networkAccess.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{networkAccessEntity.legacyId}</dd>
          <dt>
            <span id="online">
              <Translate contentKey="kpbackApp.networkAccess.online">Online</Translate>
            </span>
          </dt>
          <dd>
            {networkAccessEntity.online ? <TextFormat value={networkAccessEntity.online} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="download">
              <Translate contentKey="kpbackApp.networkAccess.download">Download</Translate>
            </span>
          </dt>
          <dd>
            {networkAccessEntity.download ? <TextFormat value={networkAccessEntity.download} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.networkAccess.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{networkAccessEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.networkAccess.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {networkAccessEntity.createdAt ? (
              <TextFormat value={networkAccessEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.networkAccess.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{networkAccessEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.networkAccess.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {networkAccessEntity.modifiedAt ? (
              <TextFormat value={networkAccessEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.networkAccess.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{networkAccessEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.networkAccess.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {networkAccessEntity.deletedAt ? (
              <TextFormat value={networkAccessEntity.deletedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="kpbackApp.networkAccess.digitalPublicationRow">Digital Publication Row</Translate>
          </dt>
          <dd>{networkAccessEntity.digitalPublicationRow ? networkAccessEntity.digitalPublicationRow.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.networkAccess.network">Network</Translate>
          </dt>
          <dd>{networkAccessEntity.network ? networkAccessEntity.network.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.networkAccess.publicationRelease">Publication Release</Translate>
          </dt>
          <dd>{networkAccessEntity.publicationRelease ? networkAccessEntity.publicationRelease.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/network-access" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/network-access/${networkAccessEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default NetworkAccessDetail;
