import dayjs from 'dayjs';

export interface INetwork {
  id?: string;
  legacyId?: number | null;
  code?: string | null;
  orderNumber?: number;
  name?: string;
  canDownload?: boolean;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<INetwork> = {
  canDownload: false,
};
