import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './digital-publication.reducer';

export const DigitalPublicationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const digitalPublicationEntity = useAppSelector(state => state.digitalPublication.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="digitalPublicationDetailsHeading">
          <Translate contentKey="kpbackApp.digitalPublication.detail.title">DigitalPublication</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.digitalPublication.id">Id</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.digitalPublication.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationEntity.legacyId}</dd>
          <dt>
            <span id="publisherBusinessName">
              <Translate contentKey="kpbackApp.digitalPublication.publisherBusinessName">Publisher Business Name</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationEntity.publisherBusinessName}</dd>
          <dt>
            <span id="publisherName">
              <Translate contentKey="kpbackApp.digitalPublication.publisherName">Publisher Name</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationEntity.publisherName}</dd>
          <dt>
            <span id="phone">
              <Translate contentKey="kpbackApp.digitalPublication.phone">Phone</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationEntity.phone}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="kpbackApp.digitalPublication.email">Email</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationEntity.email}</dd>
          <dt>
            <span id="webpage">
              <Translate contentKey="kpbackApp.digitalPublication.webpage">Webpage</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationEntity.webpage}</dd>
          <dt>
            <span id="contact">
              <Translate contentKey="kpbackApp.digitalPublication.contact">Contact</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationEntity.contact}</dd>
          <dt>
            <span id="submittedBy">
              <Translate contentKey="kpbackApp.digitalPublication.submittedBy">Submitted By</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationEntity.submittedBy}</dd>
          <dt>
            <span id="submittedAt">
              <Translate contentKey="kpbackApp.digitalPublication.submittedAt">Submitted At</Translate>
            </span>
          </dt>
          <dd>
            {digitalPublicationEntity.submittedAt ? (
              <TextFormat value={digitalPublicationEntity.submittedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="confirmedBy">
              <Translate contentKey="kpbackApp.digitalPublication.confirmedBy">Confirmed By</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationEntity.confirmedBy}</dd>
          <dt>
            <span id="confirmedAt">
              <Translate contentKey="kpbackApp.digitalPublication.confirmedAt">Confirmed At</Translate>
            </span>
          </dt>
          <dd>
            {digitalPublicationEntity.confirmedAt ? (
              <TextFormat value={digitalPublicationEntity.confirmedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.digitalPublication.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.digitalPublication.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {digitalPublicationEntity.createdAt ? (
              <TextFormat value={digitalPublicationEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.digitalPublication.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.digitalPublication.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {digitalPublicationEntity.modifiedAt ? (
              <TextFormat value={digitalPublicationEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.digitalPublication.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.digitalPublication.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {digitalPublicationEntity.deletedAt ? (
              <TextFormat value={digitalPublicationEntity.deletedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="kpbackApp.digitalPublication.status">Status</Translate>
          </dt>
          <dd>{digitalPublicationEntity.status ? digitalPublicationEntity.status.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.digitalPublication.address">Address</Translate>
          </dt>
          <dd>{digitalPublicationEntity.address ? digitalPublicationEntity.address.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.digitalPublication.publisher">Publisher</Translate>
          </dt>
          <dd>{digitalPublicationEntity.publisher ? digitalPublicationEntity.publisher.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/digital-publication" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/digital-publication/${digitalPublicationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DigitalPublicationDetail;
