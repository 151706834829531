import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getMediums } from 'app/entities/medium/medium.reducer';
import { IMedium } from 'app/shared/model/medium.model';
import { getEntity, updateEntity, createEntity, reset } from './medium.reducer';

export const MediumUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const mediums = useAppSelector(state => state.medium.entities);
  const mediumEntity = useAppSelector(state => state.medium.entity);
  const loading = useAppSelector(state => state.medium.loading);
  const updating = useAppSelector(state => state.medium.updating);
  const updateSuccess = useAppSelector(state => state.medium.updateSuccess);

  const handleClose = () => {
    navigate('/medium' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getMediums({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.legacyId !== undefined && typeof values.legacyId !== 'number') {
      values.legacyId = Number(values.legacyId);
    }
    if (values.orderNo !== undefined && typeof values.orderNo !== 'number') {
      values.orderNo = Number(values.orderNo);
    }
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.modifiedAt = convertDateTimeToServer(values.modifiedAt);
    values.deletedAt = convertDateTimeToServer(values.deletedAt);

    const entity = {
      ...mediumEntity,
      ...values,
      parent: mediums.find(it => it.id.toString() === values.parent.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          modifiedAt: displayDefaultDateTime(),
          deletedAt: displayDefaultDateTime(),
        }
      : {
          ...mediumEntity,
          createdAt: convertDateTimeFromServer(mediumEntity.createdAt),
          modifiedAt: convertDateTimeFromServer(mediumEntity.modifiedAt),
          deletedAt: convertDateTimeFromServer(mediumEntity.deletedAt),
          parent: mediumEntity?.parent?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="kpbackApp.medium.home.createOrEditLabel" data-cy="MediumCreateUpdateHeading">
            <Translate contentKey="kpbackApp.medium.home.createOrEditLabel">Create or edit a Medium</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="medium-id"
                  label={translate('kpbackApp.medium.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('kpbackApp.medium.legacyId')}
                id="medium-legacyId"
                name="legacyId"
                data-cy="legacyId"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.medium.nameEt')}
                id="medium-nameEt"
                name="nameEt"
                data-cy="nameEt"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.medium.orderNo')}
                id="medium-orderNo"
                name="orderNo"
                data-cy="orderNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.medium.marcBindingName')}
                id="medium-marcBindingName"
                name="marcBindingName"
                data-cy="marcBindingName"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.medium.hasBindings')}
                id="medium-hasBindings"
                name="hasBindings"
                data-cy="hasBindings"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.medium.hasPrintRun')}
                id="medium-hasPrintRun"
                name="hasPrintRun"
                data-cy="hasPrintRun"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.medium.hasHeight')}
                id="medium-hasHeight"
                name="hasHeight"
                data-cy="hasHeight"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.medium.hasDrm')}
                id="medium-hasDrm"
                name="hasDrm"
                data-cy="hasDrm"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.medium.hasWebpage')}
                id="medium-hasWebpage"
                name="hasWebpage"
                data-cy="hasWebpage"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.medium.hasContentType')}
                id="medium-hasContentType"
                name="hasContentType"
                data-cy="hasContentType"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.medium.isOther')}
                id="medium-isOther"
                name="isOther"
                data-cy="isOther"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.medium.asAppendix')}
                id="medium-asAppendix"
                name="asAppendix"
                data-cy="asAppendix"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.medium.asRelease')}
                id="medium-asRelease"
                name="asRelease"
                data-cy="asRelease"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.medium.isPhysical')}
                id="medium-isPhysical"
                name="isPhysical"
                data-cy="isPhysical"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.medium.createdBy')}
                id="medium-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.medium.createdAt')}
                id="medium-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.medium.modifiedBy')}
                id="medium-modifiedBy"
                name="modifiedBy"
                data-cy="modifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.medium.modifiedAt')}
                id="medium-modifiedAt"
                name="modifiedAt"
                data-cy="modifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.medium.deletedBy')}
                id="medium-deletedBy"
                name="deletedBy"
                data-cy="deletedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.medium.deletedAt')}
                id="medium-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField id="medium-parent" name="parent" data-cy="parent" label={translate('kpbackApp.medium.parent')} type="select">
                <option value="" key="0" />
                {mediums
                  ? mediums.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/medium" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MediumUpdate;
