import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IPublisher } from 'app/shared/model/publisher.model';
import { getEntities as getPublishers } from 'app/entities/publisher/publisher.reducer';
import { IPendingFiles } from 'app/shared/model/pending-files.model';
import { getEntity, updateEntity, createEntity, reset } from './pending-files.reducer';

export const PendingFilesUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const publishers = useAppSelector(state => state.publisher.entities);
  const pendingFilesEntity = useAppSelector(state => state.pendingFiles.entity);
  const loading = useAppSelector(state => state.pendingFiles.loading);
  const updating = useAppSelector(state => state.pendingFiles.updating);
  const updateSuccess = useAppSelector(state => state.pendingFiles.updateSuccess);

  const handleClose = () => {
    navigate('/pending-files' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getPublishers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.legacyId !== undefined && typeof values.legacyId !== 'number') {
      values.legacyId = Number(values.legacyId);
    }
    values.notifiedAt = convertDateTimeToServer(values.notifiedAt);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.modifiedAt = convertDateTimeToServer(values.modifiedAt);
    values.deletedAt = convertDateTimeToServer(values.deletedAt);

    const entity = {
      ...pendingFilesEntity,
      ...values,
      publisher: publishers.find(it => it.id.toString() === values.publisher.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          notifiedAt: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          modifiedAt: displayDefaultDateTime(),
          deletedAt: displayDefaultDateTime(),
        }
      : {
          ...pendingFilesEntity,
          notifiedAt: convertDateTimeFromServer(pendingFilesEntity.notifiedAt),
          createdAt: convertDateTimeFromServer(pendingFilesEntity.createdAt),
          modifiedAt: convertDateTimeFromServer(pendingFilesEntity.modifiedAt),
          deletedAt: convertDateTimeFromServer(pendingFilesEntity.deletedAt),
          publisher: pendingFilesEntity?.publisher?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="kpbackApp.pendingFiles.home.createOrEditLabel" data-cy="PendingFilesCreateUpdateHeading">
            <Translate contentKey="kpbackApp.pendingFiles.home.createOrEditLabel">Create or edit a PendingFiles</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="pending-files-id"
                  label={translate('kpbackApp.pendingFiles.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('kpbackApp.pendingFiles.legacyId')}
                id="pending-files-legacyId"
                name="legacyId"
                data-cy="legacyId"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.pendingFiles.path')}
                id="pending-files-path"
                name="path"
                data-cy="path"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 65535, message: translate('entity.validation.maxlength', { max: 65535 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.pendingFiles.isScanned')}
                id="pending-files-isScanned"
                name="isScanned"
                data-cy="isScanned"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.pendingFiles.notifiedAt')}
                id="pending-files-notifiedAt"
                name="notifiedAt"
                data-cy="notifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.pendingFiles.createdBy')}
                id="pending-files-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.pendingFiles.createdAt')}
                id="pending-files-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.pendingFiles.modifiedBy')}
                id="pending-files-modifiedBy"
                name="modifiedBy"
                data-cy="modifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.pendingFiles.modifiedAt')}
                id="pending-files-modifiedAt"
                name="modifiedAt"
                data-cy="modifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.pendingFiles.deletedBy')}
                id="pending-files-deletedBy"
                name="deletedBy"
                data-cy="deletedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.pendingFiles.deletedAt')}
                id="pending-files-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="pending-files-publisher"
                name="publisher"
                data-cy="publisher"
                label={translate('kpbackApp.pendingFiles.publisher')}
                type="select"
              >
                <option value="" key="0" />
                {publishers
                  ? publishers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/pending-files" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PendingFilesUpdate;
