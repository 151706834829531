import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Classifier from './classifier';
import ClassifierDetail from './classifier-detail';
import ClassifierUpdate from './classifier-update';
import ClassifierDeleteDialog from './classifier-delete-dialog';

const ClassifierRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Classifier />} />
    <Route path="new" element={<ClassifierUpdate />} />
    <Route path=":id">
      <Route index element={<ClassifierDetail />} />
      <Route path="edit" element={<ClassifierUpdate />} />
      <Route path="delete" element={<ClassifierDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ClassifierRoutes;
