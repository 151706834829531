import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/classifier">
        <Translate contentKey="global.menu.entities.classifier" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/classifier-value">
        <Translate contentKey="global.menu.entities.classifierValue" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/person">
        <Translate contentKey="global.menu.entities.person" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/kp-account">
        <Translate contentKey="global.menu.entities.kpAccount" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/publisher">
        <Translate contentKey="global.menu.entities.publisher" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/publisher-relationship">
        <Translate contentKey="global.menu.entities.publisherRelationship" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/address">
        <Translate contentKey="global.menu.entities.address" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/publisher-account">
        <Translate contentKey="global.menu.entities.publisherAccount" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/isn-block">
        <Translate contentKey="global.menu.entities.isnBlock" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/isn-block-owner">
        <Translate contentKey="global.menu.entities.isnBlockOwner" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/isn-block-range">
        <Translate contentKey="global.menu.entities.isnBlockRange" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/isn">
        <Translate contentKey="global.menu.entities.isn" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/publication">
        <Translate contentKey="global.menu.entities.publication" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/publication-release">
        <Translate contentKey="global.menu.entities.publicationRelease" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/publication-release-person">
        <Translate contentKey="global.menu.entities.publicationReleasePerson" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/publication-release-person-role">
        <Translate contentKey="global.menu.entities.publicationReleasePersonRole" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/manufacturer">
        <Translate contentKey="global.menu.entities.manufacturer" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/network-access">
        <Translate contentKey="global.menu.entities.networkAccess" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/version">
        <Translate contentKey="global.menu.entities.version" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/license">
        <Translate contentKey="global.menu.entities.license" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/fileset">
        <Translate contentKey="global.menu.entities.fileset" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/fileset-item">
        <Translate contentKey="global.menu.entities.filesetItem" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/rejection">
        <Translate contentKey="global.menu.entities.rejection" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/rejection-reason">
        <Translate contentKey="global.menu.entities.rejectionReason" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/content-type">
        <Translate contentKey="global.menu.entities.contentType" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/format">
        <Translate contentKey="global.menu.entities.format" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/medium">
        <Translate contentKey="global.menu.entities.medium" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/language">
        <Translate contentKey="global.menu.entities.language" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/isn-class">
        <Translate contentKey="global.menu.entities.isnClass" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/publish-period">
        <Translate contentKey="global.menu.entities.publishPeriod" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/action-log-type">
        <Translate contentKey="global.menu.entities.actionLogType" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/configuration-setting">
        <Translate contentKey="global.menu.entities.configurationSetting" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/action-log">
        <Translate contentKey="global.menu.entities.actionLog" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/public-notification">
        <Translate contentKey="global.menu.entities.publicNotification" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/legal-owner">
        <Translate contentKey="global.menu.entities.legalOwner" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/network">
        <Translate contentKey="global.menu.entities.network" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/distributor">
        <Translate contentKey="global.menu.entities.distributor" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/subject-action-type">
        <Translate contentKey="global.menu.entities.subjectActionType" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/role">
        <Translate contentKey="global.menu.entities.role" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/access-right">
        <Translate contentKey="global.menu.entities.accessRight" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/keyword">
        <Translate contentKey="global.menu.entities.keyword" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/digital-publication">
        <Translate contentKey="global.menu.entities.digitalPublication" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/digital-publication-row">
        <Translate contentKey="global.menu.entities.digitalPublicationRow" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/translation">
        <Translate contentKey="global.menu.entities.translation" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/note">
        <Translate contentKey="global.menu.entities.note" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/pending-files">
        <Translate contentKey="global.menu.entities.pendingFiles" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/publisher-email">
        <Translate contentKey="global.menu.entities.publisherEmail" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
