import dayjs from 'dayjs';
import { IClassifier } from 'app/shared/model/classifier.model';
import { IPublication } from 'app/shared/model/publication.model';

export interface IKeyword {
  id?: string;
  legacyId?: number | null;
  name?: string;
  code?: string | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  targetCode?: IClassifier | null;
  publications?: IPublication[] | null;
}

export const defaultValue: Readonly<IKeyword> = {};
