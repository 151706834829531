import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PublicationReleasePerson from './publication-release-person';
import PublicationReleasePersonDetail from './publication-release-person-detail';
import PublicationReleasePersonUpdate from './publication-release-person-update';
import PublicationReleasePersonDeleteDialog from './publication-release-person-delete-dialog';

const PublicationReleasePersonRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PublicationReleasePerson />} />
    <Route path="new" element={<PublicationReleasePersonUpdate />} />
    <Route path=":id">
      <Route index element={<PublicationReleasePersonDetail />} />
      <Route path="edit" element={<PublicationReleasePersonUpdate />} />
      <Route path="delete" element={<PublicationReleasePersonDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PublicationReleasePersonRoutes;
