import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IPublisher } from 'app/shared/model/publisher.model';
import { getEntities as getPublishers } from 'app/entities/publisher/publisher.reducer';
import { IClassifier } from 'app/shared/model/classifier.model';
import { getEntities as getClassifiers } from 'app/entities/classifier/classifier.reducer';
import { IPublisherRelationship } from 'app/shared/model/publisher-relationship.model';
import { getEntity, updateEntity, createEntity, reset } from './publisher-relationship.reducer';

export const PublisherRelationshipUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const publishers = useAppSelector(state => state.publisher.entities);
  const classifiers = useAppSelector(state => state.classifier.entities);
  const publisherRelationshipEntity = useAppSelector(state => state.publisherRelationship.entity);
  const loading = useAppSelector(state => state.publisherRelationship.loading);
  const updating = useAppSelector(state => state.publisherRelationship.updating);
  const updateSuccess = useAppSelector(state => state.publisherRelationship.updateSuccess);

  const handleClose = () => {
    navigate('/publisher-relationship' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getPublishers({}));
    dispatch(getClassifiers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    values.validFrom = convertDateTimeToServer(values.validFrom);
    values.validTo = convertDateTimeToServer(values.validTo);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.modifiedAt = convertDateTimeToServer(values.modifiedAt);
    values.deletedAt = convertDateTimeToServer(values.deletedAt);

    const entity = {
      ...publisherRelationshipEntity,
      ...values,
      parentPublisher: publishers.find(it => it.id.toString() === values.parentPublisher.toString()),
      childPublisher: publishers.find(it => it.id.toString() === values.childPublisher.toString()),
      relationType: classifiers.find(it => it.id.toString() === values.relationType.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          validFrom: displayDefaultDateTime(),
          validTo: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          modifiedAt: displayDefaultDateTime(),
          deletedAt: displayDefaultDateTime(),
        }
      : {
          ...publisherRelationshipEntity,
          validFrom: convertDateTimeFromServer(publisherRelationshipEntity.validFrom),
          validTo: convertDateTimeFromServer(publisherRelationshipEntity.validTo),
          createdAt: convertDateTimeFromServer(publisherRelationshipEntity.createdAt),
          modifiedAt: convertDateTimeFromServer(publisherRelationshipEntity.modifiedAt),
          deletedAt: convertDateTimeFromServer(publisherRelationshipEntity.deletedAt),
          parentPublisher: publisherRelationshipEntity?.parentPublisher?.id,
          childPublisher: publisherRelationshipEntity?.childPublisher?.id,
          relationType: publisherRelationshipEntity?.relationType?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="kpbackApp.publisherRelationship.home.createOrEditLabel" data-cy="PublisherRelationshipCreateUpdateHeading">
            <Translate contentKey="kpbackApp.publisherRelationship.home.createOrEditLabel">
              Create or edit a PublisherRelationship
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="publisher-relationship-id"
                  label={translate('kpbackApp.publisherRelationship.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('kpbackApp.publisherRelationship.validFrom')}
                id="publisher-relationship-validFrom"
                name="validFrom"
                data-cy="validFrom"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publisherRelationship.validTo')}
                id="publisher-relationship-validTo"
                name="validTo"
                data-cy="validTo"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publisherRelationship.createdBy')}
                id="publisher-relationship-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publisherRelationship.createdAt')}
                id="publisher-relationship-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publisherRelationship.modifiedBy')}
                id="publisher-relationship-modifiedBy"
                name="modifiedBy"
                data-cy="modifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publisherRelationship.modifiedAt')}
                id="publisher-relationship-modifiedAt"
                name="modifiedAt"
                data-cy="modifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.publisherRelationship.deletedBy')}
                id="publisher-relationship-deletedBy"
                name="deletedBy"
                data-cy="deletedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publisherRelationship.deletedAt')}
                id="publisher-relationship-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="publisher-relationship-parentPublisher"
                name="parentPublisher"
                data-cy="parentPublisher"
                label={translate('kpbackApp.publisherRelationship.parentPublisher')}
                type="select"
              >
                <option value="" key="0" />
                {publishers
                  ? publishers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publisher-relationship-childPublisher"
                name="childPublisher"
                data-cy="childPublisher"
                label={translate('kpbackApp.publisherRelationship.childPublisher')}
                type="select"
              >
                <option value="" key="0" />
                {publishers
                  ? publishers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publisher-relationship-relationType"
                name="relationType"
                data-cy="relationType"
                label={translate('kpbackApp.publisherRelationship.relationType')}
                type="select"
              >
                <option value="" key="0" />
                {classifiers
                  ? classifiers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/publisher-relationship" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PublisherRelationshipUpdate;
