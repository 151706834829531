import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './legal-owner.reducer';

export const LegalOwnerDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const legalOwnerEntity = useAppSelector(state => state.legalOwner.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="legalOwnerDetailsHeading">
          <Translate contentKey="kpbackApp.legalOwner.detail.title">LegalOwner</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.legalOwner.id">Id</Translate>
            </span>
          </dt>
          <dd>{legalOwnerEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.legalOwner.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{legalOwnerEntity.legacyId}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="kpbackApp.legalOwner.name">Name</Translate>
            </span>
          </dt>
          <dd>{legalOwnerEntity.name}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.legalOwner.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{legalOwnerEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.legalOwner.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {legalOwnerEntity.createdAt ? <TextFormat value={legalOwnerEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.legalOwner.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{legalOwnerEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.legalOwner.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {legalOwnerEntity.modifiedAt ? <TextFormat value={legalOwnerEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.legalOwner.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{legalOwnerEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.legalOwner.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {legalOwnerEntity.deletedAt ? <TextFormat value={legalOwnerEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="kpbackApp.legalOwner.publication">Publication</Translate>
          </dt>
          <dd>{legalOwnerEntity.publication ? legalOwnerEntity.publication.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.legalOwner.digitalPublication">Digital Publication</Translate>
          </dt>
          <dd>{legalOwnerEntity.digitalPublication ? legalOwnerEntity.digitalPublication.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/legal-owner" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/legal-owner/${legalOwnerEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default LegalOwnerDetail;
