import dayjs from 'dayjs';
import { IClassifier } from 'app/shared/model/classifier.model';
import { IAddress } from 'app/shared/model/address.model';
import { IPublisher } from 'app/shared/model/publisher.model';

export interface IDigitalPublication {
  id?: string;
  legacyId?: number | null;
  publisherBusinessName?: string | null;
  publisherName?: string | null;
  phone?: string | null;
  email?: string | null;
  webpage?: string | null;
  contact?: string | null;
  submittedBy?: string;
  submittedAt?: dayjs.Dayjs;
  confirmedBy?: string | null;
  confirmedAt?: dayjs.Dayjs | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  status?: IClassifier | null;
  address?: IAddress | null;
  publisher?: IPublisher | null;
}

export const defaultValue: Readonly<IDigitalPublication> = {};
