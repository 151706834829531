import dayjs from 'dayjs';

export interface IMedium {
  id?: string;
  legacyId?: number | null;
  nameEt?: string | null;
  orderNo?: number;
  marcBindingName?: string | null;
  hasBindings?: boolean;
  hasPrintRun?: boolean;
  hasHeight?: boolean;
  hasDrm?: boolean;
  hasWebpage?: boolean;
  hasContentType?: boolean;
  isOther?: boolean;
  asAppendix?: boolean;
  asRelease?: boolean;
  isPhysical?: boolean;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  parent?: IMedium | null;
}

export const defaultValue: Readonly<IMedium> = {
  hasBindings: false,
  hasPrintRun: false,
  hasHeight: false,
  hasDrm: false,
  hasWebpage: false,
  hasContentType: false,
  isOther: false,
  asAppendix: false,
  asRelease: false,
  isPhysical: false,
};
