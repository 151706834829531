import dayjs from 'dayjs';
import { IClassifier } from 'app/shared/model/classifier.model';
import { IISN } from 'app/shared/model/isn.model';

export interface IISNBlock {
  id?: string;
  legacyId?: number | null;
  prefix?: string;
  isnSize?: number;
  isShared?: boolean;
  isMuted?: boolean | null;
  lastNumber?: string;
  isDigitalCopy?: boolean | null;
  isActive?: boolean | null;
  isnBlockRange?: number | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  isnType?: IClassifier | null;
  isns?: IISN[] | null;
}

export const defaultValue: Readonly<IISNBlock> = {
  isShared: false,
  isMuted: false,
  isDigitalCopy: false,
  isActive: false,
};
