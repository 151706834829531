import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IClassifier } from 'app/shared/model/classifier.model';
import { getEntities as getClassifiers } from 'app/entities/classifier/classifier.reducer';
import { IISNBlock } from 'app/shared/model/isn-block.model';
import { getEntity, updateEntity, createEntity, reset } from './isn-block.reducer';

export const ISNBlockUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const classifiers = useAppSelector(state => state.classifier.entities);
  const iSNBlockEntity = useAppSelector(state => state.iSNBlock.entity);
  const loading = useAppSelector(state => state.iSNBlock.loading);
  const updating = useAppSelector(state => state.iSNBlock.updating);
  const updateSuccess = useAppSelector(state => state.iSNBlock.updateSuccess);

  const handleClose = () => {
    navigate('/isn-block' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getClassifiers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.legacyId !== undefined && typeof values.legacyId !== 'number') {
      values.legacyId = Number(values.legacyId);
    }
    if (values.isnSize !== undefined && typeof values.isnSize !== 'number') {
      values.isnSize = Number(values.isnSize);
    }
    if (values.isnBlockRange !== undefined && typeof values.isnBlockRange !== 'number') {
      values.isnBlockRange = Number(values.isnBlockRange);
    }
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.modifiedAt = convertDateTimeToServer(values.modifiedAt);
    values.deletedAt = convertDateTimeToServer(values.deletedAt);

    const entity = {
      ...iSNBlockEntity,
      ...values,
      isnType: classifiers.find(it => it.id.toString() === values.isnType.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          modifiedAt: displayDefaultDateTime(),
          deletedAt: displayDefaultDateTime(),
        }
      : {
          ...iSNBlockEntity,
          createdAt: convertDateTimeFromServer(iSNBlockEntity.createdAt),
          modifiedAt: convertDateTimeFromServer(iSNBlockEntity.modifiedAt),
          deletedAt: convertDateTimeFromServer(iSNBlockEntity.deletedAt),
          isnType: iSNBlockEntity?.isnType?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="kpbackApp.iSNBlock.home.createOrEditLabel" data-cy="ISNBlockCreateUpdateHeading">
            <Translate contentKey="kpbackApp.iSNBlock.home.createOrEditLabel">Create or edit a ISNBlock</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="isn-block-id"
                  label={translate('kpbackApp.iSNBlock.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('kpbackApp.iSNBlock.legacyId')}
                id="isn-block-legacyId"
                name="legacyId"
                data-cy="legacyId"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.iSNBlock.prefix')}
                id="isn-block-prefix"
                name="prefix"
                data-cy="prefix"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 11, message: translate('entity.validation.maxlength', { max: 11 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.iSNBlock.isnSize')}
                id="isn-block-isnSize"
                name="isnSize"
                data-cy="isnSize"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.iSNBlock.isShared')}
                id="isn-block-isShared"
                name="isShared"
                data-cy="isShared"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.iSNBlock.isMuted')}
                id="isn-block-isMuted"
                name="isMuted"
                data-cy="isMuted"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.iSNBlock.lastNumber')}
                id="isn-block-lastNumber"
                name="lastNumber"
                data-cy="lastNumber"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 15, message: translate('entity.validation.maxlength', { max: 15 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.iSNBlock.isDigitalCopy')}
                id="isn-block-isDigitalCopy"
                name="isDigitalCopy"
                data-cy="isDigitalCopy"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.iSNBlock.isActive')}
                id="isn-block-isActive"
                name="isActive"
                data-cy="isActive"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.iSNBlock.isnBlockRange')}
                id="isn-block-isnBlockRange"
                name="isnBlockRange"
                data-cy="isnBlockRange"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.iSNBlock.createdBy')}
                id="isn-block-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.iSNBlock.createdAt')}
                id="isn-block-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.iSNBlock.modifiedBy')}
                id="isn-block-modifiedBy"
                name="modifiedBy"
                data-cy="modifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.iSNBlock.modifiedAt')}
                id="isn-block-modifiedAt"
                name="modifiedAt"
                data-cy="modifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.iSNBlock.deletedBy')}
                id="isn-block-deletedBy"
                name="deletedBy"
                data-cy="deletedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.iSNBlock.deletedAt')}
                id="isn-block-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="isn-block-isnType"
                name="isnType"
                data-cy="isnType"
                label={translate('kpbackApp.iSNBlock.isnType')}
                type="select"
              >
                <option value="" key="0" />
                {classifiers
                  ? classifiers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/isn-block" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ISNBlockUpdate;
