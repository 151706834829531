import dayjs from 'dayjs';

export interface IPublishPeriod {
  id?: string;
  legacyId?: number | null;
  name?: string | null;
  isOther?: boolean;
  isIrregular?: boolean;
  isDaily?: boolean;
  code?: string | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<IPublishPeriod> = {
  isOther: false,
  isIrregular: false,
  isDaily: false,
};
