import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ISN from './isn';
import ISNDetail from './isn-detail';
import ISNUpdate from './isn-update';
import ISNDeleteDialog from './isn-delete-dialog';

const ISNRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ISN />} />
    <Route path="new" element={<ISNUpdate />} />
    <Route path=":id">
      <Route index element={<ISNDetail />} />
      <Route path="edit" element={<ISNUpdate />} />
      <Route path="delete" element={<ISNDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ISNRoutes;
