import dayjs from 'dayjs';

export interface IAddress {
  id?: string;
  legacyId?: number | null;
  adsOid?: string | null;
  street?: string;
  zipCode?: string | null;
  city?: string;
  county?: string | null;
  country?: string;
  addressName?: string | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<IAddress> = {};
