import dayjs from 'dayjs';
import { IPublicationRelease } from 'app/shared/model/publication-release.model';
import { IDigitalPublication } from 'app/shared/model/digital-publication.model';

export interface ILegalOwner {
  id?: string;
  legacyId?: number | null;
  name?: string | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  publication?: IPublicationRelease | null;
  digitalPublication?: IDigitalPublication | null;
}

export const defaultValue: Readonly<ILegalOwner> = {};
