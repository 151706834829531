import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PublishPeriod from './publish-period';
import PublishPeriodDetail from './publish-period-detail';
import PublishPeriodUpdate from './publish-period-update';
import PublishPeriodDeleteDialog from './publish-period-delete-dialog';

const PublishPeriodRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PublishPeriod />} />
    <Route path="new" element={<PublishPeriodUpdate />} />
    <Route path=":id">
      <Route index element={<PublishPeriodDetail />} />
      <Route path="edit" element={<PublishPeriodUpdate />} />
      <Route path="delete" element={<PublishPeriodDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PublishPeriodRoutes;
