import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './publisher-account.reducer';

export const PublisherAccountDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const publisherAccountEntity = useAppSelector(state => state.publisherAccount.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="publisherAccountDetailsHeading">
          <Translate contentKey="kpbackApp.publisherAccount.detail.title">PublisherAccount</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.publisherAccount.id">Id</Translate>
            </span>
          </dt>
          <dd>{publisherAccountEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.publisherAccount.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{publisherAccountEntity.legacyId}</dd>
          <dt>
            <span id="phone">
              <Translate contentKey="kpbackApp.publisherAccount.phone">Phone</Translate>
            </span>
          </dt>
          <dd>{publisherAccountEntity.phone}</dd>
          <dt>
            <span id="jobPosition">
              <Translate contentKey="kpbackApp.publisherAccount.jobPosition">Job Position</Translate>
            </span>
          </dt>
          <dd>{publisherAccountEntity.jobPosition}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.publisherAccount.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{publisherAccountEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.publisherAccount.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {publisherAccountEntity.createdAt ? (
              <TextFormat value={publisherAccountEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.publisherAccount.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{publisherAccountEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.publisherAccount.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {publisherAccountEntity.modifiedAt ? (
              <TextFormat value={publisherAccountEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.publisherAccount.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{publisherAccountEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.publisherAccount.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {publisherAccountEntity.deletedAt ? (
              <TextFormat value={publisherAccountEntity.deletedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="kpbackApp.publisherAccount.account">Account</Translate>
          </dt>
          <dd>{publisherAccountEntity.account ? publisherAccountEntity.account.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publisherAccount.publisher">Publisher</Translate>
          </dt>
          <dd>{publisherAccountEntity.publisher ? publisherAccountEntity.publisher.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/publisher-account" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/publisher-account/${publisherAccountEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PublisherAccountDetail;
