import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IManufacturer } from 'app/shared/model/manufacturer.model';
import { getEntities as getManufacturers } from 'app/entities/manufacturer/manufacturer.reducer';
import { IISN } from 'app/shared/model/isn.model';
import { getEntities as getISns } from 'app/entities/isn/isn.reducer';
import { IPublication } from 'app/shared/model/publication.model';
import { getEntities as getPublications } from 'app/entities/publication/publication.reducer';
import { IVersion } from 'app/shared/model/version.model';
import { getEntities as getVersions } from 'app/entities/version/version.reducer';
import { IMedium } from 'app/shared/model/medium.model';
import { getEntities as getMediums } from 'app/entities/medium/medium.reducer';
import { IContentType } from 'app/shared/model/content-type.model';
import { getEntities as getContentTypes } from 'app/entities/content-type/content-type.reducer';
import { IFormat } from 'app/shared/model/format.model';
import { getEntities as getFormats } from 'app/entities/format/format.reducer';
import { ILanguage } from 'app/shared/model/language.model';
import { getEntities as getLanguages } from 'app/entities/language/language.reducer';
import { ILicense } from 'app/shared/model/license.model';
import { getEntities as getLicenses } from 'app/entities/license/license.reducer';
import { getEntities as getPublicationReleases } from 'app/entities/publication-release/publication-release.reducer';
import { IPublicationRelease } from 'app/shared/model/publication-release.model';
import { getEntity, updateEntity, createEntity, reset } from './publication-release.reducer';

export const PublicationReleaseUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const manufacturers = useAppSelector(state => state.manufacturer.entities);
  const iSNS = useAppSelector(state => state.iSN.entities);
  const publications = useAppSelector(state => state.publication.entities);
  const versions = useAppSelector(state => state.version.entities);
  const mediums = useAppSelector(state => state.medium.entities);
  const contentTypes = useAppSelector(state => state.contentType.entities);
  const formats = useAppSelector(state => state.format.entities);
  const languages = useAppSelector(state => state.language.entities);
  const licenses = useAppSelector(state => state.license.entities);
  const publicationReleases = useAppSelector(state => state.publicationRelease.entities);
  const publicationReleaseEntity = useAppSelector(state => state.publicationRelease.entity);
  const loading = useAppSelector(state => state.publicationRelease.loading);
  const updating = useAppSelector(state => state.publicationRelease.updating);
  const updateSuccess = useAppSelector(state => state.publicationRelease.updateSuccess);

  const handleClose = () => {
    navigate('/publication-release' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getManufacturers({}));
    dispatch(getISns({}));
    dispatch(getPublications({}));
    dispatch(getVersions({}));
    dispatch(getMediums({}));
    dispatch(getContentTypes({}));
    dispatch(getFormats({}));
    dispatch(getLanguages({}));
    dispatch(getLicenses({}));
    dispatch(getPublicationReleases({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.legacyId !== undefined && typeof values.legacyId !== 'number') {
      values.legacyId = Number(values.legacyId);
    }
    if (values.height !== undefined && typeof values.height !== 'number') {
      values.height = Number(values.height);
    }
    if (values.width !== undefined && typeof values.width !== 'number') {
      values.width = Number(values.width);
    }
    if (values.orderNo !== undefined && typeof values.orderNo !== 'number') {
      values.orderNo = Number(values.orderNo);
    }
    if (values.editionNo !== undefined && typeof values.editionNo !== 'number') {
      values.editionNo = Number(values.editionNo);
    }
    if (values.printRun !== undefined && typeof values.printRun !== 'number') {
      values.printRun = Number(values.printRun);
    }
    if (values.pages !== undefined && typeof values.pages !== 'number') {
      values.pages = Number(values.pages);
    }
    if (values.refYear !== undefined && typeof values.refYear !== 'number') {
      values.refYear = Number(values.refYear);
    }
    if (values.isbnRefYear !== undefined && typeof values.isbnRefYear !== 'number') {
      values.isbnRefYear = Number(values.isbnRefYear);
    }
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.modifiedAt = convertDateTimeToServer(values.modifiedAt);
    values.deletedAt = convertDateTimeToServer(values.deletedAt);

    const entity = {
      ...publicationReleaseEntity,
      ...values,
      manufacturer: manufacturers.find(it => it.id.toString() === values.manufacturer.toString()),
      distributor: manufacturers.find(it => it.id.toString() === values.distributor.toString()),
      isn: iSNS.find(it => it.id.toString() === values.isn.toString()),
      publication: publications.find(it => it.id.toString() === values.publication.toString()),
      version: versions.find(it => it.id.toString() === values.version.toString()),
      medium: mediums.find(it => it.id.toString() === values.medium.toString()),
      contentType: contentTypes.find(it => it.id.toString() === values.contentType.toString()),
      format: formats.find(it => it.id.toString() === values.format.toString()),
      languageCode: languages.find(it => it.id.toString() === values.languageCode.toString()),
      license: licenses.find(it => it.id.toString() === values.license.toString()),
      publicationRelease: publicationReleases.find(it => it.id.toString() === values.publicationRelease.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          modifiedAt: displayDefaultDateTime(),
          deletedAt: displayDefaultDateTime(),
        }
      : {
          ...publicationReleaseEntity,
          createdAt: convertDateTimeFromServer(publicationReleaseEntity.createdAt),
          modifiedAt: convertDateTimeFromServer(publicationReleaseEntity.modifiedAt),
          deletedAt: convertDateTimeFromServer(publicationReleaseEntity.deletedAt),
          manufacturer: publicationReleaseEntity?.manufacturer?.id,
          distributor: publicationReleaseEntity?.distributor?.id,
          isn: publicationReleaseEntity?.isn?.id,
          publication: publicationReleaseEntity?.publication?.id,
          version: publicationReleaseEntity?.version?.id,
          medium: publicationReleaseEntity?.medium?.id,
          contentType: publicationReleaseEntity?.contentType?.id,
          format: publicationReleaseEntity?.format?.id,
          languageCode: publicationReleaseEntity?.languageCode?.id,
          license: publicationReleaseEntity?.license?.id,
          publicationRelease: publicationReleaseEntity?.publicationRelease?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="kpbackApp.publicationRelease.home.createOrEditLabel" data-cy="PublicationReleaseCreateUpdateHeading">
            <Translate contentKey="kpbackApp.publicationRelease.home.createOrEditLabel">Create or edit a PublicationRelease</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="publication-release-id"
                  label={translate('kpbackApp.publicationRelease.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.legacyId')}
                id="publication-release-legacyId"
                name="legacyId"
                data-cy="legacyId"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.licenseOther')}
                id="publication-release-licenseOther"
                name="licenseOther"
                data-cy="licenseOther"
                type="text"
                validate={{
                  maxLength: { value: 16000, message: translate('entity.validation.maxlength', { max: 16000 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.licenseComment')}
                id="publication-release-licenseComment"
                name="licenseComment"
                data-cy="licenseComment"
                type="text"
                validate={{
                  maxLength: { value: 16000, message: translate('entity.validation.maxlength', { max: 16000 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.manufacToBeDeterm')}
                id="publication-release-manufacToBeDeterm"
                name="manufacToBeDeterm"
                data-cy="manufacToBeDeterm"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.distributorToBeDeterm')}
                id="publication-release-distributorToBeDeterm"
                name="distributorToBeDeterm"
                data-cy="distributorToBeDeterm"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.mediumOther')}
                id="publication-release-mediumOther"
                name="mediumOther"
                data-cy="mediumOther"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.otherContent')}
                id="publication-release-otherContent"
                name="otherContent"
                data-cy="otherContent"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.height')}
                id="publication-release-height"
                name="height"
                data-cy="height"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.width')}
                id="publication-release-width"
                name="width"
                data-cy="width"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.orderNo')}
                id="publication-release-orderNo"
                name="orderNo"
                data-cy="orderNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.isEdition')}
                id="publication-release-isEdition"
                name="isEdition"
                data-cy="isEdition"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.editionNo')}
                id="publication-release-editionNo"
                name="editionNo"
                data-cy="editionNo"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.editionImproved')}
                id="publication-release-editionImproved"
                name="editionImproved"
                data-cy="editionImproved"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.editionEdited')}
                id="publication-release-editionEdited"
                name="editionEdited"
                data-cy="editionEdited"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.editionRePrint')}
                id="publication-release-editionRePrint"
                name="editionRePrint"
                data-cy="editionRePrint"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.editionOther')}
                id="publication-release-editionOther"
                name="editionOther"
                data-cy="editionOther"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.printRun')}
                id="publication-release-printRun"
                name="printRun"
                data-cy="printRun"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.pages')}
                id="publication-release-pages"
                name="pages"
                data-cy="pages"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.hasAppendices')}
                id="publication-release-hasAppendices"
                name="hasAppendices"
                data-cy="hasAppendices"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.otherAppendix')}
                id="publication-release-otherAppendix"
                name="otherAppendix"
                data-cy="otherAppendix"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.webpage')}
                id="publication-release-webpage"
                name="webpage"
                data-cy="webpage"
                type="text"
                validate={{
                  maxLength: { value: 200, message: translate('entity.validation.maxlength', { max: 200 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.isAppendix')}
                id="publication-release-isAppendix"
                name="isAppendix"
                data-cy="isAppendix"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.indepDistributed')}
                id="publication-release-indepDistributed"
                name="indepDistributed"
                data-cy="indepDistributed"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.title')}
                id="publication-release-title"
                name="title"
                data-cy="title"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.hasDrm')}
                id="publication-release-hasDrm"
                name="hasDrm"
                data-cy="hasDrm"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.hasWatermark')}
                id="publication-release-hasWatermark"
                name="hasWatermark"
                data-cy="hasWatermark"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.hasManufacturer')}
                id="publication-release-hasManufacturer"
                name="hasManufacturer"
                data-cy="hasManufacturer"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.isReference')}
                id="publication-release-isReference"
                name="isReference"
                data-cy="isReference"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.refTitle')}
                id="publication-release-refTitle"
                name="refTitle"
                data-cy="refTitle"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.refIsn')}
                id="publication-release-refIsn"
                name="refIsn"
                data-cy="refIsn"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.refPublisher')}
                id="publication-release-refPublisher"
                name="refPublisher"
                data-cy="refPublisher"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.refYear')}
                id="publication-release-refYear"
                name="refYear"
                data-cy="refYear"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.refNo')}
                id="publication-release-refNo"
                name="refNo"
                data-cy="refNo"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.refLocation')}
                id="publication-release-refLocation"
                name="refLocation"
                data-cy="refLocation"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.scale')}
                id="publication-release-scale"
                name="scale"
                data-cy="scale"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.languageOther')}
                id="publication-release-languageOther"
                name="languageOther"
                data-cy="languageOther"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.hasIsbnRef')}
                id="publication-release-hasIsbnRef"
                name="hasIsbnRef"
                data-cy="hasIsbnRef"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.isbnRefIsbn')}
                id="publication-release-isbnRefIsbn"
                name="isbnRefIsbn"
                data-cy="isbnRefIsbn"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.isbnRefAuthor')}
                id="publication-release-isbnRefAuthor"
                name="isbnRefAuthor"
                data-cy="isbnRefAuthor"
                type="text"
                validate={{
                  maxLength: { value: 120, message: translate('entity.validation.maxlength', { max: 120 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.isbnRefTitle')}
                id="publication-release-isbnRefTitle"
                name="isbnRefTitle"
                data-cy="isbnRefTitle"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.isbnRefPublisher')}
                id="publication-release-isbnRefPublisher"
                name="isbnRefPublisher"
                data-cy="isbnRefPublisher"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.isbnRefYear')}
                id="publication-release-isbnRefYear"
                name="isbnRefYear"
                data-cy="isbnRefYear"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.isbnRefLocation')}
                id="publication-release-isbnRefLocation"
                name="isbnRefLocation"
                data-cy="isbnRefLocation"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.esterId')}
                id="publication-release-esterId"
                name="esterId"
                data-cy="esterId"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.isDeleted')}
                id="publication-release-isDeleted"
                name="isDeleted"
                data-cy="isDeleted"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.createdBy')}
                id="publication-release-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.createdAt')}
                id="publication-release-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.modifiedBy')}
                id="publication-release-modifiedBy"
                name="modifiedBy"
                data-cy="modifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.modifiedAt')}
                id="publication-release-modifiedAt"
                name="modifiedAt"
                data-cy="modifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.deletedBy')}
                id="publication-release-deletedBy"
                name="deletedBy"
                data-cy="deletedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationRelease.deletedAt')}
                id="publication-release-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="publication-release-manufacturer"
                name="manufacturer"
                data-cy="manufacturer"
                label={translate('kpbackApp.publicationRelease.manufacturer')}
                type="select"
              >
                <option value="" key="0" />
                {manufacturers
                  ? manufacturers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-release-distributor"
                name="distributor"
                data-cy="distributor"
                label={translate('kpbackApp.publicationRelease.distributor')}
                type="select"
              >
                <option value="" key="0" />
                {manufacturers
                  ? manufacturers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-release-isn"
                name="isn"
                data-cy="isn"
                label={translate('kpbackApp.publicationRelease.isn')}
                type="select"
              >
                <option value="" key="0" />
                {iSNS
                  ? iSNS.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-release-publication"
                name="publication"
                data-cy="publication"
                label={translate('kpbackApp.publicationRelease.publication')}
                type="select"
              >
                <option value="" key="0" />
                {publications
                  ? publications.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-release-version"
                name="version"
                data-cy="version"
                label={translate('kpbackApp.publicationRelease.version')}
                type="select"
              >
                <option value="" key="0" />
                {versions
                  ? versions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-release-medium"
                name="medium"
                data-cy="medium"
                label={translate('kpbackApp.publicationRelease.medium')}
                type="select"
              >
                <option value="" key="0" />
                {mediums
                  ? mediums.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-release-contentType"
                name="contentType"
                data-cy="contentType"
                label={translate('kpbackApp.publicationRelease.contentType')}
                type="select"
              >
                <option value="" key="0" />
                {contentTypes
                  ? contentTypes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-release-format"
                name="format"
                data-cy="format"
                label={translate('kpbackApp.publicationRelease.format')}
                type="select"
              >
                <option value="" key="0" />
                {formats
                  ? formats.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-release-languageCode"
                name="languageCode"
                data-cy="languageCode"
                label={translate('kpbackApp.publicationRelease.languageCode')}
                type="select"
              >
                <option value="" key="0" />
                {languages
                  ? languages.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-release-license"
                name="license"
                data-cy="license"
                label={translate('kpbackApp.publicationRelease.license')}
                type="select"
              >
                <option value="" key="0" />
                {licenses
                  ? licenses.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-release-publicationRelease"
                name="publicationRelease"
                data-cy="publicationRelease"
                label={translate('kpbackApp.publicationRelease.publicationRelease')}
                type="select"
              >
                <option value="" key="0" />
                {publicationReleases
                  ? publicationReleases.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/publication-release" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PublicationReleaseUpdate;
