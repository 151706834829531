import dayjs from 'dayjs';
import { IISN } from 'app/shared/model/isn.model';
import { IPublisher } from 'app/shared/model/publisher.model';
import { IIsnClass } from 'app/shared/model/isn-class.model';
import { IClassifier } from 'app/shared/model/classifier.model';
import { IAddress } from 'app/shared/model/address.model';
import { ILanguage } from 'app/shared/model/language.model';
import { IPublishPeriod } from 'app/shared/model/publish-period.model';
import { IKeyword } from 'app/shared/model/keyword.model';
import { FileStatus } from 'app/shared/model/enumerations/file-status.model';

export interface IPublication {
  id?: string;
  legacyId?: number | null;
  title?: string;
  subtitle?: string | null;
  languageOther?: string | null;
  isTranslated?: boolean;
  origLanguageOther?: string | null;
  origTitle?: string | null;
  isMultiVolume?: boolean;
  volumes?: number;
  volumeNo?: number;
  isSeries?: boolean;
  seriesTitle?: string | null;
  issn?: string | null;
  seriesNo?: number;
  yearPublished?: number;
  publisherComment?: string | null;
  issuerComment?: string | null;
  fileStatus?: keyof typeof FileStatus;
  publishOther?: string | null;
  publishInterval?: number;
  periodicalTypeOther?: string | null;
  hasOtherTitle?: boolean;
  otherTitle?: string | null;
  otherTitleIsn?: string | null;
  hasSubseries?: boolean;
  subseriesTitle?: string | null;
  subseriesIsn?: string | null;
  hasVersions?: boolean;
  hasIsbnRef?: boolean;
  isbnRefIsbn?: string | null;
  isbnRefAuthor?: string | null;
  isbnRefTitle?: string | null;
  isbnRefPublisher?: string | null;
  isbnRefYear?: number;
  isbnRefRecordNo?: string | null;
  filesSubmitterId?: number;
  filesSubmittedAt?: dayjs.Dayjs;
  volumesIsnNo?: string | null;
  volumeTitle?: string | null;
  volumeIsnRequested?: boolean;
  issnRequested?: boolean;
  secondTitle?: string | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  submittedBy?: string;
  submittedAt?: dayjs.Dayjs;
  confirmedBy?: string | null;
  confirmedAt?: dayjs.Dayjs | null;
  isn?: IISN | null;
  publisher?: IPublisher | null;
  isnClass?: IIsnClass | null;
  status?: IClassifier | null;
  address?: IAddress | null;
  languageCode?: ILanguage | null;
  origLanguageCode?: ILanguage | null;
  periodOfYear?: IClassifier | null;
  publishPeriod?: IPublishPeriod | null;
  periodicalType?: IClassifier | null;
  keywords?: IKeyword[] | null;
}

export const defaultValue: Readonly<IPublication> = {
  isTranslated: false,
  isMultiVolume: false,
  isSeries: false,
  hasOtherTitle: false,
  hasSubseries: false,
  hasVersions: false,
  hasIsbnRef: false,
  volumeIsnRequested: false,
  issnRequested: false,
};
