import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './fileset.reducer';

export const FilesetDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const filesetEntity = useAppSelector(state => state.fileset.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="filesetDetailsHeading">
          <Translate contentKey="kpbackApp.fileset.detail.title">Fileset</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.fileset.id">Id</Translate>
            </span>
          </dt>
          <dd>{filesetEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.fileset.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{filesetEntity.legacyId}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="kpbackApp.fileset.title">Title</Translate>
            </span>
          </dt>
          <dd>{filesetEntity.title}</dd>
          <dt>
            <span id="confirmedAt">
              <Translate contentKey="kpbackApp.fileset.confirmedAt">Confirmed At</Translate>
            </span>
          </dt>
          <dd>
            {filesetEntity.confirmedAt ? <TextFormat value={filesetEntity.confirmedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="publishedAt">
              <Translate contentKey="kpbackApp.fileset.publishedAt">Published At</Translate>
            </span>
          </dt>
          <dd>
            {filesetEntity.publishedAt ? <TextFormat value={filesetEntity.publishedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="fedoraId">
              <Translate contentKey="kpbackApp.fileset.fedoraId">Fedora Id</Translate>
            </span>
          </dt>
          <dd>{filesetEntity.fedoraId}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.fileset.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{filesetEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.fileset.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{filesetEntity.createdAt ? <TextFormat value={filesetEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.fileset.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{filesetEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.fileset.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>{filesetEntity.modifiedAt ? <TextFormat value={filesetEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.fileset.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{filesetEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.fileset.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>{filesetEntity.deletedAt ? <TextFormat value={filesetEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="kpbackApp.fileset.release">Release</Translate>
          </dt>
          <dd>{filesetEntity.release ? filesetEntity.release.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.fileset.filesStatus">Files Status</Translate>
          </dt>
          <dd>{filesetEntity.filesStatus ? filesetEntity.filesStatus.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/fileset" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/fileset/${filesetEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default FilesetDetail;
