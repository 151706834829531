import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ISNBlock from './isn-block';
import ISNBlockDetail from './isn-block-detail';
import ISNBlockUpdate from './isn-block-update';
import ISNBlockDeleteDialog from './isn-block-delete-dialog';

const ISNBlockRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ISNBlock />} />
    <Route path="new" element={<ISNBlockUpdate />} />
    <Route path=":id">
      <Route index element={<ISNBlockDetail />} />
      <Route path="edit" element={<ISNBlockUpdate />} />
      <Route path="delete" element={<ISNBlockDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ISNBlockRoutes;
