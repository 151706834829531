import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './note.reducer';

export const NoteDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const noteEntity = useAppSelector(state => state.note.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="noteDetailsHeading">
          <Translate contentKey="kpbackApp.note.detail.title">Note</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.note.id">Id</Translate>
            </span>
          </dt>
          <dd>{noteEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.note.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{noteEntity.legacyId}</dd>
          <dt>
            <span id="note">
              <Translate contentKey="kpbackApp.note.note">Note</Translate>
            </span>
          </dt>
          <dd>{noteEntity.note}</dd>
          <dt>
            <span id="isPublic">
              <Translate contentKey="kpbackApp.note.isPublic">Is Public</Translate>
            </span>
          </dt>
          <dd>{noteEntity.isPublic ? 'true' : 'false'}</dd>
          <dt>
            <span id="isDeleted">
              <Translate contentKey="kpbackApp.note.isDeleted">Is Deleted</Translate>
            </span>
          </dt>
          <dd>{noteEntity.isDeleted ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.note.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{noteEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.note.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{noteEntity.createdAt ? <TextFormat value={noteEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.note.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{noteEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.note.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>{noteEntity.modifiedAt ? <TextFormat value={noteEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.note.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{noteEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.note.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>{noteEntity.deletedAt ? <TextFormat value={noteEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="kpbackApp.note.publication">Publication</Translate>
          </dt>
          <dd>{noteEntity.publication ? noteEntity.publication.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.note.publisher">Publisher</Translate>
          </dt>
          <dd>{noteEntity.publisher ? noteEntity.publisher.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/note" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/note/${noteEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default NoteDetail;
