import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './network.reducer';

export const NetworkDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const networkEntity = useAppSelector(state => state.network.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="networkDetailsHeading">
          <Translate contentKey="kpbackApp.network.detail.title">Network</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.network.id">Id</Translate>
            </span>
          </dt>
          <dd>{networkEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.network.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{networkEntity.legacyId}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="kpbackApp.network.code">Code</Translate>
            </span>
          </dt>
          <dd>{networkEntity.code}</dd>
          <dt>
            <span id="orderNumber">
              <Translate contentKey="kpbackApp.network.orderNumber">Order Number</Translate>
            </span>
          </dt>
          <dd>{networkEntity.orderNumber}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="kpbackApp.network.name">Name</Translate>
            </span>
          </dt>
          <dd>{networkEntity.name}</dd>
          <dt>
            <span id="canDownload">
              <Translate contentKey="kpbackApp.network.canDownload">Can Download</Translate>
            </span>
          </dt>
          <dd>{networkEntity.canDownload ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.network.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{networkEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.network.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{networkEntity.createdAt ? <TextFormat value={networkEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.network.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{networkEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.network.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>{networkEntity.modifiedAt ? <TextFormat value={networkEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.network.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{networkEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.network.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>{networkEntity.deletedAt ? <TextFormat value={networkEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
        </dl>
        <Button tag={Link} to="/network" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/network/${networkEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default NetworkDetail;
