import dayjs from 'dayjs';

export interface IIsnClass {
  id?: string;
  legacyId?: number | null;
  typeCode?: string | null;
  orderNo?: number;
  groupNo?: number;
  nameEt?: string | null;
  nameShortEt?: string | null;
  forLibrary?: boolean;
  hasShort?: boolean;
  onlyShort?: boolean;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<IIsnClass> = {
  forLibrary: false,
  hasShort: false,
  onlyShort: false,
};
