import dayjs from 'dayjs';
import { IPublisher } from 'app/shared/model/publisher.model';
import { IClassifier } from 'app/shared/model/classifier.model';

export interface IPublisherRelationship {
  id?: string;
  validFrom?: dayjs.Dayjs;
  validTo?: dayjs.Dayjs;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  parentPublisher?: IPublisher | null;
  childPublisher?: IPublisher | null;
  relationType?: IClassifier | null;
}

export const defaultValue: Readonly<IPublisherRelationship> = {};
