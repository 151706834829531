import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './person.reducer';

export const PersonDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const personEntity = useAppSelector(state => state.person.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="personDetailsHeading">
          <Translate contentKey="kpbackApp.person.detail.title">Person</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.person.id">Id</Translate>
            </span>
          </dt>
          <dd>{personEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.person.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{personEntity.legacyId}</dd>
          <dt>
            <span id="firstName">
              <Translate contentKey="kpbackApp.person.firstName">First Name</Translate>
            </span>
          </dt>
          <dd>{personEntity.firstName}</dd>
          <dt>
            <span id="lastName">
              <Translate contentKey="kpbackApp.person.lastName">Last Name</Translate>
            </span>
          </dt>
          <dd>{personEntity.lastName}</dd>
          <dt>
            <span id="ssoEmail">
              <Translate contentKey="kpbackApp.person.ssoEmail">Sso Email</Translate>
            </span>
          </dt>
          <dd>{personEntity.ssoEmail}</dd>
          <dt>
            <span id="languageCode">
              <Translate contentKey="kpbackApp.person.languageCode">Language Code</Translate>
            </span>
          </dt>
          <dd>{personEntity.languageCode}</dd>
          <dt>
            <span id="sierraId">
              <Translate contentKey="kpbackApp.person.sierraId">Sierra Id</Translate>
            </span>
          </dt>
          <dd>{personEntity.sierraId}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.person.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{personEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.person.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{personEntity.createdAt ? <TextFormat value={personEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.person.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{personEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.person.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>{personEntity.modifiedAt ? <TextFormat value={personEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.person.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{personEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.person.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>{personEntity.deletedAt ? <TextFormat value={personEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
        </dl>
        <Button tag={Link} to="/person" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/person/${personEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PersonDetail;
