import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IActionLogType } from 'app/shared/model/action-log-type.model';
import { getEntities as getActionLogTypes } from 'app/entities/action-log-type/action-log-type.reducer';
import { ISubjectActionType } from 'app/shared/model/subject-action-type.model';
import { getEntities as getSubjectActionTypes } from 'app/entities/subject-action-type/subject-action-type.reducer';
import { IPublication } from 'app/shared/model/publication.model';
import { getEntities as getPublications } from 'app/entities/publication/publication.reducer';
import { IPublisher } from 'app/shared/model/publisher.model';
import { getEntities as getPublishers } from 'app/entities/publisher/publisher.reducer';
import { IKpAccount } from 'app/shared/model/kp-account.model';
import { getEntities as getKpAccounts } from 'app/entities/kp-account/kp-account.reducer';
import { IPerson } from 'app/shared/model/person.model';
import { getEntities as getPeople } from 'app/entities/person/person.reducer';
import { IActionLog } from 'app/shared/model/action-log.model';
import { getEntity, updateEntity, createEntity, reset } from './action-log.reducer';

export const ActionLogUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const actionLogTypes = useAppSelector(state => state.actionLogType.entities);
  const subjectActionTypes = useAppSelector(state => state.subjectActionType.entities);
  const publications = useAppSelector(state => state.publication.entities);
  const publishers = useAppSelector(state => state.publisher.entities);
  const kpAccounts = useAppSelector(state => state.kpAccount.entities);
  const people = useAppSelector(state => state.person.entities);
  const actionLogEntity = useAppSelector(state => state.actionLog.entity);
  const loading = useAppSelector(state => state.actionLog.loading);
  const updating = useAppSelector(state => state.actionLog.updating);
  const updateSuccess = useAppSelector(state => state.actionLog.updateSuccess);

  const handleClose = () => {
    navigate('/action-log' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getActionLogTypes({}));
    dispatch(getSubjectActionTypes({}));
    dispatch(getPublications({}));
    dispatch(getPublishers({}));
    dispatch(getKpAccounts({}));
    dispatch(getPeople({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.legacyId !== undefined && typeof values.legacyId !== 'number') {
      values.legacyId = Number(values.legacyId);
    }
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.modifiedAt = convertDateTimeToServer(values.modifiedAt);
    values.deletedAt = convertDateTimeToServer(values.deletedAt);

    const entity = {
      ...actionLogEntity,
      ...values,
      actionLogType: actionLogTypes.find(it => it.id.toString() === values.actionLogType.toString()),
      subjectActionType: subjectActionTypes.find(it => it.id.toString() === values.subjectActionType.toString()),
      publication: publications.find(it => it.id.toString() === values.publication.toString()),
      publisher: publishers.find(it => it.id.toString() === values.publisher.toString()),
      user: kpAccounts.find(it => it.id.toString() === values.user.toString()),
      person: people.find(it => it.id.toString() === values.person.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          modifiedAt: displayDefaultDateTime(),
          deletedAt: displayDefaultDateTime(),
        }
      : {
          ...actionLogEntity,
          createdAt: convertDateTimeFromServer(actionLogEntity.createdAt),
          modifiedAt: convertDateTimeFromServer(actionLogEntity.modifiedAt),
          deletedAt: convertDateTimeFromServer(actionLogEntity.deletedAt),
          actionLogType: actionLogEntity?.actionLogType?.id,
          subjectActionType: actionLogEntity?.subjectActionType?.id,
          publication: actionLogEntity?.publication?.id,
          publisher: actionLogEntity?.publisher?.id,
          user: actionLogEntity?.user?.id,
          person: actionLogEntity?.person?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="kpbackApp.actionLog.home.createOrEditLabel" data-cy="ActionLogCreateUpdateHeading">
            <Translate contentKey="kpbackApp.actionLog.home.createOrEditLabel">Create or edit a ActionLog</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="action-log-id"
                  label={translate('kpbackApp.actionLog.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('kpbackApp.actionLog.legacyId')}
                id="action-log-legacyId"
                name="legacyId"
                data-cy="legacyId"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.actionLog.content')}
                id="action-log-content"
                name="content"
                data-cy="content"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.actionLog.createdBy')}
                id="action-log-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.actionLog.createdAt')}
                id="action-log-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.actionLog.modifiedBy')}
                id="action-log-modifiedBy"
                name="modifiedBy"
                data-cy="modifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.actionLog.modifiedAt')}
                id="action-log-modifiedAt"
                name="modifiedAt"
                data-cy="modifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.actionLog.deletedBy')}
                id="action-log-deletedBy"
                name="deletedBy"
                data-cy="deletedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.actionLog.deletedAt')}
                id="action-log-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="action-log-actionLogType"
                name="actionLogType"
                data-cy="actionLogType"
                label={translate('kpbackApp.actionLog.actionLogType')}
                type="select"
              >
                <option value="" key="0" />
                {actionLogTypes
                  ? actionLogTypes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="action-log-subjectActionType"
                name="subjectActionType"
                data-cy="subjectActionType"
                label={translate('kpbackApp.actionLog.subjectActionType')}
                type="select"
              >
                <option value="" key="0" />
                {subjectActionTypes
                  ? subjectActionTypes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="action-log-publication"
                name="publication"
                data-cy="publication"
                label={translate('kpbackApp.actionLog.publication')}
                type="select"
              >
                <option value="" key="0" />
                {publications
                  ? publications.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="action-log-publisher"
                name="publisher"
                data-cy="publisher"
                label={translate('kpbackApp.actionLog.publisher')}
                type="select"
              >
                <option value="" key="0" />
                {publishers
                  ? publishers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="action-log-user" name="user" data-cy="user" label={translate('kpbackApp.actionLog.user')} type="select">
                <option value="" key="0" />
                {kpAccounts
                  ? kpAccounts.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="action-log-person"
                name="person"
                data-cy="person"
                label={translate('kpbackApp.actionLog.person')}
                type="select"
              >
                <option value="" key="0" />
                {people
                  ? people.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/action-log" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ActionLogUpdate;
