import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import FilesetItem from './fileset-item';
import FilesetItemDetail from './fileset-item-detail';
import FilesetItemUpdate from './fileset-item-update';
import FilesetItemDeleteDialog from './fileset-item-delete-dialog';

const FilesetItemRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<FilesetItem />} />
    <Route path="new" element={<FilesetItemUpdate />} />
    <Route path=":id">
      <Route index element={<FilesetItemDetail />} />
      <Route path="edit" element={<FilesetItemUpdate />} />
      <Route path="delete" element={<FilesetItemDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FilesetItemRoutes;
