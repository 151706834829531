import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IClassifier } from 'app/shared/model/classifier.model';
import { getEntities as getClassifiers } from 'app/entities/classifier/classifier.reducer';
import { IAddress } from 'app/shared/model/address.model';
import { getEntities as getAddresses } from 'app/entities/address/address.reducer';
import { IPublisher } from 'app/shared/model/publisher.model';
import { getEntities as getPublishers } from 'app/entities/publisher/publisher.reducer';
import { IDigitalPublication } from 'app/shared/model/digital-publication.model';
import { getEntity, updateEntity, createEntity, reset } from './digital-publication.reducer';

export const DigitalPublicationUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const classifiers = useAppSelector(state => state.classifier.entities);
  const addresses = useAppSelector(state => state.address.entities);
  const publishers = useAppSelector(state => state.publisher.entities);
  const digitalPublicationEntity = useAppSelector(state => state.digitalPublication.entity);
  const loading = useAppSelector(state => state.digitalPublication.loading);
  const updating = useAppSelector(state => state.digitalPublication.updating);
  const updateSuccess = useAppSelector(state => state.digitalPublication.updateSuccess);

  const handleClose = () => {
    navigate('/digital-publication' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getClassifiers({}));
    dispatch(getAddresses({}));
    dispatch(getPublishers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.legacyId !== undefined && typeof values.legacyId !== 'number') {
      values.legacyId = Number(values.legacyId);
    }
    values.submittedAt = convertDateTimeToServer(values.submittedAt);
    values.confirmedAt = convertDateTimeToServer(values.confirmedAt);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.modifiedAt = convertDateTimeToServer(values.modifiedAt);
    values.deletedAt = convertDateTimeToServer(values.deletedAt);

    const entity = {
      ...digitalPublicationEntity,
      ...values,
      status: classifiers.find(it => it.id.toString() === values.status.toString()),
      address: addresses.find(it => it.id.toString() === values.address.toString()),
      publisher: publishers.find(it => it.id.toString() === values.publisher.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          submittedAt: displayDefaultDateTime(),
          confirmedAt: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          modifiedAt: displayDefaultDateTime(),
          deletedAt: displayDefaultDateTime(),
        }
      : {
          ...digitalPublicationEntity,
          submittedAt: convertDateTimeFromServer(digitalPublicationEntity.submittedAt),
          confirmedAt: convertDateTimeFromServer(digitalPublicationEntity.confirmedAt),
          createdAt: convertDateTimeFromServer(digitalPublicationEntity.createdAt),
          modifiedAt: convertDateTimeFromServer(digitalPublicationEntity.modifiedAt),
          deletedAt: convertDateTimeFromServer(digitalPublicationEntity.deletedAt),
          status: digitalPublicationEntity?.status?.id,
          address: digitalPublicationEntity?.address?.id,
          publisher: digitalPublicationEntity?.publisher?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="kpbackApp.digitalPublication.home.createOrEditLabel" data-cy="DigitalPublicationCreateUpdateHeading">
            <Translate contentKey="kpbackApp.digitalPublication.home.createOrEditLabel">Create or edit a DigitalPublication</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="digital-publication-id"
                  label={translate('kpbackApp.digitalPublication.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('kpbackApp.digitalPublication.legacyId')}
                id="digital-publication-legacyId"
                name="legacyId"
                data-cy="legacyId"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.digitalPublication.publisherBusinessName')}
                id="digital-publication-publisherBusinessName"
                name="publisherBusinessName"
                data-cy="publisherBusinessName"
                type="text"
                validate={{
                  maxLength: { value: 200, message: translate('entity.validation.maxlength', { max: 200 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.digitalPublication.publisherName')}
                id="digital-publication-publisherName"
                name="publisherName"
                data-cy="publisherName"
                type="text"
                validate={{
                  maxLength: { value: 200, message: translate('entity.validation.maxlength', { max: 200 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.digitalPublication.phone')}
                id="digital-publication-phone"
                name="phone"
                data-cy="phone"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.digitalPublication.email')}
                id="digital-publication-email"
                name="email"
                data-cy="email"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.digitalPublication.webpage')}
                id="digital-publication-webpage"
                name="webpage"
                data-cy="webpage"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.digitalPublication.contact')}
                id="digital-publication-contact"
                name="contact"
                data-cy="contact"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.digitalPublication.submittedBy')}
                id="digital-publication-submittedBy"
                name="submittedBy"
                data-cy="submittedBy"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.digitalPublication.submittedAt')}
                id="digital-publication-submittedAt"
                name="submittedAt"
                data-cy="submittedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.digitalPublication.confirmedBy')}
                id="digital-publication-confirmedBy"
                name="confirmedBy"
                data-cy="confirmedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.digitalPublication.confirmedAt')}
                id="digital-publication-confirmedAt"
                name="confirmedAt"
                data-cy="confirmedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.digitalPublication.createdBy')}
                id="digital-publication-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.digitalPublication.createdAt')}
                id="digital-publication-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.digitalPublication.modifiedBy')}
                id="digital-publication-modifiedBy"
                name="modifiedBy"
                data-cy="modifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.digitalPublication.modifiedAt')}
                id="digital-publication-modifiedAt"
                name="modifiedAt"
                data-cy="modifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.digitalPublication.deletedBy')}
                id="digital-publication-deletedBy"
                name="deletedBy"
                data-cy="deletedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.digitalPublication.deletedAt')}
                id="digital-publication-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="digital-publication-status"
                name="status"
                data-cy="status"
                label={translate('kpbackApp.digitalPublication.status')}
                type="select"
              >
                <option value="" key="0" />
                {classifiers
                  ? classifiers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="digital-publication-address"
                name="address"
                data-cy="address"
                label={translate('kpbackApp.digitalPublication.address')}
                type="select"
              >
                <option value="" key="0" />
                {addresses
                  ? addresses.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="digital-publication-publisher"
                name="publisher"
                data-cy="publisher"
                label={translate('kpbackApp.digitalPublication.publisher')}
                type="select"
              >
                <option value="" key="0" />
                {publishers
                  ? publishers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/digital-publication" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DigitalPublicationUpdate;
