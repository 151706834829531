import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Format from './format';
import FormatDetail from './format-detail';
import FormatUpdate from './format-update';
import FormatDeleteDialog from './format-delete-dialog';

const FormatRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Format />} />
    <Route path="new" element={<FormatUpdate />} />
    <Route path=":id">
      <Route index element={<FormatDetail />} />
      <Route path="edit" element={<FormatUpdate />} />
      <Route path="delete" element={<FormatDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FormatRoutes;
