import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './digital-publication-row.reducer';

export const DigitalPublicationRowDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const digitalPublicationRowEntity = useAppSelector(state => state.digitalPublicationRow.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="digitalPublicationRowDetailsHeading">
          <Translate contentKey="kpbackApp.digitalPublicationRow.detail.title">DigitalPublicationRow</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.digitalPublicationRow.id">Id</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationRowEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.digitalPublicationRow.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationRowEntity.legacyId}</dd>
          <dt>
            <span id="sierraRecordNumber">
              <Translate contentKey="kpbackApp.digitalPublicationRow.sierraRecordNumber">Sierra Record Number</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationRowEntity.sierraRecordNumber}</dd>
          <dt>
            <span id="fileFormat">
              <Translate contentKey="kpbackApp.digitalPublicationRow.fileFormat">File Format</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationRowEntity.fileFormat}</dd>
          <dt>
            <span id="webpage">
              <Translate contentKey="kpbackApp.digitalPublicationRow.webpage">Webpage</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationRowEntity.webpage}</dd>
          <dt>
            <span id="licenseOther">
              <Translate contentKey="kpbackApp.digitalPublicationRow.licenseOther">License Other</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationRowEntity.licenseOther}</dd>
          <dt>
            <span id="licenseComment">
              <Translate contentKey="kpbackApp.digitalPublicationRow.licenseComment">License Comment</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationRowEntity.licenseComment}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.digitalPublicationRow.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationRowEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.digitalPublicationRow.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {digitalPublicationRowEntity.createdAt ? (
              <TextFormat value={digitalPublicationRowEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.digitalPublicationRow.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationRowEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.digitalPublicationRow.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {digitalPublicationRowEntity.modifiedAt ? (
              <TextFormat value={digitalPublicationRowEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.digitalPublicationRow.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{digitalPublicationRowEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.digitalPublicationRow.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {digitalPublicationRowEntity.deletedAt ? (
              <TextFormat value={digitalPublicationRowEntity.deletedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="kpbackApp.digitalPublicationRow.digitalPublication">Digital Publication</Translate>
          </dt>
          <dd>{digitalPublicationRowEntity.digitalPublication ? digitalPublicationRowEntity.digitalPublication.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.digitalPublicationRow.license">License</Translate>
          </dt>
          <dd>{digitalPublicationRowEntity.license ? digitalPublicationRowEntity.license.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.digitalPublicationRow.status">Status</Translate>
          </dt>
          <dd>{digitalPublicationRowEntity.status ? digitalPublicationRowEntity.status.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/digital-publication-row" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/digital-publication-row/${digitalPublicationRowEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DigitalPublicationRowDetail;
