import dayjs from 'dayjs';
import { IActionLogType } from 'app/shared/model/action-log-type.model';
import { ISubjectActionType } from 'app/shared/model/subject-action-type.model';
import { IPublication } from 'app/shared/model/publication.model';
import { IPublisher } from 'app/shared/model/publisher.model';
import { IKpAccount } from 'app/shared/model/kp-account.model';
import { IPerson } from 'app/shared/model/person.model';

export interface IActionLog {
  id?: string;
  legacyId?: number | null;
  content?: string | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  actionLogType?: IActionLogType | null;
  subjectActionType?: ISubjectActionType | null;
  publication?: IPublication | null;
  publisher?: IPublisher | null;
  user?: IKpAccount | null;
  person?: IPerson | null;
}

export const defaultValue: Readonly<IActionLog> = {};
