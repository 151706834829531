import dayjs from 'dayjs';
import { IPublisher } from 'app/shared/model/publisher.model';

export interface IPendingFiles {
  id?: string;
  legacyId?: number | null;
  path?: string;
  isScanned?: boolean;
  notifiedAt?: dayjs.Dayjs | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  publisher?: IPublisher | null;
}

export const defaultValue: Readonly<IPendingFiles> = {
  isScanned: false,
};
