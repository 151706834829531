import dayjs from 'dayjs';
import { IISNBlock } from 'app/shared/model/isn-block.model';

export interface IISNBlockRange {
  id?: string;
  legacyId?: number | null;
  isnType?: number;
  rangeStart?: string;
  rangeEnd?: string;
  blockSize?: number;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  isnBlock?: IISNBlock | null;
}

export const defaultValue: Readonly<IISNBlockRange> = {};
