import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IPerson } from 'app/shared/model/person.model';
import { getEntities as getPeople } from 'app/entities/person/person.reducer';
import { IRole } from 'app/shared/model/role.model';
import { getEntities as getRoles } from 'app/entities/role/role.reducer';
import { IAccessRight } from 'app/shared/model/access-right.model';
import { getEntities as getAccessRights } from 'app/entities/access-right/access-right.reducer';
import { IPublisherAccount } from 'app/shared/model/publisher-account.model';
import { getEntities as getPublisherAccounts } from 'app/entities/publisher-account/publisher-account.reducer';
import { IKpAccount } from 'app/shared/model/kp-account.model';
import { getEntity, updateEntity, createEntity, reset } from './kp-account.reducer';

export const KpAccountUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const people = useAppSelector(state => state.person.entities);
  const roles = useAppSelector(state => state.role.entities);
  const accessRights = useAppSelector(state => state.accessRight.entities);
  const publisherAccounts = useAppSelector(state => state.publisherAccount.entities);
  const kpAccountEntity = useAppSelector(state => state.kpAccount.entity);
  const loading = useAppSelector(state => state.kpAccount.loading);
  const updating = useAppSelector(state => state.kpAccount.updating);
  const updateSuccess = useAppSelector(state => state.kpAccount.updateSuccess);

  const handleClose = () => {
    navigate('/kp-account' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getPeople({}));
    dispatch(getRoles({}));
    dispatch(getAccessRights({}));
    dispatch(getPublisherAccounts({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.legacyId !== undefined && typeof values.legacyId !== 'number') {
      values.legacyId = Number(values.legacyId);
    }
    values.validTo = convertDateTimeToServer(values.validTo);
    values.activatedAt = convertDateTimeToServer(values.activatedAt);
    values.activationHashSentAt = convertDateTimeToServer(values.activationHashSentAt);
    values.lastLoginAt = convertDateTimeToServer(values.lastLoginAt);
    values.notificationsSeenAt = convertDateTimeToServer(values.notificationsSeenAt);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.modifiedAt = convertDateTimeToServer(values.modifiedAt);
    values.deletedAt = convertDateTimeToServer(values.deletedAt);

    const entity = {
      ...kpAccountEntity,
      ...values,
      accessRights: mapIdList(values.accessRights),
      person: people.find(it => it.id.toString() === values.person.toString()),
      role: roles.find(it => it.id.toString() === values.role.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          validTo: displayDefaultDateTime(),
          activatedAt: displayDefaultDateTime(),
          activationHashSentAt: displayDefaultDateTime(),
          lastLoginAt: displayDefaultDateTime(),
          notificationsSeenAt: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          modifiedAt: displayDefaultDateTime(),
          deletedAt: displayDefaultDateTime(),
        }
      : {
          ...kpAccountEntity,
          validTo: convertDateTimeFromServer(kpAccountEntity.validTo),
          activatedAt: convertDateTimeFromServer(kpAccountEntity.activatedAt),
          activationHashSentAt: convertDateTimeFromServer(kpAccountEntity.activationHashSentAt),
          lastLoginAt: convertDateTimeFromServer(kpAccountEntity.lastLoginAt),
          notificationsSeenAt: convertDateTimeFromServer(kpAccountEntity.notificationsSeenAt),
          createdAt: convertDateTimeFromServer(kpAccountEntity.createdAt),
          modifiedAt: convertDateTimeFromServer(kpAccountEntity.modifiedAt),
          deletedAt: convertDateTimeFromServer(kpAccountEntity.deletedAt),
          person: kpAccountEntity?.person?.id,
          role: kpAccountEntity?.role?.id,
          accessRights: kpAccountEntity?.accessRights?.map(e => e.id.toString()),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="kpbackApp.kpAccount.home.createOrEditLabel" data-cy="KpAccountCreateUpdateHeading">
            <Translate contentKey="kpbackApp.kpAccount.home.createOrEditLabel">Create or edit a KpAccount</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="kp-account-id"
                  label={translate('kpbackApp.kpAccount.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('kpbackApp.kpAccount.legacyId')}
                id="kp-account-legacyId"
                name="legacyId"
                data-cy="legacyId"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.kpAccount.email')}
                id="kp-account-email"
                name="email"
                data-cy="email"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.kpAccount.isStaff')}
                id="kp-account-isStaff"
                name="isStaff"
                data-cy="isStaff"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.kpAccount.validTo')}
                id="kp-account-validTo"
                name="validTo"
                data-cy="validTo"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.kpAccount.activatedAt')}
                id="kp-account-activatedAt"
                name="activatedAt"
                data-cy="activatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.kpAccount.activationHash')}
                id="kp-account-activationHash"
                name="activationHash"
                data-cy="activationHash"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.kpAccount.activationHashSentAt')}
                id="kp-account-activationHashSentAt"
                name="activationHashSentAt"
                data-cy="activationHashSentAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.kpAccount.lastLoginAt')}
                id="kp-account-lastLoginAt"
                name="lastLoginAt"
                data-cy="lastLoginAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.kpAccount.notificationsSeenAt')}
                id="kp-account-notificationsSeenAt"
                name="notificationsSeenAt"
                data-cy="notificationsSeenAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.kpAccount.ftpAccountName')}
                id="kp-account-ftpAccountName"
                name="ftpAccountName"
                data-cy="ftpAccountName"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.kpAccount.createdBy')}
                id="kp-account-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.kpAccount.createdAt')}
                id="kp-account-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.kpAccount.modifiedBy')}
                id="kp-account-modifiedBy"
                name="modifiedBy"
                data-cy="modifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.kpAccount.modifiedAt')}
                id="kp-account-modifiedAt"
                name="modifiedAt"
                data-cy="modifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.kpAccount.deletedBy')}
                id="kp-account-deletedBy"
                name="deletedBy"
                data-cy="deletedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.kpAccount.deletedAt')}
                id="kp-account-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="kp-account-person"
                name="person"
                data-cy="person"
                label={translate('kpbackApp.kpAccount.person')}
                type="select"
              >
                <option value="" key="0" />
                {people
                  ? people.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="kp-account-role" name="role" data-cy="role" label={translate('kpbackApp.kpAccount.role')} type="select">
                <option value="" key="0" />
                {roles
                  ? roles.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('kpbackApp.kpAccount.accessRight')}
                id="kp-account-accessRight"
                data-cy="accessRight"
                type="select"
                multiple
                name="accessRights"
              >
                <option value="" key="0" />
                {accessRights
                  ? accessRights.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/kp-account" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default KpAccountUpdate;
