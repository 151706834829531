import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PendingFiles from './pending-files';
import PendingFilesDetail from './pending-files-detail';
import PendingFilesUpdate from './pending-files-update';
import PendingFilesDeleteDialog from './pending-files-delete-dialog';

const PendingFilesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PendingFiles />} />
    <Route path="new" element={<PendingFilesUpdate />} />
    <Route path=":id">
      <Route index element={<PendingFilesDetail />} />
      <Route path="edit" element={<PendingFilesUpdate />} />
      <Route path="delete" element={<PendingFilesDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PendingFilesRoutes;
