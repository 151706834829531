import dayjs from 'dayjs';
import { ILanguage } from 'app/shared/model/language.model';
import { IPublicationRelease } from 'app/shared/model/publication-release.model';
import { IRejectionReason } from 'app/shared/model/rejection-reason.model';
import { IDigitalPublicationRow } from 'app/shared/model/digital-publication-row.model';

export interface IRejection {
  id?: string;
  legacyId?: number | null;
  comment?: string | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  languageCode?: ILanguage | null;
  publication?: IPublicationRelease | null;
  rejectionReason?: IRejectionReason | null;
  digitalPublicationRow?: IDigitalPublicationRow | null;
}

export const defaultValue: Readonly<IRejection> = {};
