import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './publication.reducer';

export const PublicationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const publicationEntity = useAppSelector(state => state.publication.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="publicationDetailsHeading">
          <Translate contentKey="kpbackApp.publication.detail.title">Publication</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.publication.id">Id</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.publication.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.legacyId}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="kpbackApp.publication.title">Title</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.title}</dd>
          <dt>
            <span id="subtitle">
              <Translate contentKey="kpbackApp.publication.subtitle">Subtitle</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.subtitle}</dd>
          <dt>
            <span id="languageOther">
              <Translate contentKey="kpbackApp.publication.languageOther">Language Other</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.languageOther}</dd>
          <dt>
            <span id="isTranslated">
              <Translate contentKey="kpbackApp.publication.isTranslated">Is Translated</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.isTranslated ? 'true' : 'false'}</dd>
          <dt>
            <span id="origLanguageOther">
              <Translate contentKey="kpbackApp.publication.origLanguageOther">Orig Language Other</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.origLanguageOther}</dd>
          <dt>
            <span id="origTitle">
              <Translate contentKey="kpbackApp.publication.origTitle">Orig Title</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.origTitle}</dd>
          <dt>
            <span id="isMultiVolume">
              <Translate contentKey="kpbackApp.publication.isMultiVolume">Is Multi Volume</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.isMultiVolume ? 'true' : 'false'}</dd>
          <dt>
            <span id="volumes">
              <Translate contentKey="kpbackApp.publication.volumes">Volumes</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.volumes}</dd>
          <dt>
            <span id="volumeNo">
              <Translate contentKey="kpbackApp.publication.volumeNo">Volume No</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.volumeNo}</dd>
          <dt>
            <span id="isSeries">
              <Translate contentKey="kpbackApp.publication.isSeries">Is Series</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.isSeries ? 'true' : 'false'}</dd>
          <dt>
            <span id="seriesTitle">
              <Translate contentKey="kpbackApp.publication.seriesTitle">Series Title</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.seriesTitle}</dd>
          <dt>
            <span id="issn">
              <Translate contentKey="kpbackApp.publication.issn">Issn</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.issn}</dd>
          <dt>
            <span id="seriesNo">
              <Translate contentKey="kpbackApp.publication.seriesNo">Series No</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.seriesNo}</dd>
          <dt>
            <span id="yearPublished">
              <Translate contentKey="kpbackApp.publication.yearPublished">Year Published</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.yearPublished}</dd>
          <dt>
            <span id="publisherComment">
              <Translate contentKey="kpbackApp.publication.publisherComment">Publisher Comment</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.publisherComment}</dd>
          <dt>
            <span id="issuerComment">
              <Translate contentKey="kpbackApp.publication.issuerComment">Issuer Comment</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.issuerComment}</dd>
          <dt>
            <span id="fileStatus">
              <Translate contentKey="kpbackApp.publication.fileStatus">File Status</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.fileStatus}</dd>
          <dt>
            <span id="publishOther">
              <Translate contentKey="kpbackApp.publication.publishOther">Publish Other</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.publishOther}</dd>
          <dt>
            <span id="publishInterval">
              <Translate contentKey="kpbackApp.publication.publishInterval">Publish Interval</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.publishInterval}</dd>
          <dt>
            <span id="periodicalTypeOther">
              <Translate contentKey="kpbackApp.publication.periodicalTypeOther">Periodical Type Other</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.periodicalTypeOther}</dd>
          <dt>
            <span id="hasOtherTitle">
              <Translate contentKey="kpbackApp.publication.hasOtherTitle">Has Other Title</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.hasOtherTitle ? 'true' : 'false'}</dd>
          <dt>
            <span id="otherTitle">
              <Translate contentKey="kpbackApp.publication.otherTitle">Other Title</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.otherTitle}</dd>
          <dt>
            <span id="otherTitleIsn">
              <Translate contentKey="kpbackApp.publication.otherTitleIsn">Other Title Isn</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.otherTitleIsn}</dd>
          <dt>
            <span id="hasSubseries">
              <Translate contentKey="kpbackApp.publication.hasSubseries">Has Subseries</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.hasSubseries ? 'true' : 'false'}</dd>
          <dt>
            <span id="subseriesTitle">
              <Translate contentKey="kpbackApp.publication.subseriesTitle">Subseries Title</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.subseriesTitle}</dd>
          <dt>
            <span id="subseriesIsn">
              <Translate contentKey="kpbackApp.publication.subseriesIsn">Subseries Isn</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.subseriesIsn}</dd>
          <dt>
            <span id="hasVersions">
              <Translate contentKey="kpbackApp.publication.hasVersions">Has Versions</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.hasVersions ? 'true' : 'false'}</dd>
          <dt>
            <span id="hasIsbnRef">
              <Translate contentKey="kpbackApp.publication.hasIsbnRef">Has Isbn Ref</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.hasIsbnRef ? 'true' : 'false'}</dd>
          <dt>
            <span id="isbnRefIsbn">
              <Translate contentKey="kpbackApp.publication.isbnRefIsbn">Isbn Ref Isbn</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.isbnRefIsbn}</dd>
          <dt>
            <span id="isbnRefAuthor">
              <Translate contentKey="kpbackApp.publication.isbnRefAuthor">Isbn Ref Author</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.isbnRefAuthor}</dd>
          <dt>
            <span id="isbnRefTitle">
              <Translate contentKey="kpbackApp.publication.isbnRefTitle">Isbn Ref Title</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.isbnRefTitle}</dd>
          <dt>
            <span id="isbnRefPublisher">
              <Translate contentKey="kpbackApp.publication.isbnRefPublisher">Isbn Ref Publisher</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.isbnRefPublisher}</dd>
          <dt>
            <span id="isbnRefYear">
              <Translate contentKey="kpbackApp.publication.isbnRefYear">Isbn Ref Year</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.isbnRefYear}</dd>
          <dt>
            <span id="isbnRefRecordNo">
              <Translate contentKey="kpbackApp.publication.isbnRefRecordNo">Isbn Ref Record No</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.isbnRefRecordNo}</dd>
          <dt>
            <span id="filesSubmitterId">
              <Translate contentKey="kpbackApp.publication.filesSubmitterId">Files Submitter Id</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.filesSubmitterId}</dd>
          <dt>
            <span id="filesSubmittedAt">
              <Translate contentKey="kpbackApp.publication.filesSubmittedAt">Files Submitted At</Translate>
            </span>
          </dt>
          <dd>
            {publicationEntity.filesSubmittedAt ? (
              <TextFormat value={publicationEntity.filesSubmittedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="volumesIsnNo">
              <Translate contentKey="kpbackApp.publication.volumesIsnNo">Volumes Isn No</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.volumesIsnNo}</dd>
          <dt>
            <span id="volumeTitle">
              <Translate contentKey="kpbackApp.publication.volumeTitle">Volume Title</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.volumeTitle}</dd>
          <dt>
            <span id="volumeIsnRequested">
              <Translate contentKey="kpbackApp.publication.volumeIsnRequested">Volume Isn Requested</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.volumeIsnRequested ? 'true' : 'false'}</dd>
          <dt>
            <span id="issnRequested">
              <Translate contentKey="kpbackApp.publication.issnRequested">Issn Requested</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.issnRequested ? 'true' : 'false'}</dd>
          <dt>
            <span id="secondTitle">
              <Translate contentKey="kpbackApp.publication.secondTitle">Second Title</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.secondTitle}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.publication.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.publication.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {publicationEntity.createdAt ? <TextFormat value={publicationEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.publication.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.publication.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {publicationEntity.modifiedAt ? <TextFormat value={publicationEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.publication.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.publication.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {publicationEntity.deletedAt ? <TextFormat value={publicationEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="submittedBy">
              <Translate contentKey="kpbackApp.publication.submittedBy">Submitted By</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.submittedBy}</dd>
          <dt>
            <span id="submittedAt">
              <Translate contentKey="kpbackApp.publication.submittedAt">Submitted At</Translate>
            </span>
          </dt>
          <dd>
            {publicationEntity.submittedAt ? (
              <TextFormat value={publicationEntity.submittedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="confirmedBy">
              <Translate contentKey="kpbackApp.publication.confirmedBy">Confirmed By</Translate>
            </span>
          </dt>
          <dd>{publicationEntity.confirmedBy}</dd>
          <dt>
            <span id="confirmedAt">
              <Translate contentKey="kpbackApp.publication.confirmedAt">Confirmed At</Translate>
            </span>
          </dt>
          <dd>
            {publicationEntity.confirmedAt ? (
              <TextFormat value={publicationEntity.confirmedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="kpbackApp.publication.isn">Isn</Translate>
          </dt>
          <dd>{publicationEntity.isn ? publicationEntity.isn.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publication.publisher">Publisher</Translate>
          </dt>
          <dd>{publicationEntity.publisher ? publicationEntity.publisher.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publication.isnClass">Isn Class</Translate>
          </dt>
          <dd>{publicationEntity.isnClass ? publicationEntity.isnClass.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publication.status">Status</Translate>
          </dt>
          <dd>{publicationEntity.status ? publicationEntity.status.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publication.address">Address</Translate>
          </dt>
          <dd>{publicationEntity.address ? publicationEntity.address.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publication.languageCode">Language Code</Translate>
          </dt>
          <dd>{publicationEntity.languageCode ? publicationEntity.languageCode.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publication.origLanguageCode">Orig Language Code</Translate>
          </dt>
          <dd>{publicationEntity.origLanguageCode ? publicationEntity.origLanguageCode.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publication.periodOfYear">Period Of Year</Translate>
          </dt>
          <dd>{publicationEntity.periodOfYear ? publicationEntity.periodOfYear.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publication.publishPeriod">Publish Period</Translate>
          </dt>
          <dd>{publicationEntity.publishPeriod ? publicationEntity.publishPeriod.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publication.periodicalType">Periodical Type</Translate>
          </dt>
          <dd>{publicationEntity.periodicalType ? publicationEntity.periodicalType.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publication.keyword">Keyword</Translate>
          </dt>
          <dd>
            {publicationEntity.keywords
              ? publicationEntity.keywords.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {publicationEntity.keywords && i === publicationEntity.keywords.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/publication" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/publication/${publicationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PublicationDetail;
