import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ISNBlockRange from './isn-block-range';
import ISNBlockRangeDetail from './isn-block-range-detail';
import ISNBlockRangeUpdate from './isn-block-range-update';
import ISNBlockRangeDeleteDialog from './isn-block-range-delete-dialog';

const ISNBlockRangeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ISNBlockRange />} />
    <Route path="new" element={<ISNBlockRangeUpdate />} />
    <Route path=":id">
      <Route index element={<ISNBlockRangeDetail />} />
      <Route path="edit" element={<ISNBlockRangeUpdate />} />
      <Route path="delete" element={<ISNBlockRangeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ISNBlockRangeRoutes;
