import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Publication from './publication';
import PublicationDetail from './publication-detail';
import PublicationUpdate from './publication-update';
import PublicationDeleteDialog from './publication-delete-dialog';

const PublicationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Publication />} />
    <Route path="new" element={<PublicationUpdate />} />
    <Route path=":id">
      <Route index element={<PublicationDetail />} />
      <Route path="edit" element={<PublicationUpdate />} />
      <Route path="delete" element={<PublicationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PublicationRoutes;
