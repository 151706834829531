import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './publication-release.reducer';

export const PublicationReleaseDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const publicationReleaseEntity = useAppSelector(state => state.publicationRelease.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="publicationReleaseDetailsHeading">
          <Translate contentKey="kpbackApp.publicationRelease.detail.title">PublicationRelease</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.publicationRelease.id">Id</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.publicationRelease.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.legacyId}</dd>
          <dt>
            <span id="licenseOther">
              <Translate contentKey="kpbackApp.publicationRelease.licenseOther">License Other</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.licenseOther}</dd>
          <dt>
            <span id="licenseComment">
              <Translate contentKey="kpbackApp.publicationRelease.licenseComment">License Comment</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.licenseComment}</dd>
          <dt>
            <span id="manufacToBeDeterm">
              <Translate contentKey="kpbackApp.publicationRelease.manufacToBeDeterm">Manufac To Be Determ</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.manufacToBeDeterm ? 'true' : 'false'}</dd>
          <dt>
            <span id="distributorToBeDeterm">
              <Translate contentKey="kpbackApp.publicationRelease.distributorToBeDeterm">Distributor To Be Determ</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.distributorToBeDeterm ? 'true' : 'false'}</dd>
          <dt>
            <span id="mediumOther">
              <Translate contentKey="kpbackApp.publicationRelease.mediumOther">Medium Other</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.mediumOther}</dd>
          <dt>
            <span id="otherContent">
              <Translate contentKey="kpbackApp.publicationRelease.otherContent">Other Content</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.otherContent}</dd>
          <dt>
            <span id="height">
              <Translate contentKey="kpbackApp.publicationRelease.height">Height</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.height}</dd>
          <dt>
            <span id="width">
              <Translate contentKey="kpbackApp.publicationRelease.width">Width</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.width}</dd>
          <dt>
            <span id="orderNo">
              <Translate contentKey="kpbackApp.publicationRelease.orderNo">Order No</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.orderNo}</dd>
          <dt>
            <span id="isEdition">
              <Translate contentKey="kpbackApp.publicationRelease.isEdition">Is Edition</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.isEdition ? 'true' : 'false'}</dd>
          <dt>
            <span id="editionNo">
              <Translate contentKey="kpbackApp.publicationRelease.editionNo">Edition No</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.editionNo}</dd>
          <dt>
            <span id="editionImproved">
              <Translate contentKey="kpbackApp.publicationRelease.editionImproved">Edition Improved</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.editionImproved ? 'true' : 'false'}</dd>
          <dt>
            <span id="editionEdited">
              <Translate contentKey="kpbackApp.publicationRelease.editionEdited">Edition Edited</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.editionEdited ? 'true' : 'false'}</dd>
          <dt>
            <span id="editionRePrint">
              <Translate contentKey="kpbackApp.publicationRelease.editionRePrint">Edition Re Print</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.editionRePrint ? 'true' : 'false'}</dd>
          <dt>
            <span id="editionOther">
              <Translate contentKey="kpbackApp.publicationRelease.editionOther">Edition Other</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.editionOther}</dd>
          <dt>
            <span id="printRun">
              <Translate contentKey="kpbackApp.publicationRelease.printRun">Print Run</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.printRun}</dd>
          <dt>
            <span id="pages">
              <Translate contentKey="kpbackApp.publicationRelease.pages">Pages</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.pages}</dd>
          <dt>
            <span id="hasAppendices">
              <Translate contentKey="kpbackApp.publicationRelease.hasAppendices">Has Appendices</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.hasAppendices ? 'true' : 'false'}</dd>
          <dt>
            <span id="otherAppendix">
              <Translate contentKey="kpbackApp.publicationRelease.otherAppendix">Other Appendix</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.otherAppendix}</dd>
          <dt>
            <span id="webpage">
              <Translate contentKey="kpbackApp.publicationRelease.webpage">Webpage</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.webpage}</dd>
          <dt>
            <span id="isAppendix">
              <Translate contentKey="kpbackApp.publicationRelease.isAppendix">Is Appendix</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.isAppendix ? 'true' : 'false'}</dd>
          <dt>
            <span id="indepDistributed">
              <Translate contentKey="kpbackApp.publicationRelease.indepDistributed">Indep Distributed</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.indepDistributed ? 'true' : 'false'}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="kpbackApp.publicationRelease.title">Title</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.title}</dd>
          <dt>
            <span id="hasDrm">
              <Translate contentKey="kpbackApp.publicationRelease.hasDrm">Has Drm</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.hasDrm ? 'true' : 'false'}</dd>
          <dt>
            <span id="hasWatermark">
              <Translate contentKey="kpbackApp.publicationRelease.hasWatermark">Has Watermark</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.hasWatermark ? 'true' : 'false'}</dd>
          <dt>
            <span id="hasManufacturer">
              <Translate contentKey="kpbackApp.publicationRelease.hasManufacturer">Has Manufacturer</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.hasManufacturer ? 'true' : 'false'}</dd>
          <dt>
            <span id="isReference">
              <Translate contentKey="kpbackApp.publicationRelease.isReference">Is Reference</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.isReference ? 'true' : 'false'}</dd>
          <dt>
            <span id="refTitle">
              <Translate contentKey="kpbackApp.publicationRelease.refTitle">Ref Title</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.refTitle}</dd>
          <dt>
            <span id="refIsn">
              <Translate contentKey="kpbackApp.publicationRelease.refIsn">Ref Isn</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.refIsn}</dd>
          <dt>
            <span id="refPublisher">
              <Translate contentKey="kpbackApp.publicationRelease.refPublisher">Ref Publisher</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.refPublisher}</dd>
          <dt>
            <span id="refYear">
              <Translate contentKey="kpbackApp.publicationRelease.refYear">Ref Year</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.refYear}</dd>
          <dt>
            <span id="refNo">
              <Translate contentKey="kpbackApp.publicationRelease.refNo">Ref No</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.refNo}</dd>
          <dt>
            <span id="refLocation">
              <Translate contentKey="kpbackApp.publicationRelease.refLocation">Ref Location</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.refLocation}</dd>
          <dt>
            <span id="scale">
              <Translate contentKey="kpbackApp.publicationRelease.scale">Scale</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.scale}</dd>
          <dt>
            <span id="languageOther">
              <Translate contentKey="kpbackApp.publicationRelease.languageOther">Language Other</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.languageOther}</dd>
          <dt>
            <span id="hasIsbnRef">
              <Translate contentKey="kpbackApp.publicationRelease.hasIsbnRef">Has Isbn Ref</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.hasIsbnRef ? 'true' : 'false'}</dd>
          <dt>
            <span id="isbnRefIsbn">
              <Translate contentKey="kpbackApp.publicationRelease.isbnRefIsbn">Isbn Ref Isbn</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.isbnRefIsbn}</dd>
          <dt>
            <span id="isbnRefAuthor">
              <Translate contentKey="kpbackApp.publicationRelease.isbnRefAuthor">Isbn Ref Author</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.isbnRefAuthor}</dd>
          <dt>
            <span id="isbnRefTitle">
              <Translate contentKey="kpbackApp.publicationRelease.isbnRefTitle">Isbn Ref Title</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.isbnRefTitle}</dd>
          <dt>
            <span id="isbnRefPublisher">
              <Translate contentKey="kpbackApp.publicationRelease.isbnRefPublisher">Isbn Ref Publisher</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.isbnRefPublisher}</dd>
          <dt>
            <span id="isbnRefYear">
              <Translate contentKey="kpbackApp.publicationRelease.isbnRefYear">Isbn Ref Year</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.isbnRefYear}</dd>
          <dt>
            <span id="isbnRefLocation">
              <Translate contentKey="kpbackApp.publicationRelease.isbnRefLocation">Isbn Ref Location</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.isbnRefLocation}</dd>
          <dt>
            <span id="esterId">
              <Translate contentKey="kpbackApp.publicationRelease.esterId">Ester Id</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.esterId}</dd>
          <dt>
            <span id="isDeleted">
              <Translate contentKey="kpbackApp.publicationRelease.isDeleted">Is Deleted</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.isDeleted ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.publicationRelease.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.publicationRelease.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {publicationReleaseEntity.createdAt ? (
              <TextFormat value={publicationReleaseEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.publicationRelease.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.publicationRelease.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {publicationReleaseEntity.modifiedAt ? (
              <TextFormat value={publicationReleaseEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.publicationRelease.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{publicationReleaseEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.publicationRelease.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {publicationReleaseEntity.deletedAt ? (
              <TextFormat value={publicationReleaseEntity.deletedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="kpbackApp.publicationRelease.manufacturer">Manufacturer</Translate>
          </dt>
          <dd>{publicationReleaseEntity.manufacturer ? publicationReleaseEntity.manufacturer.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publicationRelease.distributor">Distributor</Translate>
          </dt>
          <dd>{publicationReleaseEntity.distributor ? publicationReleaseEntity.distributor.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publicationRelease.isn">Isn</Translate>
          </dt>
          <dd>{publicationReleaseEntity.isn ? publicationReleaseEntity.isn.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publicationRelease.publication">Publication</Translate>
          </dt>
          <dd>{publicationReleaseEntity.publication ? publicationReleaseEntity.publication.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publicationRelease.version">Version</Translate>
          </dt>
          <dd>{publicationReleaseEntity.version ? publicationReleaseEntity.version.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publicationRelease.medium">Medium</Translate>
          </dt>
          <dd>{publicationReleaseEntity.medium ? publicationReleaseEntity.medium.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publicationRelease.contentType">Content Type</Translate>
          </dt>
          <dd>{publicationReleaseEntity.contentType ? publicationReleaseEntity.contentType.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publicationRelease.format">Format</Translate>
          </dt>
          <dd>{publicationReleaseEntity.format ? publicationReleaseEntity.format.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publicationRelease.languageCode">Language Code</Translate>
          </dt>
          <dd>{publicationReleaseEntity.languageCode ? publicationReleaseEntity.languageCode.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publicationRelease.license">License</Translate>
          </dt>
          <dd>{publicationReleaseEntity.license ? publicationReleaseEntity.license.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publicationRelease.publicationRelease">Publication Release</Translate>
          </dt>
          <dd>{publicationReleaseEntity.publicationRelease ? publicationReleaseEntity.publicationRelease.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/publication-release" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/publication-release/${publicationReleaseEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PublicationReleaseDetail;
