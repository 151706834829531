import dayjs from 'dayjs';

export interface IPublicationReleasePersonRole {
  id?: number;
  name?: string;
  isOther?: boolean;
  isComposer?: boolean;
  isAuthor?: boolean;
  targetCode?: string;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<IPublicationReleasePersonRole> = {
  isOther: false,
  isComposer: false,
  isAuthor: false,
};
