import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ILanguage } from 'app/shared/model/language.model';
import { getEntities as getLanguages } from 'app/entities/language/language.reducer';
import { IPublicationRelease } from 'app/shared/model/publication-release.model';
import { getEntities as getPublicationReleases } from 'app/entities/publication-release/publication-release.reducer';
import { IRejectionReason } from 'app/shared/model/rejection-reason.model';
import { getEntities as getRejectionReasons } from 'app/entities/rejection-reason/rejection-reason.reducer';
import { IDigitalPublicationRow } from 'app/shared/model/digital-publication-row.model';
import { getEntities as getDigitalPublicationRows } from 'app/entities/digital-publication-row/digital-publication-row.reducer';
import { IRejection } from 'app/shared/model/rejection.model';
import { getEntity, updateEntity, createEntity, reset } from './rejection.reducer';

export const RejectionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const languages = useAppSelector(state => state.language.entities);
  const publicationReleases = useAppSelector(state => state.publicationRelease.entities);
  const rejectionReasons = useAppSelector(state => state.rejectionReason.entities);
  const digitalPublicationRows = useAppSelector(state => state.digitalPublicationRow.entities);
  const rejectionEntity = useAppSelector(state => state.rejection.entity);
  const loading = useAppSelector(state => state.rejection.loading);
  const updating = useAppSelector(state => state.rejection.updating);
  const updateSuccess = useAppSelector(state => state.rejection.updateSuccess);

  const handleClose = () => {
    navigate('/rejection' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getLanguages({}));
    dispatch(getPublicationReleases({}));
    dispatch(getRejectionReasons({}));
    dispatch(getDigitalPublicationRows({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.legacyId !== undefined && typeof values.legacyId !== 'number') {
      values.legacyId = Number(values.legacyId);
    }
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.modifiedAt = convertDateTimeToServer(values.modifiedAt);
    values.deletedAt = convertDateTimeToServer(values.deletedAt);

    const entity = {
      ...rejectionEntity,
      ...values,
      languageCode: languages.find(it => it.id.toString() === values.languageCode.toString()),
      publication: publicationReleases.find(it => it.id.toString() === values.publication.toString()),
      rejectionReason: rejectionReasons.find(it => it.id.toString() === values.rejectionReason.toString()),
      digitalPublicationRow: digitalPublicationRows.find(it => it.id.toString() === values.digitalPublicationRow.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          modifiedAt: displayDefaultDateTime(),
          deletedAt: displayDefaultDateTime(),
        }
      : {
          ...rejectionEntity,
          createdAt: convertDateTimeFromServer(rejectionEntity.createdAt),
          modifiedAt: convertDateTimeFromServer(rejectionEntity.modifiedAt),
          deletedAt: convertDateTimeFromServer(rejectionEntity.deletedAt),
          languageCode: rejectionEntity?.languageCode?.id,
          publication: rejectionEntity?.publication?.id,
          rejectionReason: rejectionEntity?.rejectionReason?.id,
          digitalPublicationRow: rejectionEntity?.digitalPublicationRow?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="kpbackApp.rejection.home.createOrEditLabel" data-cy="RejectionCreateUpdateHeading">
            <Translate contentKey="kpbackApp.rejection.home.createOrEditLabel">Create or edit a Rejection</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="rejection-id"
                  label={translate('kpbackApp.rejection.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('kpbackApp.rejection.legacyId')}
                id="rejection-legacyId"
                name="legacyId"
                data-cy="legacyId"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.rejection.comment')}
                id="rejection-comment"
                name="comment"
                data-cy="comment"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.rejection.createdBy')}
                id="rejection-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.rejection.createdAt')}
                id="rejection-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.rejection.modifiedBy')}
                id="rejection-modifiedBy"
                name="modifiedBy"
                data-cy="modifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.rejection.modifiedAt')}
                id="rejection-modifiedAt"
                name="modifiedAt"
                data-cy="modifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.rejection.deletedBy')}
                id="rejection-deletedBy"
                name="deletedBy"
                data-cy="deletedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.rejection.deletedAt')}
                id="rejection-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="rejection-languageCode"
                name="languageCode"
                data-cy="languageCode"
                label={translate('kpbackApp.rejection.languageCode')}
                type="select"
              >
                <option value="" key="0" />
                {languages
                  ? languages.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="rejection-publication"
                name="publication"
                data-cy="publication"
                label={translate('kpbackApp.rejection.publication')}
                type="select"
              >
                <option value="" key="0" />
                {publicationReleases
                  ? publicationReleases.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="rejection-rejectionReason"
                name="rejectionReason"
                data-cy="rejectionReason"
                label={translate('kpbackApp.rejection.rejectionReason')}
                type="select"
              >
                <option value="" key="0" />
                {rejectionReasons
                  ? rejectionReasons.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="rejection-digitalPublicationRow"
                name="digitalPublicationRow"
                data-cy="digitalPublicationRow"
                label={translate('kpbackApp.rejection.digitalPublicationRow')}
                type="select"
              >
                <option value="" key="0" />
                {digitalPublicationRows
                  ? digitalPublicationRows.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/rejection" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RejectionUpdate;
