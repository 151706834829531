import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IISNBlock } from 'app/shared/model/isn-block.model';
import { getEntities as getISnBlocks } from 'app/entities/isn-block/isn-block.reducer';
import { IISN } from 'app/shared/model/isn.model';
import { ISNTarget } from 'app/shared/model/enumerations/isn-target.model';
import { getEntity, updateEntity, createEntity, reset } from './isn.reducer';

export const ISNUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const iSNBlocks = useAppSelector(state => state.iSNBlock.entities);
  const iSNEntity = useAppSelector(state => state.iSN.entity);
  const loading = useAppSelector(state => state.iSN.loading);
  const updating = useAppSelector(state => state.iSN.updating);
  const updateSuccess = useAppSelector(state => state.iSN.updateSuccess);
  const iSNTargetValues = Object.keys(ISNTarget);

  const handleClose = () => {
    navigate('/isn' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getISnBlocks({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.legacyId !== undefined && typeof values.legacyId !== 'number') {
      values.legacyId = Number(values.legacyId);
    }
    values.canceledAt = convertDateTimeToServer(values.canceledAt);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.modifiedAt = convertDateTimeToServer(values.modifiedAt);
    values.deletedAt = convertDateTimeToServer(values.deletedAt);

    const entity = {
      ...iSNEntity,
      ...values,
      isnBlock: iSNBlocks.find(it => it.id.toString() === values.isnBlock.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          canceledAt: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          modifiedAt: displayDefaultDateTime(),
          deletedAt: displayDefaultDateTime(),
        }
      : {
          isnTarget: 'PUBLICATION',
          ...iSNEntity,
          canceledAt: convertDateTimeFromServer(iSNEntity.canceledAt),
          createdAt: convertDateTimeFromServer(iSNEntity.createdAt),
          modifiedAt: convertDateTimeFromServer(iSNEntity.modifiedAt),
          deletedAt: convertDateTimeFromServer(iSNEntity.deletedAt),
          isnBlock: iSNEntity?.isnBlock?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="kpbackApp.iSN.home.createOrEditLabel" data-cy="ISNCreateUpdateHeading">
            <Translate contentKey="kpbackApp.iSN.home.createOrEditLabel">Create or edit a ISN</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="isn-id"
                  label={translate('kpbackApp.iSN.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('kpbackApp.iSN.legacyId')}
                id="isn-legacyId"
                name="legacyId"
                data-cy="legacyId"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.iSN.isnNumber')}
                id="isn-isnNumber"
                name="isnNumber"
                data-cy="isnNumber"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 15, message: translate('entity.validation.maxlength', { max: 15 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.iSN.isManual')}
                id="isn-isManual"
                name="isManual"
                data-cy="isManual"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.iSN.isnComment')}
                id="isn-isnComment"
                name="isnComment"
                data-cy="isnComment"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.iSN.isnTarget')}
                id="isn-isnTarget"
                name="isnTarget"
                data-cy="isnTarget"
                type="select"
              >
                {iSNTargetValues.map(iSNTarget => (
                  <option value={iSNTarget} key={iSNTarget}>
                    {translate('kpbackApp.ISNTarget.' + iSNTarget)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('kpbackApp.iSN.canceledBy')}
                id="isn-canceledBy"
                name="canceledBy"
                data-cy="canceledBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.iSN.canceledAt')}
                id="isn-canceledAt"
                name="canceledAt"
                data-cy="canceledAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.iSN.createdBy')}
                id="isn-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.iSN.createdAt')}
                id="isn-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.iSN.modifiedBy')}
                id="isn-modifiedBy"
                name="modifiedBy"
                data-cy="modifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.iSN.modifiedAt')}
                id="isn-modifiedAt"
                name="modifiedAt"
                data-cy="modifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.iSN.deletedBy')}
                id="isn-deletedBy"
                name="deletedBy"
                data-cy="deletedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.iSN.deletedAt')}
                id="isn-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="isn-isnBlock"
                name="isnBlock"
                data-cy="isnBlock"
                label={translate('kpbackApp.iSN.isnBlock')}
                type="select"
              >
                <option value="" key="0" />
                {iSNBlocks
                  ? iSNBlocks.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/isn" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ISNUpdate;
