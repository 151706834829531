import dayjs from 'dayjs';
import { IKpAccount } from 'app/shared/model/kp-account.model';
import { IRole } from 'app/shared/model/role.model';

export interface IAccessRight {
  id?: string;
  legacyId?: number | null;
  name?: string;
  code?: string;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  kpAccounts?: IKpAccount[] | null;
  roles?: IRole[] | null;
}

export const defaultValue: Readonly<IAccessRight> = {};
