import dayjs from 'dayjs';
import { IAccessRight } from 'app/shared/model/access-right.model';

export interface IRole {
  id?: string;
  legacyId?: number | null;
  name?: string;
  code?: string;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  accessRights?: IAccessRight[] | null;
}

export const defaultValue: Readonly<IRole> = {};
