import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IPublicationReleasePersonRole } from 'app/shared/model/publication-release-person-role.model';
import { getEntity, updateEntity, createEntity, reset } from './publication-release-person-role.reducer';

export const PublicationReleasePersonRoleUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const publicationReleasePersonRoleEntity = useAppSelector(state => state.publicationReleasePersonRole.entity);
  const loading = useAppSelector(state => state.publicationReleasePersonRole.loading);
  const updating = useAppSelector(state => state.publicationReleasePersonRole.updating);
  const updateSuccess = useAppSelector(state => state.publicationReleasePersonRole.updateSuccess);

  const handleClose = () => {
    navigate('/publication-release-person-role' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.modifiedAt = convertDateTimeToServer(values.modifiedAt);
    values.deletedAt = convertDateTimeToServer(values.deletedAt);

    const entity = {
      ...publicationReleasePersonRoleEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          modifiedAt: displayDefaultDateTime(),
          deletedAt: displayDefaultDateTime(),
        }
      : {
          ...publicationReleasePersonRoleEntity,
          createdAt: convertDateTimeFromServer(publicationReleasePersonRoleEntity.createdAt),
          modifiedAt: convertDateTimeFromServer(publicationReleasePersonRoleEntity.modifiedAt),
          deletedAt: convertDateTimeFromServer(publicationReleasePersonRoleEntity.deletedAt),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="kpbackApp.publicationReleasePersonRole.home.createOrEditLabel" data-cy="PublicationReleasePersonRoleCreateUpdateHeading">
            <Translate contentKey="kpbackApp.publicationReleasePersonRole.home.createOrEditLabel">
              Create or edit a PublicationReleasePersonRole
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="publication-release-person-role-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePersonRole.name')}
                id="publication-release-person-role-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePersonRole.isOther')}
                id="publication-release-person-role-isOther"
                name="isOther"
                data-cy="isOther"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePersonRole.isComposer')}
                id="publication-release-person-role-isComposer"
                name="isComposer"
                data-cy="isComposer"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePersonRole.isAuthor')}
                id="publication-release-person-role-isAuthor"
                name="isAuthor"
                data-cy="isAuthor"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePersonRole.targetCode')}
                id="publication-release-person-role-targetCode"
                name="targetCode"
                data-cy="targetCode"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 1, message: translate('entity.validation.maxlength', { max: 1 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePersonRole.createdBy')}
                id="publication-release-person-role-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePersonRole.createdAt')}
                id="publication-release-person-role-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePersonRole.modifiedBy')}
                id="publication-release-person-role-modifiedBy"
                name="modifiedBy"
                data-cy="modifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePersonRole.modifiedAt')}
                id="publication-release-person-role-modifiedAt"
                name="modifiedAt"
                data-cy="modifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePersonRole.deletedBy')}
                id="publication-release-person-role-deletedBy"
                name="deletedBy"
                data-cy="deletedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePersonRole.deletedAt')}
                id="publication-release-person-role-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/publication-release-person-role"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PublicationReleasePersonRoleUpdate;
