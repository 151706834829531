import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PublisherRelationship from './publisher-relationship';
import PublisherRelationshipDetail from './publisher-relationship-detail';
import PublisherRelationshipUpdate from './publisher-relationship-update';
import PublisherRelationshipDeleteDialog from './publisher-relationship-delete-dialog';

const PublisherRelationshipRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PublisherRelationship />} />
    <Route path="new" element={<PublisherRelationshipUpdate />} />
    <Route path=":id">
      <Route index element={<PublisherRelationshipDetail />} />
      <Route path="edit" element={<PublisherRelationshipUpdate />} />
      <Route path="delete" element={<PublisherRelationshipDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PublisherRelationshipRoutes;
