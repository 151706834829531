import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ConfigurationSetting from './configuration-setting';
import ConfigurationSettingDetail from './configuration-setting-detail';
import ConfigurationSettingUpdate from './configuration-setting-update';
import ConfigurationSettingDeleteDialog from './configuration-setting-delete-dialog';

const ConfigurationSettingRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ConfigurationSetting />} />
    <Route path="new" element={<ConfigurationSettingUpdate />} />
    <Route path=":id">
      <Route index element={<ConfigurationSettingDetail />} />
      <Route path="edit" element={<ConfigurationSettingUpdate />} />
      <Route path="delete" element={<ConfigurationSettingDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ConfigurationSettingRoutes;
