import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IClassifier } from 'app/shared/model/classifier.model';
import { getEntities as getClassifiers } from 'app/entities/classifier/classifier.reducer';
import { IPublicationRelease } from 'app/shared/model/publication-release.model';
import { getEntities as getPublicationReleases } from 'app/entities/publication-release/publication-release.reducer';
import { IPublicationReleasePersonRole } from 'app/shared/model/publication-release-person-role.model';
import { getEntities as getPublicationReleasePersonRoles } from 'app/entities/publication-release-person-role/publication-release-person-role.reducer';
import { IPublicationReleasePerson } from 'app/shared/model/publication-release-person.model';
import { getEntity, updateEntity, createEntity, reset } from './publication-release-person.reducer';

export const PublicationReleasePersonUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const classifiers = useAppSelector(state => state.classifier.entities);
  const publicationReleases = useAppSelector(state => state.publicationRelease.entities);
  const publicationReleasePersonRoles = useAppSelector(state => state.publicationReleasePersonRole.entities);
  const publicationReleasePersonEntity = useAppSelector(state => state.publicationReleasePerson.entity);
  const loading = useAppSelector(state => state.publicationReleasePerson.loading);
  const updating = useAppSelector(state => state.publicationReleasePerson.updating);
  const updateSuccess = useAppSelector(state => state.publicationReleasePerson.updateSuccess);

  const handleClose = () => {
    navigate('/publication-release-person' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getClassifiers({}));
    dispatch(getPublicationReleases({}));
    dispatch(getPublicationReleasePersonRoles({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.orderNo !== undefined && typeof values.orderNo !== 'number') {
      values.orderNo = Number(values.orderNo);
    }
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.modifiedAt = convertDateTimeToServer(values.modifiedAt);
    values.deletedAt = convertDateTimeToServer(values.deletedAt);

    const entity = {
      ...publicationReleasePersonEntity,
      ...values,
      type: classifiers.find(it => it.id.toString() === values.type.toString()),
      release: publicationReleases.find(it => it.id.toString() === values.release.toString()),
      role: publicationReleasePersonRoles.find(it => it.id.toString() === values.role.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          modifiedAt: displayDefaultDateTime(),
          deletedAt: displayDefaultDateTime(),
        }
      : {
          ...publicationReleasePersonEntity,
          createdAt: convertDateTimeFromServer(publicationReleasePersonEntity.createdAt),
          modifiedAt: convertDateTimeFromServer(publicationReleasePersonEntity.modifiedAt),
          deletedAt: convertDateTimeFromServer(publicationReleasePersonEntity.deletedAt),
          type: publicationReleasePersonEntity?.type?.id,
          release: publicationReleasePersonEntity?.release?.id,
          role: publicationReleasePersonEntity?.role?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="kpbackApp.publicationReleasePerson.home.createOrEditLabel" data-cy="PublicationReleasePersonCreateUpdateHeading">
            <Translate contentKey="kpbackApp.publicationReleasePerson.home.createOrEditLabel">
              Create or edit a PublicationReleasePerson
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="publication-release-person-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePerson.roleName')}
                id="publication-release-person-roleName"
                name="roleName"
                data-cy="roleName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePerson.orderNo')}
                id="publication-release-person-orderNo"
                name="orderNo"
                data-cy="orderNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePerson.firstName')}
                id="publication-release-person-firstName"
                name="firstName"
                data-cy="firstName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePerson.lastName')}
                id="publication-release-person-lastName"
                name="lastName"
                data-cy="lastName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePerson.orgName')}
                id="publication-release-person-orgName"
                name="orgName"
                data-cy="orgName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePerson.createdBy')}
                id="publication-release-person-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePerson.createdAt')}
                id="publication-release-person-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePerson.modifiedBy')}
                id="publication-release-person-modifiedBy"
                name="modifiedBy"
                data-cy="modifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePerson.modifiedAt')}
                id="publication-release-person-modifiedAt"
                name="modifiedAt"
                data-cy="modifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePerson.deletedBy')}
                id="publication-release-person-deletedBy"
                name="deletedBy"
                data-cy="deletedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publicationReleasePerson.deletedAt')}
                id="publication-release-person-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="publication-release-person-type"
                name="type"
                data-cy="type"
                label={translate('kpbackApp.publicationReleasePerson.type')}
                type="select"
              >
                <option value="" key="0" />
                {classifiers
                  ? classifiers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-release-person-release"
                name="release"
                data-cy="release"
                label={translate('kpbackApp.publicationReleasePerson.release')}
                type="select"
              >
                <option value="" key="0" />
                {publicationReleases
                  ? publicationReleases.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-release-person-role"
                name="role"
                data-cy="role"
                label={translate('kpbackApp.publicationReleasePerson.role')}
                type="select"
              >
                <option value="" key="0" />
                {publicationReleasePersonRoles
                  ? publicationReleasePersonRoles.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/publication-release-person" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PublicationReleasePersonUpdate;
