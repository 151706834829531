import dayjs from 'dayjs';
import { IManufacturer } from 'app/shared/model/manufacturer.model';
import { IISN } from 'app/shared/model/isn.model';
import { IPublication } from 'app/shared/model/publication.model';
import { IVersion } from 'app/shared/model/version.model';
import { IMedium } from 'app/shared/model/medium.model';
import { IContentType } from 'app/shared/model/content-type.model';
import { IFormat } from 'app/shared/model/format.model';
import { ILanguage } from 'app/shared/model/language.model';
import { ILicense } from 'app/shared/model/license.model';

export interface IPublicationRelease {
  id?: string;
  legacyId?: number | null;
  licenseOther?: string | null;
  licenseComment?: string | null;
  manufacToBeDeterm?: boolean;
  distributorToBeDeterm?: boolean;
  mediumOther?: string | null;
  otherContent?: string | null;
  height?: number | null;
  width?: number | null;
  orderNo?: number;
  isEdition?: boolean;
  editionNo?: number | null;
  editionImproved?: boolean;
  editionEdited?: boolean;
  editionRePrint?: boolean;
  editionOther?: string;
  printRun?: number;
  pages?: number;
  hasAppendices?: boolean;
  otherAppendix?: string | null;
  webpage?: string | null;
  isAppendix?: boolean;
  indepDistributed?: boolean;
  title?: string | null;
  hasDrm?: boolean;
  hasWatermark?: boolean;
  hasManufacturer?: boolean;
  isReference?: boolean;
  refTitle?: string | null;
  refIsn?: string | null;
  refPublisher?: string | null;
  refYear?: number | null;
  refNo?: string | null;
  refLocation?: string | null;
  scale?: string | null;
  languageOther?: string | null;
  hasIsbnRef?: boolean;
  isbnRefIsbn?: string | null;
  isbnRefAuthor?: string | null;
  isbnRefTitle?: string | null;
  isbnRefPublisher?: string | null;
  isbnRefYear?: number | null;
  isbnRefLocation?: string | null;
  esterId?: string | null;
  isDeleted?: boolean;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  manufacturer?: IManufacturer | null;
  distributor?: IManufacturer | null;
  isn?: IISN | null;
  publication?: IPublication | null;
  version?: IVersion | null;
  medium?: IMedium | null;
  contentType?: IContentType | null;
  format?: IFormat | null;
  languageCode?: ILanguage | null;
  license?: ILicense | null;
  publicationRelease?: IPublicationRelease | null;
}

export const defaultValue: Readonly<IPublicationRelease> = {
  manufacToBeDeterm: false,
  distributorToBeDeterm: false,
  isEdition: false,
  editionImproved: false,
  editionEdited: false,
  editionRePrint: false,
  hasAppendices: false,
  isAppendix: false,
  indepDistributed: false,
  hasDrm: false,
  hasWatermark: false,
  hasManufacturer: false,
  isReference: false,
  hasIsbnRef: false,
  isDeleted: false,
};
