import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './publisher.reducer';

export const PublisherDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const publisherEntity = useAppSelector(state => state.publisher.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="publisherDetailsHeading">
          <Translate contentKey="kpbackApp.publisher.detail.title">Publisher</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.publisher.id">Id</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.publisher.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.legacyId}</dd>
          <dt>
            <span id="publisherType">
              <Translate contentKey="kpbackApp.publisher.publisherType">Publisher Type</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.publisherType}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="kpbackApp.publisher.status">Status</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.status}</dd>
          <dt>
            <span id="personnelId">
              <Translate contentKey="kpbackApp.publisher.personnelId">Personnel Id</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.personnelId}</dd>
          <dt>
            <span id="businessName">
              <Translate contentKey="kpbackApp.publisher.businessName">Business Name</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.businessName}</dd>
          <dt>
            <span id="registryCode">
              <Translate contentKey="kpbackApp.publisher.registryCode">Registry Code</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.registryCode}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="kpbackApp.publisher.name">Name</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.name}</dd>
          <dt>
            <span id="allowGrp">
              <Translate contentKey="kpbackApp.publisher.allowGrp">Allow Grp</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.allowGrp ? 'true' : 'false'}</dd>
          <dt>
            <span id="phone">
              <Translate contentKey="kpbackApp.publisher.phone">Phone</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.phone}</dd>
          <dt>
            <span id="contact">
              <Translate contentKey="kpbackApp.publisher.contact">Contact</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.contact}</dd>
          <dt>
            <span id="webpage">
              <Translate contentKey="kpbackApp.publisher.webpage">Webpage</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.webpage}</dd>
          <dt>
            <span id="isLibrary">
              <Translate contentKey="kpbackApp.publisher.isLibrary">Is Library</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.isLibrary ? 'true' : 'false'}</dd>
          <dt>
            <span id="lastSignon">
              <Translate contentKey="kpbackApp.publisher.lastSignon">Last Signon</Translate>
            </span>
          </dt>
          <dd>
            {publisherEntity.lastSignon ? <TextFormat value={publisherEntity.lastSignon} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="ftpRoot">
              <Translate contentKey="kpbackApp.publisher.ftpRoot">Ftp Root</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.ftpRoot}</dd>
          <dt>
            <span id="hasPublished">
              <Translate contentKey="kpbackApp.publisher.hasPublished">Has Published</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.hasPublished ? 'true' : 'false'}</dd>
          <dt>
            <span id="archivedAt">
              <Translate contentKey="kpbackApp.publisher.archivedAt">Archived At</Translate>
            </span>
          </dt>
          <dd>
            {publisherEntity.archivedAt ? <TextFormat value={publisherEntity.archivedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="allowMpiid">
              <Translate contentKey="kpbackApp.publisher.allowMpiid">Allow Mpiid</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.allowMpiid ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.publisher.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {publisherEntity.createdAt ? <TextFormat value={publisherEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="latestIsbn">
              <Translate contentKey="kpbackApp.publisher.latestIsbn">Latest Isbn</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.latestIsbn}</dd>
          <dt>
            <span id="maxPublicationCount">
              <Translate contentKey="kpbackApp.publisher.maxPublicationCount">Max Publication Count</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.maxPublicationCount}</dd>
          <dt>
            <span id="inheritedFromRegister">
              <Translate contentKey="kpbackApp.publisher.inheritedFromRegister">Inherited From Register</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.inheritedFromRegister ? 'true' : 'false'}</dd>
          <dt>
            <span id="legalOwner">
              <Translate contentKey="kpbackApp.publisher.legalOwner">Legal Owner</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.legalOwner}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.publisher.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.createdBy}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.publisher.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.publisher.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {publisherEntity.modifiedAt ? <TextFormat value={publisherEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.publisher.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{publisherEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.publisher.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {publisherEntity.deletedAt ? <TextFormat value={publisherEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="kpbackApp.publisher.address">Address</Translate>
          </dt>
          <dd>{publisherEntity.address ? publisherEntity.address.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/publisher" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/publisher/${publisherEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PublisherDetail;
