import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './publisher-email.reducer';

export const PublisherEmailDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const publisherEmailEntity = useAppSelector(state => state.publisherEmail.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="publisherEmailDetailsHeading">
          <Translate contentKey="kpbackApp.publisherEmail.detail.title">PublisherEmail</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.publisherEmail.id">Id</Translate>
            </span>
          </dt>
          <dd>{publisherEmailEntity.id}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="kpbackApp.publisherEmail.email">Email</Translate>
            </span>
          </dt>
          <dd>{publisherEmailEntity.email}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.publisherEmail.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{publisherEmailEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.publisherEmail.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {publisherEmailEntity.createdAt ? (
              <TextFormat value={publisherEmailEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.publisherEmail.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{publisherEmailEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.publisherEmail.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {publisherEmailEntity.modifiedAt ? (
              <TextFormat value={publisherEmailEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.publisherEmail.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{publisherEmailEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.publisherEmail.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {publisherEmailEntity.deletedAt ? (
              <TextFormat value={publisherEmailEntity.deletedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="kpbackApp.publisherEmail.publisher">Publisher</Translate>
          </dt>
          <dd>{publisherEmailEntity.publisher ? publisherEmailEntity.publisher.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/publisher-email" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/publisher-email/${publisherEmailEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PublisherEmailDetail;
