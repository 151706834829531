import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './isn-class.reducer';

export const IsnClassDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const isnClassEntity = useAppSelector(state => state.isnClass.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="isnClassDetailsHeading">
          <Translate contentKey="kpbackApp.isnClass.detail.title">IsnClass</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.isnClass.id">Id</Translate>
            </span>
          </dt>
          <dd>{isnClassEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.isnClass.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{isnClassEntity.legacyId}</dd>
          <dt>
            <span id="typeCode">
              <Translate contentKey="kpbackApp.isnClass.typeCode">Type Code</Translate>
            </span>
          </dt>
          <dd>{isnClassEntity.typeCode}</dd>
          <dt>
            <span id="orderNo">
              <Translate contentKey="kpbackApp.isnClass.orderNo">Order No</Translate>
            </span>
          </dt>
          <dd>{isnClassEntity.orderNo}</dd>
          <dt>
            <span id="groupNo">
              <Translate contentKey="kpbackApp.isnClass.groupNo">Group No</Translate>
            </span>
          </dt>
          <dd>{isnClassEntity.groupNo}</dd>
          <dt>
            <span id="nameEt">
              <Translate contentKey="kpbackApp.isnClass.nameEt">Name Et</Translate>
            </span>
          </dt>
          <dd>{isnClassEntity.nameEt}</dd>
          <dt>
            <span id="nameShortEt">
              <Translate contentKey="kpbackApp.isnClass.nameShortEt">Name Short Et</Translate>
            </span>
          </dt>
          <dd>{isnClassEntity.nameShortEt}</dd>
          <dt>
            <span id="forLibrary">
              <Translate contentKey="kpbackApp.isnClass.forLibrary">For Library</Translate>
            </span>
          </dt>
          <dd>{isnClassEntity.forLibrary ? 'true' : 'false'}</dd>
          <dt>
            <span id="hasShort">
              <Translate contentKey="kpbackApp.isnClass.hasShort">Has Short</Translate>
            </span>
          </dt>
          <dd>{isnClassEntity.hasShort ? 'true' : 'false'}</dd>
          <dt>
            <span id="onlyShort">
              <Translate contentKey="kpbackApp.isnClass.onlyShort">Only Short</Translate>
            </span>
          </dt>
          <dd>{isnClassEntity.onlyShort ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.isnClass.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{isnClassEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.isnClass.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{isnClassEntity.createdAt ? <TextFormat value={isnClassEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.isnClass.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{isnClassEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.isnClass.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {isnClassEntity.modifiedAt ? <TextFormat value={isnClassEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.isnClass.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{isnClassEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.isnClass.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>{isnClassEntity.deletedAt ? <TextFormat value={isnClassEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
        </dl>
        <Button tag={Link} to="/isn-class" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/isn-class/${isnClassEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default IsnClassDetail;
