import dayjs from 'dayjs';
import { IAddress } from 'app/shared/model/address.model';
import { IPublicationRelease } from 'app/shared/model/publication-release.model';

export interface IManufacturer {
  id?: string;
  legacyId?: number | null;
  name?: string;
  phone?: string | null;
  email?: string | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  address?: IAddress | null;
  manPublicationRelease?: IPublicationRelease | null;
  distPublicationRelease?: IPublicationRelease | null;
}

export const defaultValue: Readonly<IManufacturer> = {};
