import dayjs from 'dayjs';

export interface ILanguage {
  id?: string;
  legacyId?: number | null;
  code?: string | null;
  nameEt?: string | null;
  nameMarc?: string | null;
  isOther?: boolean;
  isMusic?: boolean;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<ILanguage> = {
  isOther: false,
  isMusic: false,
};
