import dayjs from 'dayjs';
import { IClassifier } from 'app/shared/model/classifier.model';

export interface IFilesetItem {
  id?: string;
  legacyId?: number | null;
  origFilename?: string | null;
  isWorkfile?: boolean;
  fedoraUploaded?: boolean;
  fedoraError?: string | null;
  discFilename?: string | null;
  filesetId?: number;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  statusCode?: IClassifier | null;
}

export const defaultValue: Readonly<IFilesetItem> = {
  isWorkfile: false,
  fedoraUploaded: false,
};
