export enum PublisherStatus {
  REGISTERED = 'REGISTERED',

  CONFIRMED = 'CONFIRMED',

  REJECTED = 'REJECTED',

  ARCHIVED = 'ARCHIVED',

  DELETED = 'DELETED',
}
