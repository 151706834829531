import dayjs from 'dayjs';
import { IClassifier } from 'app/shared/model/classifier.model';

export interface IClassifierValue {
  id?: number;
  code?: string;
  name?: string;
  description?: string;
  isOther?: boolean;
  sortOrder?: number;
  isActive?: boolean;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  classifier?: IClassifier | null;
}

export const defaultValue: Readonly<IClassifierValue> = {
  isOther: false,
  isActive: false,
};
