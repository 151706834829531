import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Medium from './medium';
import MediumDetail from './medium-detail';
import MediumUpdate from './medium-update';
import MediumDeleteDialog from './medium-delete-dialog';

const MediumRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Medium />} />
    <Route path="new" element={<MediumUpdate />} />
    <Route path=":id">
      <Route index element={<MediumDetail />} />
      <Route path="edit" element={<MediumUpdate />} />
      <Route path="delete" element={<MediumDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MediumRoutes;
