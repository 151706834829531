import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './isn.reducer';

export const ISNDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const iSNEntity = useAppSelector(state => state.iSN.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="iSNDetailsHeading">
          <Translate contentKey="kpbackApp.iSN.detail.title">ISN</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.iSN.id">Id</Translate>
            </span>
          </dt>
          <dd>{iSNEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.iSN.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{iSNEntity.legacyId}</dd>
          <dt>
            <span id="isnNumber">
              <Translate contentKey="kpbackApp.iSN.isnNumber">Isn Number</Translate>
            </span>
          </dt>
          <dd>{iSNEntity.isnNumber}</dd>
          <dt>
            <span id="isManual">
              <Translate contentKey="kpbackApp.iSN.isManual">Is Manual</Translate>
            </span>
          </dt>
          <dd>{iSNEntity.isManual ? 'true' : 'false'}</dd>
          <dt>
            <span id="isnComment">
              <Translate contentKey="kpbackApp.iSN.isnComment">Isn Comment</Translate>
            </span>
          </dt>
          <dd>{iSNEntity.isnComment}</dd>
          <dt>
            <span id="isnTarget">
              <Translate contentKey="kpbackApp.iSN.isnTarget">Isn Target</Translate>
            </span>
          </dt>
          <dd>{iSNEntity.isnTarget}</dd>
          <dt>
            <span id="canceledBy">
              <Translate contentKey="kpbackApp.iSN.canceledBy">Canceled By</Translate>
            </span>
          </dt>
          <dd>{iSNEntity.canceledBy}</dd>
          <dt>
            <span id="canceledAt">
              <Translate contentKey="kpbackApp.iSN.canceledAt">Canceled At</Translate>
            </span>
          </dt>
          <dd>{iSNEntity.canceledAt ? <TextFormat value={iSNEntity.canceledAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.iSN.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{iSNEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.iSN.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{iSNEntity.createdAt ? <TextFormat value={iSNEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.iSN.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{iSNEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.iSN.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>{iSNEntity.modifiedAt ? <TextFormat value={iSNEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.iSN.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{iSNEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.iSN.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>{iSNEntity.deletedAt ? <TextFormat value={iSNEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="kpbackApp.iSN.isnBlock">Isn Block</Translate>
          </dt>
          <dd>{iSNEntity.isnBlock ? iSNEntity.isnBlock.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/isn" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/isn/${iSNEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ISNDetail;
