import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Fileset from './fileset';
import FilesetDetail from './fileset-detail';
import FilesetUpdate from './fileset-update';
import FilesetDeleteDialog from './fileset-delete-dialog';

const FilesetRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Fileset />} />
    <Route path="new" element={<FilesetUpdate />} />
    <Route path=":id">
      <Route index element={<FilesetDetail />} />
      <Route path="edit" element={<FilesetUpdate />} />
      <Route path="delete" element={<FilesetDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FilesetRoutes;
