import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './publication-release-person.reducer';

export const PublicationReleasePersonDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const publicationReleasePersonEntity = useAppSelector(state => state.publicationReleasePerson.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="publicationReleasePersonDetailsHeading">
          <Translate contentKey="kpbackApp.publicationReleasePerson.detail.title">PublicationReleasePerson</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonEntity.id}</dd>
          <dt>
            <span id="roleName">
              <Translate contentKey="kpbackApp.publicationReleasePerson.roleName">Role Name</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonEntity.roleName}</dd>
          <dt>
            <span id="orderNo">
              <Translate contentKey="kpbackApp.publicationReleasePerson.orderNo">Order No</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonEntity.orderNo}</dd>
          <dt>
            <span id="firstName">
              <Translate contentKey="kpbackApp.publicationReleasePerson.firstName">First Name</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonEntity.firstName}</dd>
          <dt>
            <span id="lastName">
              <Translate contentKey="kpbackApp.publicationReleasePerson.lastName">Last Name</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonEntity.lastName}</dd>
          <dt>
            <span id="orgName">
              <Translate contentKey="kpbackApp.publicationReleasePerson.orgName">Org Name</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonEntity.orgName}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.publicationReleasePerson.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.publicationReleasePerson.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {publicationReleasePersonEntity.createdAt ? (
              <TextFormat value={publicationReleasePersonEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.publicationReleasePerson.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.publicationReleasePerson.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {publicationReleasePersonEntity.modifiedAt ? (
              <TextFormat value={publicationReleasePersonEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.publicationReleasePerson.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{publicationReleasePersonEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.publicationReleasePerson.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {publicationReleasePersonEntity.deletedAt ? (
              <TextFormat value={publicationReleasePersonEntity.deletedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="kpbackApp.publicationReleasePerson.type">Type</Translate>
          </dt>
          <dd>{publicationReleasePersonEntity.type ? publicationReleasePersonEntity.type.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publicationReleasePerson.release">Release</Translate>
          </dt>
          <dd>{publicationReleasePersonEntity.release ? publicationReleasePersonEntity.release.id : ''}</dd>
          <dt>
            <Translate contentKey="kpbackApp.publicationReleasePerson.role">Role</Translate>
          </dt>
          <dd>{publicationReleasePersonEntity.role ? publicationReleasePersonEntity.role.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/publication-release-person" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/publication-release-person/${publicationReleasePersonEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PublicationReleasePersonDetail;
