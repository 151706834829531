import dayjs from 'dayjs';

export interface IContentType {
  id?: string;
  legacyId?: number | null;
  code?: string | null;
  name?: string | null;
  isOther?: boolean;
  forIsmn?: boolean;
  forIsbn?: boolean;
  isIsmnName?: boolean;
  forSeparateIsmn?: boolean;
  ismnApxType?: number;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<IContentType> = {
  isOther: false,
  forIsmn: false,
  forIsbn: false,
  isIsmnName: false,
  forSeparateIsmn: false,
};
