import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './isn-block.reducer';

export const ISNBlockDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const iSNBlockEntity = useAppSelector(state => state.iSNBlock.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="iSNBlockDetailsHeading">
          <Translate contentKey="kpbackApp.iSNBlock.detail.title">ISNBlock</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="kpbackApp.iSNBlock.id">Id</Translate>
            </span>
          </dt>
          <dd>{iSNBlockEntity.id}</dd>
          <dt>
            <span id="legacyId">
              <Translate contentKey="kpbackApp.iSNBlock.legacyId">Legacy Id</Translate>
            </span>
          </dt>
          <dd>{iSNBlockEntity.legacyId}</dd>
          <dt>
            <span id="prefix">
              <Translate contentKey="kpbackApp.iSNBlock.prefix">Prefix</Translate>
            </span>
          </dt>
          <dd>{iSNBlockEntity.prefix}</dd>
          <dt>
            <span id="isnSize">
              <Translate contentKey="kpbackApp.iSNBlock.isnSize">Isn Size</Translate>
            </span>
          </dt>
          <dd>{iSNBlockEntity.isnSize}</dd>
          <dt>
            <span id="isShared">
              <Translate contentKey="kpbackApp.iSNBlock.isShared">Is Shared</Translate>
            </span>
          </dt>
          <dd>{iSNBlockEntity.isShared ? 'true' : 'false'}</dd>
          <dt>
            <span id="isMuted">
              <Translate contentKey="kpbackApp.iSNBlock.isMuted">Is Muted</Translate>
            </span>
          </dt>
          <dd>{iSNBlockEntity.isMuted ? 'true' : 'false'}</dd>
          <dt>
            <span id="lastNumber">
              <Translate contentKey="kpbackApp.iSNBlock.lastNumber">Last Number</Translate>
            </span>
          </dt>
          <dd>{iSNBlockEntity.lastNumber}</dd>
          <dt>
            <span id="isDigitalCopy">
              <Translate contentKey="kpbackApp.iSNBlock.isDigitalCopy">Is Digital Copy</Translate>
            </span>
          </dt>
          <dd>{iSNBlockEntity.isDigitalCopy ? 'true' : 'false'}</dd>
          <dt>
            <span id="isActive">
              <Translate contentKey="kpbackApp.iSNBlock.isActive">Is Active</Translate>
            </span>
          </dt>
          <dd>{iSNBlockEntity.isActive ? 'true' : 'false'}</dd>
          <dt>
            <span id="isnBlockRange">
              <Translate contentKey="kpbackApp.iSNBlock.isnBlockRange">Isn Block Range</Translate>
            </span>
          </dt>
          <dd>{iSNBlockEntity.isnBlockRange}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="kpbackApp.iSNBlock.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{iSNBlockEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="kpbackApp.iSNBlock.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{iSNBlockEntity.createdAt ? <TextFormat value={iSNBlockEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="kpbackApp.iSNBlock.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{iSNBlockEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="kpbackApp.iSNBlock.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {iSNBlockEntity.modifiedAt ? <TextFormat value={iSNBlockEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="deletedBy">
              <Translate contentKey="kpbackApp.iSNBlock.deletedBy">Deleted By</Translate>
            </span>
          </dt>
          <dd>{iSNBlockEntity.deletedBy}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="kpbackApp.iSNBlock.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>{iSNBlockEntity.deletedAt ? <TextFormat value={iSNBlockEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="kpbackApp.iSNBlock.isnType">Isn Type</Translate>
          </dt>
          <dd>{iSNBlockEntity.isnType ? iSNBlockEntity.isnType.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/isn-block" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/isn-block/${iSNBlockEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ISNBlockDetail;
