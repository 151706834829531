import dayjs from 'dayjs';
import { IISNBlock } from 'app/shared/model/isn-block.model';
import { IPublication } from 'app/shared/model/publication.model';
import { IPublicationRelease } from 'app/shared/model/publication-release.model';
import { ISNTarget } from 'app/shared/model/enumerations/isn-target.model';

export interface IISN {
  id?: string;
  legacyId?: number | null;
  isnNumber?: string;
  isManual?: boolean;
  isnComment?: string | null;
  isnTarget?: keyof typeof ISNTarget | null;
  canceledBy?: string | null;
  canceledAt?: dayjs.Dayjs | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  isnBlock?: IISNBlock | null;
  publications?: IPublication[] | null;
  releases?: IPublicationRelease[] | null;
}

export const defaultValue: Readonly<IISN> = {
  isManual: false,
};
