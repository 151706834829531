import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IClassifier } from 'app/shared/model/classifier.model';
import { getEntities as getClassifiers } from 'app/entities/classifier/classifier.reducer';
import { IFilesetItem } from 'app/shared/model/fileset-item.model';
import { getEntity, updateEntity, createEntity, reset } from './fileset-item.reducer';

export const FilesetItemUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const classifiers = useAppSelector(state => state.classifier.entities);
  const filesetItemEntity = useAppSelector(state => state.filesetItem.entity);
  const loading = useAppSelector(state => state.filesetItem.loading);
  const updating = useAppSelector(state => state.filesetItem.updating);
  const updateSuccess = useAppSelector(state => state.filesetItem.updateSuccess);

  const handleClose = () => {
    navigate('/fileset-item' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getClassifiers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.legacyId !== undefined && typeof values.legacyId !== 'number') {
      values.legacyId = Number(values.legacyId);
    }
    if (values.filesetId !== undefined && typeof values.filesetId !== 'number') {
      values.filesetId = Number(values.filesetId);
    }
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.modifiedAt = convertDateTimeToServer(values.modifiedAt);
    values.deletedAt = convertDateTimeToServer(values.deletedAt);

    const entity = {
      ...filesetItemEntity,
      ...values,
      statusCode: classifiers.find(it => it.id.toString() === values.statusCode.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          modifiedAt: displayDefaultDateTime(),
          deletedAt: displayDefaultDateTime(),
        }
      : {
          ...filesetItemEntity,
          createdAt: convertDateTimeFromServer(filesetItemEntity.createdAt),
          modifiedAt: convertDateTimeFromServer(filesetItemEntity.modifiedAt),
          deletedAt: convertDateTimeFromServer(filesetItemEntity.deletedAt),
          statusCode: filesetItemEntity?.statusCode?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="kpbackApp.filesetItem.home.createOrEditLabel" data-cy="FilesetItemCreateUpdateHeading">
            <Translate contentKey="kpbackApp.filesetItem.home.createOrEditLabel">Create or edit a FilesetItem</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="fileset-item-id"
                  label={translate('kpbackApp.filesetItem.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('kpbackApp.filesetItem.legacyId')}
                id="fileset-item-legacyId"
                name="legacyId"
                data-cy="legacyId"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.filesetItem.origFilename')}
                id="fileset-item-origFilename"
                name="origFilename"
                data-cy="origFilename"
                type="text"
                validate={{
                  maxLength: { value: 300, message: translate('entity.validation.maxlength', { max: 300 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.filesetItem.isWorkfile')}
                id="fileset-item-isWorkfile"
                name="isWorkfile"
                data-cy="isWorkfile"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.filesetItem.fedoraUploaded')}
                id="fileset-item-fedoraUploaded"
                name="fedoraUploaded"
                data-cy="fedoraUploaded"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.filesetItem.fedoraError')}
                id="fileset-item-fedoraError"
                name="fedoraError"
                data-cy="fedoraError"
                type="text"
                validate={{
                  maxLength: { value: 300, message: translate('entity.validation.maxlength', { max: 300 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.filesetItem.discFilename')}
                id="fileset-item-discFilename"
                name="discFilename"
                data-cy="discFilename"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.filesetItem.filesetId')}
                id="fileset-item-filesetId"
                name="filesetId"
                data-cy="filesetId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.filesetItem.createdBy')}
                id="fileset-item-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.filesetItem.createdAt')}
                id="fileset-item-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.filesetItem.modifiedBy')}
                id="fileset-item-modifiedBy"
                name="modifiedBy"
                data-cy="modifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.filesetItem.modifiedAt')}
                id="fileset-item-modifiedAt"
                name="modifiedAt"
                data-cy="modifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.filesetItem.deletedBy')}
                id="fileset-item-deletedBy"
                name="deletedBy"
                data-cy="deletedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.filesetItem.deletedAt')}
                id="fileset-item-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="fileset-item-statusCode"
                name="statusCode"
                data-cy="statusCode"
                label={translate('kpbackApp.filesetItem.statusCode')}
                type="select"
              >
                <option value="" key="0" />
                {classifiers
                  ? classifiers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/fileset-item" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FilesetItemUpdate;
