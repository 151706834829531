import dayjs from 'dayjs';

export interface IPerson {
  id?: string;
  legacyId?: number | null;
  firstName?: string;
  lastName?: string;
  ssoEmail?: string;
  languageCode?: string | null;
  sierraId?: string;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<IPerson> = {};
