import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AccessRight from './access-right';
import AccessRightDetail from './access-right-detail';
import AccessRightUpdate from './access-right-update';
import AccessRightDeleteDialog from './access-right-delete-dialog';

const AccessRightRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AccessRight />} />
    <Route path="new" element={<AccessRightUpdate />} />
    <Route path=":id">
      <Route index element={<AccessRightDetail />} />
      <Route path="edit" element={<AccessRightUpdate />} />
      <Route path="delete" element={<AccessRightDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AccessRightRoutes;
