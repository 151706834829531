import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IISN } from 'app/shared/model/isn.model';
import { getEntities as getISns } from 'app/entities/isn/isn.reducer';
import { IPublisher } from 'app/shared/model/publisher.model';
import { getEntities as getPublishers } from 'app/entities/publisher/publisher.reducer';
import { IIsnClass } from 'app/shared/model/isn-class.model';
import { getEntities as getIsnClasses } from 'app/entities/isn-class/isn-class.reducer';
import { IClassifier } from 'app/shared/model/classifier.model';
import { getEntities as getClassifiers } from 'app/entities/classifier/classifier.reducer';
import { IAddress } from 'app/shared/model/address.model';
import { getEntities as getAddresses } from 'app/entities/address/address.reducer';
import { ILanguage } from 'app/shared/model/language.model';
import { getEntities as getLanguages } from 'app/entities/language/language.reducer';
import { IPublishPeriod } from 'app/shared/model/publish-period.model';
import { getEntities as getPublishPeriods } from 'app/entities/publish-period/publish-period.reducer';
import { IKeyword } from 'app/shared/model/keyword.model';
import { getEntities as getKeywords } from 'app/entities/keyword/keyword.reducer';
import { IPublication } from 'app/shared/model/publication.model';
import { FileStatus } from 'app/shared/model/enumerations/file-status.model';
import { getEntity, updateEntity, createEntity, reset } from './publication.reducer';

export const PublicationUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const iSNS = useAppSelector(state => state.iSN.entities);
  const publishers = useAppSelector(state => state.publisher.entities);
  const isnClasses = useAppSelector(state => state.isnClass.entities);
  const classifiers = useAppSelector(state => state.classifier.entities);
  const addresses = useAppSelector(state => state.address.entities);
  const languages = useAppSelector(state => state.language.entities);
  const publishPeriods = useAppSelector(state => state.publishPeriod.entities);
  const keywords = useAppSelector(state => state.keyword.entities);
  const publicationEntity = useAppSelector(state => state.publication.entity);
  const loading = useAppSelector(state => state.publication.loading);
  const updating = useAppSelector(state => state.publication.updating);
  const updateSuccess = useAppSelector(state => state.publication.updateSuccess);
  const fileStatusValues = Object.keys(FileStatus);

  const handleClose = () => {
    navigate('/publication' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getISns({}));
    dispatch(getPublishers({}));
    dispatch(getIsnClasses({}));
    dispatch(getClassifiers({}));
    dispatch(getAddresses({}));
    dispatch(getLanguages({}));
    dispatch(getPublishPeriods({}));
    dispatch(getKeywords({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.legacyId !== undefined && typeof values.legacyId !== 'number') {
      values.legacyId = Number(values.legacyId);
    }
    if (values.volumes !== undefined && typeof values.volumes !== 'number') {
      values.volumes = Number(values.volumes);
    }
    if (values.volumeNo !== undefined && typeof values.volumeNo !== 'number') {
      values.volumeNo = Number(values.volumeNo);
    }
    if (values.seriesNo !== undefined && typeof values.seriesNo !== 'number') {
      values.seriesNo = Number(values.seriesNo);
    }
    if (values.yearPublished !== undefined && typeof values.yearPublished !== 'number') {
      values.yearPublished = Number(values.yearPublished);
    }
    if (values.publishInterval !== undefined && typeof values.publishInterval !== 'number') {
      values.publishInterval = Number(values.publishInterval);
    }
    if (values.isbnRefYear !== undefined && typeof values.isbnRefYear !== 'number') {
      values.isbnRefYear = Number(values.isbnRefYear);
    }
    if (values.filesSubmitterId !== undefined && typeof values.filesSubmitterId !== 'number') {
      values.filesSubmitterId = Number(values.filesSubmitterId);
    }
    values.filesSubmittedAt = convertDateTimeToServer(values.filesSubmittedAt);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.modifiedAt = convertDateTimeToServer(values.modifiedAt);
    values.deletedAt = convertDateTimeToServer(values.deletedAt);
    values.submittedAt = convertDateTimeToServer(values.submittedAt);
    values.confirmedAt = convertDateTimeToServer(values.confirmedAt);

    const entity = {
      ...publicationEntity,
      ...values,
      keywords: mapIdList(values.keywords),
      isn: iSNS.find(it => it.id.toString() === values.isn.toString()),
      publisher: publishers.find(it => it.id.toString() === values.publisher.toString()),
      isnClass: isnClasses.find(it => it.id.toString() === values.isnClass.toString()),
      status: classifiers.find(it => it.id.toString() === values.status.toString()),
      periodOfYear: classifiers.find(it => it.id.toString() === values.periodOfYear.toString()),
      periodicalType: classifiers.find(it => it.id.toString() === values.periodicalType.toString()),
      address: addresses.find(it => it.id.toString() === values.address.toString()),
      languageCode: languages.find(it => it.id.toString() === values.languageCode.toString()),
      origLanguageCode: languages.find(it => it.id.toString() === values.origLanguageCode.toString()),
      publishPeriod: publishPeriods.find(it => it.id.toString() === values.publishPeriod.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          filesSubmittedAt: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          modifiedAt: displayDefaultDateTime(),
          deletedAt: displayDefaultDateTime(),
          submittedAt: displayDefaultDateTime(),
          confirmedAt: displayDefaultDateTime(),
        }
      : {
          fileStatus: 'MISSING',
          ...publicationEntity,
          filesSubmittedAt: convertDateTimeFromServer(publicationEntity.filesSubmittedAt),
          createdAt: convertDateTimeFromServer(publicationEntity.createdAt),
          modifiedAt: convertDateTimeFromServer(publicationEntity.modifiedAt),
          deletedAt: convertDateTimeFromServer(publicationEntity.deletedAt),
          submittedAt: convertDateTimeFromServer(publicationEntity.submittedAt),
          confirmedAt: convertDateTimeFromServer(publicationEntity.confirmedAt),
          isn: publicationEntity?.isn?.id,
          publisher: publicationEntity?.publisher?.id,
          isnClass: publicationEntity?.isnClass?.id,
          status: publicationEntity?.status?.id,
          address: publicationEntity?.address?.id,
          languageCode: publicationEntity?.languageCode?.id,
          origLanguageCode: publicationEntity?.origLanguageCode?.id,
          periodOfYear: publicationEntity?.periodOfYear?.id,
          publishPeriod: publicationEntity?.publishPeriod?.id,
          periodicalType: publicationEntity?.periodicalType?.id,
          keywords: publicationEntity?.keywords?.map(e => e.id.toString()),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="kpbackApp.publication.home.createOrEditLabel" data-cy="PublicationCreateUpdateHeading">
            <Translate contentKey="kpbackApp.publication.home.createOrEditLabel">Create or edit a Publication</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="publication-id"
                  label={translate('kpbackApp.publication.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('kpbackApp.publication.legacyId')}
                id="publication-legacyId"
                name="legacyId"
                data-cy="legacyId"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publication.title')}
                id="publication-title"
                name="title"
                data-cy="title"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.subtitle')}
                id="publication-subtitle"
                name="subtitle"
                data-cy="subtitle"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.languageOther')}
                id="publication-languageOther"
                name="languageOther"
                data-cy="languageOther"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.isTranslated')}
                id="publication-isTranslated"
                name="isTranslated"
                data-cy="isTranslated"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publication.origLanguageOther')}
                id="publication-origLanguageOther"
                name="origLanguageOther"
                data-cy="origLanguageOther"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.origTitle')}
                id="publication-origTitle"
                name="origTitle"
                data-cy="origTitle"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.isMultiVolume')}
                id="publication-isMultiVolume"
                name="isMultiVolume"
                data-cy="isMultiVolume"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publication.volumes')}
                id="publication-volumes"
                name="volumes"
                data-cy="volumes"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.volumeNo')}
                id="publication-volumeNo"
                name="volumeNo"
                data-cy="volumeNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.isSeries')}
                id="publication-isSeries"
                name="isSeries"
                data-cy="isSeries"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publication.seriesTitle')}
                id="publication-seriesTitle"
                name="seriesTitle"
                data-cy="seriesTitle"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.issn')}
                id="publication-issn"
                name="issn"
                data-cy="issn"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.seriesNo')}
                id="publication-seriesNo"
                name="seriesNo"
                data-cy="seriesNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.yearPublished')}
                id="publication-yearPublished"
                name="yearPublished"
                data-cy="yearPublished"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.publisherComment')}
                id="publication-publisherComment"
                name="publisherComment"
                data-cy="publisherComment"
                type="text"
                validate={{
                  maxLength: { value: 8000, message: translate('entity.validation.maxlength', { max: 8000 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.issuerComment')}
                id="publication-issuerComment"
                name="issuerComment"
                data-cy="issuerComment"
                type="text"
                validate={{
                  maxLength: { value: 8000, message: translate('entity.validation.maxlength', { max: 8000 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.fileStatus')}
                id="publication-fileStatus"
                name="fileStatus"
                data-cy="fileStatus"
                type="select"
              >
                {fileStatusValues.map(fileStatus => (
                  <option value={fileStatus} key={fileStatus}>
                    {translate('kpbackApp.FileStatus.' + fileStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('kpbackApp.publication.publishOther')}
                id="publication-publishOther"
                name="publishOther"
                data-cy="publishOther"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.publishInterval')}
                id="publication-publishInterval"
                name="publishInterval"
                data-cy="publishInterval"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.periodicalTypeOther')}
                id="publication-periodicalTypeOther"
                name="periodicalTypeOther"
                data-cy="periodicalTypeOther"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.hasOtherTitle')}
                id="publication-hasOtherTitle"
                name="hasOtherTitle"
                data-cy="hasOtherTitle"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publication.otherTitle')}
                id="publication-otherTitle"
                name="otherTitle"
                data-cy="otherTitle"
                type="text"
                validate={{
                  maxLength: { value: 800, message: translate('entity.validation.maxlength', { max: 800 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.otherTitleIsn')}
                id="publication-otherTitleIsn"
                name="otherTitleIsn"
                data-cy="otherTitleIsn"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.hasSubseries')}
                id="publication-hasSubseries"
                name="hasSubseries"
                data-cy="hasSubseries"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publication.subseriesTitle')}
                id="publication-subseriesTitle"
                name="subseriesTitle"
                data-cy="subseriesTitle"
                type="text"
                validate={{
                  maxLength: { value: 800, message: translate('entity.validation.maxlength', { max: 800 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.subseriesIsn')}
                id="publication-subseriesIsn"
                name="subseriesIsn"
                data-cy="subseriesIsn"
                type="text"
                validate={{
                  maxLength: { value: 9, message: translate('entity.validation.maxlength', { max: 9 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.hasVersions')}
                id="publication-hasVersions"
                name="hasVersions"
                data-cy="hasVersions"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publication.hasIsbnRef')}
                id="publication-hasIsbnRef"
                name="hasIsbnRef"
                data-cy="hasIsbnRef"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publication.isbnRefIsbn')}
                id="publication-isbnRefIsbn"
                name="isbnRefIsbn"
                data-cy="isbnRefIsbn"
                type="text"
                validate={{
                  maxLength: { value: 20, message: translate('entity.validation.maxlength', { max: 20 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.isbnRefAuthor')}
                id="publication-isbnRefAuthor"
                name="isbnRefAuthor"
                data-cy="isbnRefAuthor"
                type="text"
                validate={{
                  maxLength: { value: 120, message: translate('entity.validation.maxlength', { max: 120 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.isbnRefTitle')}
                id="publication-isbnRefTitle"
                name="isbnRefTitle"
                data-cy="isbnRefTitle"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.isbnRefPublisher')}
                id="publication-isbnRefPublisher"
                name="isbnRefPublisher"
                data-cy="isbnRefPublisher"
                type="text"
                validate={{
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.isbnRefYear')}
                id="publication-isbnRefYear"
                name="isbnRefYear"
                data-cy="isbnRefYear"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.isbnRefRecordNo')}
                id="publication-isbnRefRecordNo"
                name="isbnRefRecordNo"
                data-cy="isbnRefRecordNo"
                type="text"
                validate={{
                  maxLength: { value: 30, message: translate('entity.validation.maxlength', { max: 30 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.filesSubmitterId')}
                id="publication-filesSubmitterId"
                name="filesSubmitterId"
                data-cy="filesSubmitterId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.filesSubmittedAt')}
                id="publication-filesSubmittedAt"
                name="filesSubmittedAt"
                data-cy="filesSubmittedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.volumesIsnNo')}
                id="publication-volumesIsnNo"
                name="volumesIsnNo"
                data-cy="volumesIsnNo"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.volumeTitle')}
                id="publication-volumeTitle"
                name="volumeTitle"
                data-cy="volumeTitle"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.volumeIsnRequested')}
                id="publication-volumeIsnRequested"
                name="volumeIsnRequested"
                data-cy="volumeIsnRequested"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publication.issnRequested')}
                id="publication-issnRequested"
                name="issnRequested"
                data-cy="issnRequested"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('kpbackApp.publication.secondTitle')}
                id="publication-secondTitle"
                name="secondTitle"
                data-cy="secondTitle"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.createdBy')}
                id="publication-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publication.createdAt')}
                id="publication-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.modifiedBy')}
                id="publication-modifiedBy"
                name="modifiedBy"
                data-cy="modifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publication.modifiedAt')}
                id="publication-modifiedAt"
                name="modifiedAt"
                data-cy="modifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.publication.deletedBy')}
                id="publication-deletedBy"
                name="deletedBy"
                data-cy="deletedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publication.deletedAt')}
                id="publication-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('kpbackApp.publication.submittedBy')}
                id="publication-submittedBy"
                name="submittedBy"
                data-cy="submittedBy"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.submittedAt')}
                id="publication-submittedAt"
                name="submittedAt"
                data-cy="submittedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('kpbackApp.publication.confirmedBy')}
                id="publication-confirmedBy"
                name="confirmedBy"
                data-cy="confirmedBy"
                type="text"
              />
              <ValidatedField
                label={translate('kpbackApp.publication.confirmedAt')}
                id="publication-confirmedAt"
                name="confirmedAt"
                data-cy="confirmedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField id="publication-isn" name="isn" data-cy="isn" label={translate('kpbackApp.publication.isn')} type="select">
                <option value="" key="0" />
                {iSNS
                  ? iSNS.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-publisher"
                name="publisher"
                data-cy="publisher"
                label={translate('kpbackApp.publication.publisher')}
                type="select"
              >
                <option value="" key="0" />
                {publishers
                  ? publishers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-isnClass"
                name="isnClass"
                data-cy="isnClass"
                label={translate('kpbackApp.publication.isnClass')}
                type="select"
              >
                <option value="" key="0" />
                {isnClasses
                  ? isnClasses.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-status"
                name="status"
                data-cy="status"
                label={translate('kpbackApp.publication.status')}
                type="select"
              >
                <option value="" key="0" />
                {classifiers
                  ? classifiers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-address"
                name="address"
                data-cy="address"
                label={translate('kpbackApp.publication.address')}
                type="select"
              >
                <option value="" key="0" />
                {addresses
                  ? addresses.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-languageCode"
                name="languageCode"
                data-cy="languageCode"
                label={translate('kpbackApp.publication.languageCode')}
                type="select"
              >
                <option value="" key="0" />
                {languages
                  ? languages.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-origLanguageCode"
                name="origLanguageCode"
                data-cy="origLanguageCode"
                label={translate('kpbackApp.publication.origLanguageCode')}
                type="select"
              >
                <option value="" key="0" />
                {languages
                  ? languages.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-periodOfYear"
                name="periodOfYear"
                data-cy="periodOfYear"
                label={translate('kpbackApp.publication.periodOfYear')}
                type="select"
              >
                <option value="" key="0" />
                {classifiers
                  ? classifiers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-publishPeriod"
                name="publishPeriod"
                data-cy="publishPeriod"
                label={translate('kpbackApp.publication.publishPeriod')}
                type="select"
              >
                <option value="" key="0" />
                {publishPeriods
                  ? publishPeriods.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="publication-periodicalType"
                name="periodicalType"
                data-cy="periodicalType"
                label={translate('kpbackApp.publication.periodicalType')}
                type="select"
              >
                <option value="" key="0" />
                {classifiers
                  ? classifiers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('kpbackApp.publication.keyword')}
                id="publication-keyword"
                data-cy="keyword"
                type="select"
                multiple
                name="keywords"
              >
                <option value="" key="0" />
                {keywords
                  ? keywords.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/publication" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PublicationUpdate;
