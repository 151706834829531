import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RejectionReason from './rejection-reason';
import RejectionReasonDetail from './rejection-reason-detail';
import RejectionReasonUpdate from './rejection-reason-update';
import RejectionReasonDeleteDialog from './rejection-reason-delete-dialog';

const RejectionReasonRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RejectionReason />} />
    <Route path="new" element={<RejectionReasonUpdate />} />
    <Route path=":id">
      <Route index element={<RejectionReasonDetail />} />
      <Route path="edit" element={<RejectionReasonUpdate />} />
      <Route path="delete" element={<RejectionReasonDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RejectionReasonRoutes;
