import dayjs from 'dayjs';
import { IISNBlock } from 'app/shared/model/isn-block.model';
import { IPublisher } from 'app/shared/model/publisher.model';

export interface IISNBlockOwner {
  id?: string;
  legacyId?: number | null;
  expiredAt?: dayjs.Dayjs | null;
  expirationReason?: string | null;
  createdBy?: string | null;
  createdAt?: dayjs.Dayjs;
  modifiedBy?: string | null;
  modifiedAt?: dayjs.Dayjs | null;
  deletedBy?: string | null;
  deletedAt?: dayjs.Dayjs | null;
  isnBlock?: IISNBlock | null;
  publisher?: IPublisher | null;
}

export const defaultValue: Readonly<IISNBlockOwner> = {};
